<form id="mainForm" action="#" role="form">
    <div class="clearfix">
        <h1 id="fil_modtaget_overskrift" class="pull-left">{{ 'FILOVERFOERSEL.INDBERET.OVERSKRIFT' | translate | async }}</h1>

        <p class="pull-right no-print">
            <a id="fil-modtaget-print" href="#" class="js-print"><span class="skts-icon skts-icon--print skts-icon--align-left"></span>{{ 'FILOVERFOERSEL.MODTAGET.UDSKRIV_KNAP' | translate | async }}</a>
        </p>

    </div>
    <ul class="skts-navbar-wizard">
        <li><span>{{ 'FILOVERFOERSEL.INDBERET.VAEGLFIL.LABEL' | translate | async }}</span></li>
        <li><span>{{ 'FILOVERFOERSEL.INDBERET.KONTROLLER.LABEL' | translate | async }}</span></li>
        <li class="active"><span>{{ 'FILOVERFOERSEL.INDBERET.KVITTERING.LABEL' | translate | async }}</span></li>
    </ul>
    <p class="pull-right"><a id="goToReports" (click)="goToReports()">{{'FILOVERFOERSEL.MODTAGET.SE_TIDLIGERE_INDBERETNINGER' | translate | async}}</a></p>
    <h2>{{ 'FILOVERFOERSEL.MODTAGET.UNDEROVERSKRIFT' | translate | async }}</h2>

    <skat-indberetning-view *ngIf="model.fil"
                            [model]="model"
                            [enableCollapseOnStatus]="false"
                            [showDetails]="showDetailsView"
                            [showDownloadLink]="false"
                            [showFejlKode]="false"></skat-indberetning-view>

    <p [innerHTML]="'FILOVERFOERSEL.INDBERET.KVITTERING.LAES_MERE_PAA_SKATDK' | translate | async"></p>

</form>
