import { Injectable } from "@angular/core";
import { setLanguageCode } from "../../utils/session-storage-utils";
import { TranslationService } from "../translate/translation.service";

declare let datePickerController: any;

@Injectable()
export class DatePickerLocaleService {

    constructor(private translationService: TranslationService) {
    }

    setLanguageCode(languageCode: string) {
        setLanguageCode(languageCode);
        this.getLocale().then(datePickerLocale => {
            (<any>window).fdLocale = datePickerLocale;
            datePickerController.loadLanguage();
        });
    }

    translateText(query: string): Promise<string> {
        return this.translationService.environmentAwareTranslate(query).toPromise();
    }

    private async getLocale(): Promise<Object> {
        return {
            dayAbbrs: [
                await this.translateText('KALENDER.MAN'),
                await this.translateText('KALENDER.TIR'),
                await this.translateText('KALENDER.ONS'),
                await this.translateText('KALENDER.TOR'),
                await this.translateText('KALENDER.FRE'),
                await this.translateText('KALENDER.LOR'),
                await this.translateText('KALENDER.SON'),
            ],
            fullDays: [
                await this.translateText('KALENDER.MANDAG'),
                await this.translateText('KALENDER.TIRSDAG'),
                await this.translateText('KALENDER.ONSDAG'),
                await this.translateText('KALENDER.TORSDAG'),
                await this.translateText('KALENDER.FREDAG'),
                await this.translateText('KALENDER.LORDAG'),
                await this.translateText('KALENDER.SONDAG'),
            ],
            fullMonths: [
                await this.translateText('KALENDER.JANUAR'),
                await this.translateText('KALENDER.FEBRUAR'),
                await this.translateText('KALENDER.MARTS'),
                await this.translateText('KALENDER.APRIL'),
                await this.translateText('KALENDER.MAJ'),
                await this.translateText('KALENDER.JUNI'),
                await this.translateText('KALENDER.JULI'),
                await this.translateText('KALENDER.AUGUST'),
                await this.translateText('KALENDER.SEPTEMBER'),
                await this.translateText('KALENDER.OKTOBER'),
                await this.translateText('KALENDER.NOVEMBER'),
                await this.translateText('KALENDER.DECEMBER'),
            ],
            monthAbbrs: [
                await this.translateText('KALENDER.JAN'),
                await this.translateText('KALENDER.FEB'),
                await this.translateText('KALENDER.MAR'),
                await this.translateText('KALENDER.APR'),
                await this.translateText('KALENDER.MAJ'),
                await this.translateText('KALENDER.JUN'),
                await this.translateText('KALENDER.JUL'),
                await this.translateText('KALENDER.AUG'),
                await this.translateText('KALENDER.SEP'),
                await this.translateText('KALENDER.OKT'),
                await this.translateText('KALENDER.NOV'),
                await this.translateText('KALENDER.DEC'),
                ],
            titles: [
                await this.translateText('KALENDER.FORRIGE_MANED'),
                await this.translateText('KALENDER.NAESTE_MANED'),
                await this.translateText('KALENDER.FORRIG_AR'),
                await this.translateText('KALENDER.NAESTE_AR'),
                await this.translateText('KALENDER.IDAG'),
                await this.translateText('KALENDER.VIS_KALENDER'),
                await this.translateText('KALENDER.UGE'),
                await this.translateText('KALENDER.UGE_AF'),
                await this.translateText('KALENDER.UGE'),
                await this.translateText('KALENDER.VAELG_EN_DATO'),
                await this.translateText('KALENDER.KLICK_TRAEK'),
                await this.translateText('KALENDER.VIS_FORST'),
                await this.translateText('KALENDER.GA_TIL_DAGS_DATO'),
                await this.translateText('KALENDER.DEAKTIVERET_DATO'),
            ]
        };
    }
}
