import { Fejl } from './fejl.model';

export class Valideringsfejl {
    xmlFejl:Fejl[];
    indsholdsFejl:Fejl[];
    advisFejl: Fejl[];

    constructor(xmlFejl: Fejl[], indholdsFejl: Fejl[], advisFejl:Fejl[] = []){
        this.xmlFejl = xmlFejl;
        this.indsholdsFejl = indholdsFejl;
        this.advisFejl = advisFejl;
    }
}
