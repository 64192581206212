import { AdditionalInfoModel } from './additional-info.model';
import { CountryModel } from "./country.model";

export class ReportModel {
    timestamp: string;
    messageRefId: string;
    reportingPeriod: string;
    currency: string; // shown in control views, not consolidated data
    companyNames: Array<string>;
    countries: Array<CountryModel>;
    additionalInfos: Array<AdditionalInfoModel>;

    hasData(): boolean {
        if ( this.countries == undefined ) {
           return false;
        }
        return this.countries.length > 0 || this.additionalInfos.length > 0;
    }
}
