import { ReportModel } from "../../models/report.model";

/**
 * Represents the result of a call to the consolidated indberetninger endpoint, which holds the business data,
 * in the same way that `IndberetningResultModel` represents an indberetning and its business data.
 */
export class KonsolideretIndberetningResultModel {

    constructor(public reportingPeriod: string, public reportModel: ReportModel) {
    }

}
