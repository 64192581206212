<div id="landeliste" class="country-list" *ngIf="hasData()">
    <div class="country-report" *ngFor="let country of report.countries; let i = index">
        <div *ngIf="i != 0" class="skts-row-seperator"></div>
        <div class="skts-collapse skts-collapse-arrow skts-header-section">
            <a href="#" aria-expanded="false" (click)="updateTables()">
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-7">
                        <h3 class="country-report-{{country.countryCode}}">{{country.countryName.toLowerCase()}}</h3>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-5">
                        <div class="row" >
                            <div class="col-sm-9 col-md-9 col-lg-9 text-right" *ngIf="country.companies != undefined ">{{'FILOVERFOERSEL.REPORT.ANTAL_VIRKSOMHEDER' | translate | async}}:</div>
                            <div class="col-sm-1 col-md-1 col-lg-1 text-right number-of-companies" *ngIf="country.companies != undefined ">{{country.companies.length}}</div>
                            <div class="col-sm-2 col-md-2 col-lg-2 text-right">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="hidden skts-toggle-hidden" [ngClass]="{'printAll': print, 'extra-row-separator': i+1 != report.countries.length || additionalInfoNoCountries(report.additionalInfos)}" >
            <div class="skts-row-seperator extra-space report-table1" *ngIf="country.profitOrLoss != undefined">
                <h3 class="text-center">{{'REPORT.TABEL1.OVERSKRIFT' | translate | async}}</h3>
                <div class="table-responsive">
                    <table class="table skts-block-table country-overview ">
                        <tbody>
                            <tr>
                                <td>{{ 'FILOVERFOERSEL.REPORT.OMSAETNING' | translate | async }}</td>
                                <td>{{ 'FILOVERFOERSEL.REPORT.OMSAETNING_UAFHAENGIGE' | translate | async }}</td>
                                <td class="text-right">{{country.revenue.unrelated.amount}}</td>
                                <td>{{getCurrencyCode(country.revenue.unrelated)}}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>{{ 'FILOVERFOERSEL.REPORT.OMSAETNING_AFHAENGIGE' | translate | async }}</td>
                                <td class="text-right">{{country.revenue.related.amount}}</td>
                                <td>{{getCurrencyCode(country.revenue.related)}}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>{{ 'FILOVERFOERSEL.REPORT.OMSAETNING_TOTALT' | translate | async }}</td>
                                <td class="text-right">{{country.revenue.total.amount}}</td>
                                <td>{{getCurrencyCode(country.revenue.total)}}</td>
                            </tr>
                            <tr>
                                <td>{{ 'FILOVERFOERSEL.REPORT.OVERSKUD' | translate | async }}</td>
                                <td></td>
                                <td class="text-right">{{country.profitOrLoss.amount}}</td>
                                <td>{{getCurrencyCode(country.profitOrLoss)}}</td>
                            </tr>
                            <tr>
                                <td>{{ 'FILOVERFOERSEL.REPORT.BETALT_INDKOMSTSKAT' | translate | async }}</td>
                                <td></td>
                                <td class="text-right">{{country.taxPaid.amount}}</td>
                                <td>{{getCurrencyCode(country.taxPaid)}}</td>
                            </tr>
                            <tr>
                                <td>{{ 'FILOVERFOERSEL.REPORT.BEREGNET_SKAT' | translate | async }}</td>
                                <td></td>
                                <td class="text-right">{{country.taxAccrued.amount}}</td>
                                <td>{{getCurrencyCode(country.taxAccrued)}}</td>
                            </tr>
                            <tr>
                                <td>{{'FILOVERFOERSEL.REPORT.VEDTAGET_KAPITAL' | translate | async}}</td>
                                <td></td>
                                <td class="text-right">{{country.capital.amount}}</td>
                                <td>{{getCurrencyCode(country.capital)}}</td>
                            </tr>
                            <tr>
                                <td>{{'FILOVERFOERSEL.REPORT.AKKUMULERET_FORTJENSTE' | translate | async}}</td>
                                <td></td>
                                <td class="text-right">{{country.earnings.amount}}</td>
                                <td>{{getCurrencyCode(country.earnings)}}</td>
                            </tr>
                            <tr>
                                <td>{{'FILOVERFOERSEL.REPORT.ANTAL_MEDARBEJDERE' | translate | async}}</td>
                                <td></td>
                                <td class="text-right">{{country.noOfEmployees}}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{{'FILOVERFOERSEL.REPORT.MATERIELLE_AKTIVER' | translate | async}}</td>
                                <td></td>
                                <td class="text-right">{{country.assets.amount}}</td>
                                <td>{{getCurrencyCode(country.assets)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="skts-row-seperator extra-space report-table2" *ngIf="country.companies != undefined">
                <h3 class="text-center">{{'REPORT.TABEL2.OVERSKRIFT' | translate | async}}</h3>
                <div class="table-responsive country-companies-container">
                    <table class="table skts-block-table country-companies" >
                        <thead>
                            <tr>
                                <th class="text-left col-md-2" valign="bottom">{{ 'FILOVERFOERSEL.REPORT.VIRKSOMHED.TINNUMMER' | translate | async }}</th>
                                <th class="text-left col-md-4">
                                    {{'FILOVERFOERSEL.REPORT.VIRKSOMHED.HEADER.LIST_OF.LABEL' | translate | async }}<br/>
                                    {{ 'FILOVERFOERSEL.REPORT.VIRKSOMHED.MAIN_BUSINESS_ACTIVITY' | translate | async }}
                                </th>
                                <th class="text-left col-md-3">
                                    {{'FILOVERFOERSEL.REPORT.VIRKSOMHED.HEADER.LIST_OF.LABEL' | translate | async }}<br/>
                                    {{ 'FILOVERFOERSEL.REPORT.VIRKSOMHED.NAVN' | translate | async }}
                                </th>
                                <th class="text-left col-md-4">
                                    {{'FILOVERFOERSEL.REPORT.VIRKSOMHED.HEADER.LIST_OF.LABEL' | translate | async }}<br/>
                                    {{ 'FILOVERFOERSEL.REPORT.VIRKSOMHED.ADRESSE' | translate | async }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let company of country.companies">
                                <td class="text-left">{{company.tinNumber}}</td>
                                <td class="text-left">
                                    <span *ngFor="let bizActivitiy of company.bizActivities">
                                        {{bizActivitiy}} - {{'FILOVERFOERSEL.REPORT.BIZACTIVITIES.'+bizActivitiy | translate | async}};
                                    </span>
                                </td>
                                <td>
                                    <span *ngFor="let name of company.names">
                                    {{name}};
                                    </span>
                                </td>
                                <td class="text-left">
                                    <span *ngFor="let address of company.addresses">
                                    {{address}};
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="skts-row-seperator extra-space report-table3" *ngIf="report.additionalInfos != null && doesAdditionalInfoContainCountryCode(country.countryCode)">
                <div *ngFor="let additionalInfo of additionalInfoWithCountryCode(country.countryCode)" class="additionalinfo">
                    <h3 *ngIf="additionalInfo.summaryRefs != undefined"><strong>{{'REPORT.TABEL3_FLERE.OVERSKRIFT' | translate:{felter: listSummaryRefCodes(additionalInfo.summaryRefs)} | async}}</strong></h3>
                    <h3 *ngIf="additionalInfo.summaryRefs == undefined"><strong>{{'REPORT.TABEL3.OVERSKRIFT' | translate | async}}</strong></h3>
                    <p>{{additionalInfo.otherInfo}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="skts-row-seperator extra-space general-additionalinfo" *ngIf="additionalInfoNoCountries(report.additionalInfos)">
        <div *ngFor="let additionalInfo of getAdditionalInfoNoCountries(report.additionalInfos)" class="additionalinfo">
            <h3 *ngIf="additionalInfo.summaryRefs != undefined"><strong>{{'REPORT.TABEL3_FLERE.OVERSKRIFT' | translate:{felter: listSummaryRefCodes(additionalInfo.summaryRefs)} | async}}</strong></h3>
            <h3 *ngIf="additionalInfo.summaryRefs == undefined"><strong>{{'REPORT.TABEL3.OVERSKRIFT' | translate | async}}</strong></h3>
            <p>{{additionalInfo.otherInfo}}</p>
        </div>
    </div>
</div>


<div id="ingen-data" *ngIf="!hasData()">
    <p><em>{{'INDBERETNING.INGEN_DATA' | translate | async }}</em></p>
</div>
