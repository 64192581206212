import { HttpClient, HttpClientModule, HttpXhrBackend } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { appRouting } from "./app.routing";
import { FiloverfoerselModule } from "./filoverfoersel/filoverfoersel.module";
import { ForsideComponent } from "./forside/forside.component";
import { IndberetningerModule } from './indberetninger/indberetninger.module';
import { FejlsideComponent } from "./shared/components/fejlside/fejlside.component";
import { RouterComponent } from "./shared/components/router/router.component";
import { UnavailableComponent } from "./shared/components/unavailable/unavailable.component";
import { SkatHttp } from "./shared/helpers/skat-http";
import { ConfigService } from "./shared/services/config/config.service";
import { DatePickerLocaleService } from "./shared/services/date-picker-locale/date-picker-locale.service";
import { ErrorService } from "./shared/services/error/error.service";
import { LanguageFileService, languageFileServiceFactory } from "./shared/services/language-file/language-file.service";
import { RedirectService } from "./shared/services/redirect/redirect.service";
import { TranslationService } from "./shared/services/translate/translation.service";
import { SharedModule } from "./shared/shared.module";
import { clearUserInfo, setTextKeysVisible, setUserIsBeingValidated } from "./shared/utils/session-storage-utils";
import { TilkendegivelserModule } from "./tilkendegivelser/tilkendegivelser.module";

@NgModule({
    imports: [
        BrowserModule,
        appRouting,
        HttpClientModule,
        FiloverfoerselModule,
        IndberetningerModule,
        TilkendegivelserModule,
        SharedModule,
    ],
    declarations: [
        RouterComponent,
        ForsideComponent,
        FejlsideComponent,
        UnavailableComponent,
    ],
    exports: [
        RouterComponent,
        ForsideComponent,
        FejlsideComponent,
        UnavailableComponent,
        RouterModule,
    ],
    providers: [
        {
            provide: HttpClient, useFactory: httpFactory,
            deps: [HttpXhrBackend]
        },
        ErrorService,
        ConfigService,
        TranslationService,
        LanguageFileService,
        DatePickerLocaleService,
        RedirectService,
        {
            provide: APP_INITIALIZER,
            useFactory: languageFileServiceFactory,
            deps: [LanguageFileService],
            multi: true
        }
    ],
    bootstrap: [RouterComponent]
})
export class AppModule {
    constructor() {
        clearUserInfo();
        setTextKeysVisible("false");
        setUserIsBeingValidated(false);
    }
}

export function httpFactory(backend: HttpXhrBackend) {
    return new SkatHttp(backend);
}
