import { AfterViewInit, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";
import { Fejl } from "../../models/fejl.model";
import { ErrorService } from "../../services/error/error.service";
import { TranslationService } from "../../services/translate/translation.service";

@Component({
    templateUrl: './fejlside.component.html'
})

export class FejlsideComponent implements AfterViewInit{

    errors:ErrorService;

    constructor(private route: ActivatedRoute, private errorService: ErrorService, private titleService: Title, private translationService: TranslationService) {
        this.errors = this.errorService;
    }

    ngOnInit() {
        const besked = this.route.snapshot.params.besked;
        this.errors.reset();
        this.errors.tilfoejFejl([new Fejl(besked)]);
    }

    ngAfterViewInit(){
        this.translationService.environmentAwareTranslate('GENERELT.FEJL.STANDARD_FEJLBESKED').subscribe((res: string) => {
            this.titleService.setTitle( res );
        });
    }
}
