import { Fejl } from "../models/fejl.model";

export function nyStandardFejl(textKey: string = "GENERELT.FEJL.STANDARD_FEJLBESKED"): Fejl {
    return new Fejl(textKey);
}

export function nyTekniskFejl(): Fejl {
    return new Fejl("GENERELT.FEJL.STANDARD_FEJLBESKED", null, true);
}

export function standardOverskrift(): string {
    return "GENERELT.FEJL.STANDARD_OVERSKRIFT";
}

export function nyRettighedsFejl(): Fejl {
    return new Fejl("GENERELT.FEJL.RETTIGHED_FEJLBESKED");
}

export function generateErrorItems(response: any, status: number): Fejl[] {
    const errors = [];
    if (status === 403) {
        errors.push(nyRettighedsFejl());
        return errors;
    }

    if (status >= 500 && status < 600) {
        errors.push(nyTekniskFejl());
        return errors;
    }

    if (response !== "" && response !== null && isJson(response)) {
        const json = JSON.parse(response);

        if (json.valideringsFejl !== undefined && status === 400 && json.valideringsFejl.length > 0) {
            for (const error of json.valideringsFejl) {
                if (error.fejlKode && error.fejlBeskrivelse) {
                    errors.push(new Fejl(error.fejlBeskrivelse, error.fejlKode.toString()));
                }
            }

        }
    }
    if (errors.length === 0) {
        errors.push(nyStandardFejl());
    }
    return errors;
}

export function isJson(text:any) {
    const stringifiedText = typeof text !== "string" ? JSON.stringify(text) : text;
    let json:string;
    try {
        json = JSON.parse(stringifiedText);
    } catch (e) {
        return false;
    }
    return typeof json === "object" && json !== null;
}
