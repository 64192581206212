import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable()
export class RedirectService {

    /**
     * Redirect to the login page. The login page will redirect back to `targetUrl` after a successful login.
     */
    redirectToLogin(targetUrl: string) {
        const targetUrlBase64 = window.btoa(window.location.origin + "/#" + targetUrl);
        window.location.href = environment.loginUrl + "?userType=virksomhed&targetUrl=" + targetUrlBase64;
    }

    redirectToNoAccess() {
        window.location.href = environment.noAccessUrl;
    }

}
