import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ErrorStylingService } from "../../../services/error-styling/error-styling.service";
import { RapporteringPeriodeSlutValidationService } from "../../../services/rapportering-periode-slut/rapportering-periode-slut-validation.service";
import { isParseableDkDate } from "../../../utils/date-utils";
import { DatovaelgerInputFeltComponent } from "../datovaelger-input-felt/datovaelger-input-felt.component";

@Component({
    selector: "skat-rapportering-periode-slut-input-felt",
    templateUrl: './rapportering-periode-slut-input-felt.component.html',
    providers: [RapporteringPeriodeSlutValidationService, ErrorStylingService]
})
export class RapporteringPeriodeSlutInputFeltComponent {

    @ViewChild('rapporteringPeriodeSlutDatepicker', {static: true}) datoVaelger: DatovaelgerInputFeltComponent;

    valid: boolean;
    private shouldShowErrorText: boolean;

    constructor(private validationService: RapporteringPeriodeSlutValidationService,
                private errorStylingService: ErrorStylingService) {
    }

    get feltId(): string {
        return this.datoVaelger.feltId;
    }

    @Input()
    set feltId(input: string) {
        this.datoVaelger.feltId = input;
    }

    get datePickerStart(): string {
        return this.datoVaelger.datePickerStart;
    }

    @Input()
    set datePickerStart(input: string) {
        this.datoVaelger.datePickerStart = input;
    }

    @Output() modelDateChange = new EventEmitter();

    @Input()
    get modelDate() {
        return this.datoVaelger.date;
    }

    privateInitialValue: string;

    get initialValue() {
        return this.privateInitialValue;
    }

    @Input()
    set initialValue(value: string) {
        this.privateInitialValue = value;
        this.removeErrorClass();
    }

    private previousValue: string;

    ngAfterViewInit(): void {
        this.validationService.validationResult.subscribe(
            (validationResult: boolean) => {
                this.valid = validationResult;
                this.shouldShowErrorText = this.modelDate !== this.initialValue && !validationResult;

                if (this.shouldShowErrorText) {
                    this.addErrorClass();
                } else {
                    this.removeErrorClass();
                }
            });
        this.validationService.validationError.subscribe(
            (status: number) => {
                this.valid = false;
                if (status !== 401 && status !== 403) {
                    this.shouldShowErrorText = false;
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.validationService.validationResult.unsubscribe();
        this.validationService.validationError.unsubscribe();
    }

    set modelDate(input: string) {
        if (input === this.previousValue) {
            return;
        }

        this.validateDate(input);
        this.previousValue = this.datoVaelger.date;
        this.datoVaelger.date = input;
        this.modelDateChange.emit(input);
    }

    private validateDate(rapporteringPeriodeSlut: string) {
        if (rapporteringPeriodeSlut === this.initialValue) {
            this.valid = true;
            this.removeErrorClass();
        } else if (isParseableDkDate(rapporteringPeriodeSlut)) {
            this.validationService.validate(rapporteringPeriodeSlut);
        } else {
            this.valid = false;
            this.removeErrorClass();
        }
    }

    focus() {
        this.addErrorClass();
        document.getElementById(this.feltId).focus();
    }

    private removeErrorClass() {
        const datoVaelgerElement = document.getElementById(this.datoVaelger.feltId);
        this.errorStylingService.removeErrorStyling(datoVaelgerElement);
        this.datoVaelger.shouldShowCustomErrorText = false;
    }

    private addErrorClass() {
        const datoVaelgerElement = document.getElementById(this.datoVaelger.feltId);
        this.errorStylingService.addErrorStyling(datoVaelgerElement);
        this.datoVaelger.shouldShowCustomErrorText = true;
    }
}
