export class ModerselskabInfoModel {

    skatteJurisdiktion: string;
    tin: string;
    navn: string;
    adresse: string;
    postNummer: string;
    by: string;
    rapporteringPeriodeStart: string;
    rapporteringPeriodeSlut: string;

    assignFrom(model: ModerselskabInfoModel): void {
        Object.keys(model).forEach(key => {
            this[key] = model[key];
        });
    }

    isIdenticalTo(model: ModerselskabInfoModel): boolean {
        const compareModel = model ? model : new ModerselskabInfoModel();
        return this.skatteJurisdiktion === compareModel.skatteJurisdiktion &&
            this.tin === compareModel.tin &&
            this.navn === compareModel.navn &&
            this.adresse === compareModel.adresse &&
            this.postNummer === compareModel.postNummer &&
            this.by === compareModel.by &&
            this.rapporteringPeriodeStart === compareModel.rapporteringPeriodeStart &&
            this.rapporteringPeriodeSlut === compareModel.rapporteringPeriodeSlut;
    }
}
