<div class="well indberetning" id="samlet-indberetning{{model.reportingPeriod}}">
    <div class="indberetning-wrap">
        <div class="information">
            <div class="row">
                <div class="col-sm-4 col-md-4 col-xl-2">{{ 'INDBERETNING.TYPE.LABEL' | translate | async }}</div>
                <div id=indberetningsType
                     class="col-sm-8 col-md-8 col-xl-10 indberetningsType">{{ 'INDBERETNING.TYPE.SAMLET' | translate | async }}</div>
            </div>

            <div class="row">
                <div class="col-sm-4 col-md-4 col-xl-2">{{ 'INDBERETNING.DATO.LABEL' | translate | async }}</div>
                <div class="col-sm-8 col-md-8 col-xl-10 date">{{ formatDate(model.date) }}</div>
            </div>
        </div>
        <div class="links text-right noprint">
            <span class="skts-icon skts-icon--print skts-icon--align-left"></span>
            <a href="javascript:void(0)" class="printReport-link" (click)="requestPrint(model.reportingPeriod)">{{ 'INDBERETNING.UDSKRIV' | translate | async }}</a>
        </div>
    </div>

    <div class="details-report">
        <h2 class="skts-row-seperator skts-collapse skts-collapse-arrow"
            (click)="fetchConsolidatedData(model.reportingPeriod)">
            <a aria-expanded="false" href="#">{{ 'INDBERETNING.SE_DETALJER.LABEL' | translate | async }}</a>
        </h2>
        <div class="hidden skts-toggle-hidden">
            <div>
                <div *ngIf="hasData(model.reportingPeriod)" class="skts-row-seperator"></div>

                <skat-report-view [report]="consolidatedReports.get(model.reportingPeriod)" [showIndividualCurrencies]="true"></skat-report-view>
            </div>
        </div>
    </div>
</div>
