import * as moment from 'moment';

export const DK_DATE_FORMAT: string = "DD-MM-YYYY";
export const DK_DATE_FORMAT_NO_DASHES: string = "DDMMYYYY";
export const ISO_DATE_FORMAT: string = "YYYY-MM-DD";
export const ZULU_DATE_FORMAT: string = "YYYY-MM-DDTHH:mm:ss:SSS";

export function isParseableDkDate(dateString: string): boolean {
    return /^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[1-9]\d{3}$/.test(dateString);
}

export function isParseableIsoDate(dateString: string): boolean {
    return /^[1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(dateString);
}

export function isoDateToDk(dateString: string): string {
    if (!isParseableIsoDate(dateString)) {
        return "";
    }
    return convertDateFormat(dateString, ISO_DATE_FORMAT, DK_DATE_FORMAT);
}

export function zuluDateToDkNoDashes(dateString: string): string {
    return convertDateFormat(dateString, ZULU_DATE_FORMAT, DK_DATE_FORMAT_NO_DASHES);
}

export function dkDateToIso(dateString: string): string {
    if (!isParseableDkDate(dateString)) {
        return "";
    }
    return convertDateFormat(dateString, DK_DATE_FORMAT, ISO_DATE_FORMAT);
}

function convertDateFormat(dateString: string, dateFormatFrom: string, dateFormatTo: string) {
    const output = moment(dateString, dateFormatFrom).format(dateFormatTo);
    if (output === "Invalid date") {
        return "";
    } else {
        return output;
    }
}
