<div class="inline" id="printable-{{model.id}}">

    <header class="pdf-only pdf-header" id="pdf-header-{{model.id}}">
        <div class="container">
            <div class="skts-logo">
            </div>
        </div>
    </header>

    <div class="pdf-body" id="pdf-body-{{model.id}}">

        <h1 class="pdf-only" id="pdf-overskrift-{{model.id}}">
            {{ 'TILKENDEGIVELSER.KVITTERING.OVERSKRIFT' | translate | async }}
        </h1>

        <h2 class="pdf-only" id="pdf-label-{{model.id}}">
            {{ 'TILKENDEGIVELSER.PDF.KVITTERING_LABEL' | translate | async }}
        </h2>

        <div class="pdf-only tilkendegivelse-view-row">
            {{ 'TILKENDEGIVELSER.KVITTERING.INFO.LABEL' | translate | async }}
        </div>

        <div class="tilkendegivelse" id="tilkendegivelse-{{model.id}}">
            <div class="row tilkendegivelse-view-row">
                <div class="col-sm-3 col-md-3 col-xl-2">{{ 'TILKENDEGIVELSE.VIEW.DATO.LABEL' | translate | async }}</div>
                <div class="col-sm-9 col-md-9 col-xl-10" id="tilkendegivelse-{{model.id}}-dato">{{ model.oprettelsesTidDk() }}</div>
            </div>

            <div id="tilkendegivelse-omsaetning-info-{{model.id}}" class="row tilkendegivelse-view-row">
                <div class="col-md-12">
                    <span id="har-tilkendegivit-{{model.id}}">
                       <ng-container *ngIf="indberetterAndPligtigAreTheSame()">
                           {{ 'TILKENDEGIVELSE.VIEW.CVR_HAR_TILKENDEGIVIT' | translate:{indberetter: model.indberetter} | async }}
                       </ng-container>
                       <ng-container *ngIf="!indberetterAndPligtigAreTheSame()">
                           {{ 'TILKENDEGIVELSE.VIEW.CVR_HAR_TILKENDEGIVIT_PVA' | translate:{indberetter: model.indberetter, pligtig: model.pligtig} | async }}
                       </ng-container>
                    </span>

                    <span id="omfattet-af-cbc-{{model.id}}" class="bold">
                        <ng-container *ngIf="model.omfattetAfCbc">
                            {{ 'TILKENDEGIVELSE.VIEW.OMSAETNING.OMFATTET_AF_CBC' | translate | async }}
                        </ng-container>
                        <ng-container *ngIf="!model.omfattetAfCbc">
                            {{ 'TILKENDEGIVELSE.VIEW.OMSAETNING.IKKE_OMFATTET_AF_CBC' | translate | async }}
                        </ng-container>
                    </span>

                    <span class="inline">{{ 'TILKENDEGIVELSE.VIEW.I_REGNSKABSPERIODEN' | translate | async }}</span>
                    <span id="tilkendegivelse-{{model.id}}-omsaetningsperiode" class="bold">{{ model.omsaetningPeriodeStartDk()}} - {{ model.omsaetningPeriodeSlutDk()}}</span>.
                </div>
            </div>

            <div class="row">
                <div id="tilkendegivelse-forventer-info-{{model.id}}" class="col-md-12">
                    <div class="tilkendegivelse-view-row" *ngIf="model.omfattetAfCbc">{{ 'TILKENDEGIVELSE.VIEW.FORVENTER_JA' | translate | async }}</div>
                    <div *ngIf="!model.omfattetAfCbc">{{ 'TILKENDEGIVELSE.VIEW.FORVENTER_NEJ' | translate | async }}</div>
                </div>
            </div>

            <div class="inline" id="moderselskabInfo-wrapper" *ngIf="model.omfattetAfCbc">
                <div class="row">
                    <div class="col-sm-3 col-md-3 col-xl-2">{{ 'TILKENDEGIVELSE.VIEW.RAPPORTERING.PERIODE_SLUT.LABEL' | translate | async }}</div>
                    <div class="col-sm-9 col-md-9 col-xl-10" id="tilkendegivelse-{{model.id}}-rapportering-periode-slut">{{ model.rapporteringPeriodeSlutDk() }}</div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-md-3 col-xl-2">{{ 'TILKENDEGIVELSE.VIEW.RAPPORTERING.SKATTEJURISDIKTION.LABEL' | translate | async }}</div>
                    <div class="col-sm-9 col-md-9 col-xl-10" id="tilkendegivelse-{{model.id}}-skattejurisdiktion">{{ model.jurisdictionName() }}</div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-md-3 col-xl-2" id="tilkendegivelse-{{model.id}}-tin-label">{{ ((model.moderselskabInfo?.skatteJurisdiktion == 'DK') ? 'TILKENDEGIVELSE.VIEW.RAPPORTERING.TIN.DANSK_ULTIMATIV_EJER' : 'TILKENDEGIVELSE.VIEW.RAPPORTERING.TIN.UDENLANDSK_ULTIMATIV_EJER') | translate | async }}</div>
                    <div class="col-sm-9 col-md-9 col-xl-10" id="tilkendegivelse-{{model.id}}-tin">{{ model.moderselskabInfo?.tin }}</div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-md-3 col-xl-2">{{ 'TILKENDEGIVELSE.VIEW.RAPPORTERING.NAVN.LABEL' | translate | async }}</div>
                    <div class="col-sm-9 col-md-9 col-xl-10" id="tilkendegivelse-{{model.id}}-navn">{{ model.moderselskabInfo?.navn }}</div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-md-3 col-xl-2">{{ 'TILKENDEGIVELSE.VIEW.RAPPORTERING.ADRESSE.LABEL' | translate | async }}</div>
                    <div class="col-sm-9 col-md-9 col-xl-10 scroll-on-overflow" id="tilkendegivelse-{{model.id}}-adresse">
                        {{ model.fullAddress() }}
                    </div>
                </div>
            </div>

            <div id="pdf-button-{{model.id}}" class="row print-row no-print">
                <div class="pull-right">
                    <a *ngIf="correctLinkEnabled"
                       class="correct-link"
                       (click)="goToRetTilkendegivelse()"><span class="skts-icon skts-icon--ret skts-icon--align-left"></span>{{ 'TILKENDEGIVELSE.VIEW.RET' | translate | async }}
                    </a>

                    <a *ngIf="pdfLinkEnabled"
                       class="download-pdf-link"
                       id="download-pdf-link"
                       (click)="downloadAsPortableDocumentFormat()"><span class="skts-icon skts-icon--pdf-round skts-icon--align-left"></span>{{ 'TILKENDEGIVELSE.VIEW.GEM_SOM_PDF' | translate | async }}</a>
                </div>
            </div>

        </div>

        <div class="pdf-only">
            <div class="du-kan-rette">
                <div>{{ 'TILKENDEGIVELSER.KVITTERING.DU_KAN_RETTE' | translate | async }}</div>
                <a routerLink="/tidligere-tilkendegivelser">{{ 'TILKENDEGIVELSER.KVITTERING.TIDLIGER_TILKENDEGIVELSER_LINK_LABEL' | translate | async }}</a>
            </div>

            <div class="du-kan-laese">
                <div>{{ 'TILKENDEGIVELSER.KVITTERING.DU_KAN_LAESE' | translate | async }}</div>
                <a href="https://www.skat.dk">{{ 'TILKENDEGIVELSER.KVITTERING.SKAT_LINK_LABEL' | translate | async }}</a>
            </div>

            <div class="venlig-hilsen">
                <div>{{ 'TILKENDEGIVELSER.KVITTERING.VENLIG_HILSEN' | translate | async }}</div>
            </div>
        </div>
    </div>

    <footer class="pdf-only pdf-footer" id="pdf-footer-{{model.id}}">
        <div class="container">
            <div class="pull-left">
                <address>Kontakt os: <a href="tel:72221818">72 22 18 18</a></address>
            </div>
        </div>
        <div class="skts-footer-info">
            <p class="text-center">skat.dk er Skatteforvaltningens digitale indgang til selvbetjening og vejledning om skatter og afgifter</p>
        </div>
    </footer>

</div>
