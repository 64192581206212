import { AdditionalInfoModel } from '../models/additional-info.model';
import { CompanyModel } from '../models/company.model';
import { CountryModel } from "../models/country.model";
import { ReportModel } from "../models/report.model";
import { RevenueModel } from "../models/revenue.model";
import { getCountryNameByCode } from "./text-utils";

export function mapConsolidatedJsonToReportModel(obj: any): ReportModel {
    const report = new ReportModel();
    if (obj === null) {
        return report;
    }

    // Country info
    const cbcReportsCountryCodes: string[] = [];
    const cbcReports = obj.data.attributes.cbcReports;
    if (cbcReports !== undefined) {
        report.countries = [];
        for (const cbcReport of cbcReports) {
            report.countries.push(getCountry(cbcReport, cbcReportsCountryCodes));
        }
    }

    // Additional info
    const additionalInfoCountryCodes: string[] = [];
    const additionalInfos = obj.data.attributes.additionalInfos;
    if (additionalInfos !== undefined) {
        report.additionalInfos = [];
        for (const additionalInfo of additionalInfos) {
            report.additionalInfos.push(getAdditionalInfo(additionalInfo, additionalInfoCountryCodes));
        }
    }

    // Add any countries that might be mentioned in additionalInfos but not in cbcReports
    for (const countryCode of additionalInfoCountryCodes) {
        if (!cbcReportsCountryCodes.includes(countryCode)) {
            const consolidatedCountry = new CountryModel();
            consolidatedCountry.countryCode = countryCode;
            consolidatedCountry.countryName = getCountryNameByCode(countryCode);
            report.countries.push(consolidatedCountry);
        }
    }

    return report;
}

/**
 * Extracts AdditionalInfo data from the provided JSON and returns a AdditionalInfoModel.
 * @param additionalInfosJson a child element of an `additionalInfos` JSON array.
 * @param additionalInfoCountryCodes an array to push all found (distinct) ResCountryCodes to.
 */
function getAdditionalInfo(additionalInfosJson: any, additionalInfoCountryCodes: string[]): AdditionalInfoModel {
    const additionalInfoModel = new AdditionalInfoModel();

    additionalInfoModel.otherInfo = additionalInfosJson.otherInfo;

    if (additionalInfosJson.resCountryCodes !== undefined) {
        additionalInfoModel.resCountryCodes = [];
        for (const countryCode of additionalInfosJson.resCountryCodes) {
            additionalInfoModel.resCountryCodes.push(countryCode);
            if (!additionalInfoCountryCodes.includes(countryCode)) {
                additionalInfoCountryCodes.push(countryCode);
            }
        }
    }

    if (additionalInfosJson.summaryRefs !== undefined) {
        additionalInfoModel.summaryRefs = [];
        for (const ref of additionalInfosJson.summaryRefs) {
            additionalInfoModel.summaryRefs.push(ref);
        }
    }

    return additionalInfoModel;
}

function getRevenue(revenuesJson: any): RevenueModel {
    const revenue = new RevenueModel();
    revenue.unrelated = revenuesJson.unrelated;
    revenue.related = revenuesJson.related;
    revenue.total = revenuesJson.total;
    return revenue;
}

/**
 * Extracts cbcReports data from the provided JSON and returns a CountryModel.
 * @param cbcReportsJson a child element of an `cbcReports` JSON array.
 * @param countryCodes an array to push all found (distinct) ResCountryCodes to.
 */
function getCountry(cbcReportsJson: any, countryCodes: string[]): CountryModel {
    countryCodes.push(cbcReportsJson.resCountryCode);

    const consolidatedCountry = new CountryModel();

    consolidatedCountry.countryCode = cbcReportsJson.resCountryCode;
    consolidatedCountry.countryName = getCountryNameByCode((cbcReportsJson.resCountryCode));
    consolidatedCountry.noOfEmployees = cbcReportsJson.noOfEmployees;
    consolidatedCountry.profitOrLoss = cbcReportsJson.profitOrLoss;
    consolidatedCountry.taxPaid = cbcReportsJson.taxPaid;
    consolidatedCountry.taxAccrued = cbcReportsJson.taxAccrued;
    consolidatedCountry.capital = cbcReportsJson.capital;
    consolidatedCountry.earnings = cbcReportsJson.earnings;
    consolidatedCountry.assets = cbcReportsJson.assets;
    consolidatedCountry.revenue = getRevenue(cbcReportsJson.revenues);

    consolidatedCountry.companies = [];
    for (const entity of cbcReportsJson.constEntities) {
        consolidatedCountry.companies.push(getCompany(entity));
    }

    return consolidatedCountry;
}

export function getCompany(constEntitiesJson: any): CompanyModel {
    const company = new CompanyModel();

    company.tinNumber = constEntitiesJson.tinNumber;

    if (constEntitiesJson.names !== undefined) {
        company.names = [];
        for (const entityName of constEntitiesJson.names) {
            company.names.push(entityName);
        }
    }

    if (constEntitiesJson.bizActivities !== undefined) {
        company.bizActivities = [];
        for (const activity of constEntitiesJson.bizActivities) {
            company.bizActivities.push(activity);
        }
    }

    if (constEntitiesJson.addresses !== undefined) {
        company.addresses = [];
        for (const entityAddress of constEntitiesJson.addresses) {
            company.addresses.push(getAddress(entityAddress));
        }
    }

    return company;
}

/**
 * Returns a formatted version of the address information in the JSON.
 * If the `city` element exists, returns an address of the form
 * `<street name> <building identifier>, <post code> <city>`,
 * and otherwise returns the contents of the `addressFree` element (which is then assumed to exist).
 * @param addressJson a child element of an `addresses` JSON array.
 */
export function getAddress(addressJson: any): string {
    let result = '';

    if (addressJson.city !== undefined) {
        let street = '';
        if (addressJson.street !== undefined) {
            street += addressJson.street;
        }
        if (addressJson.buildingIdentifier !== undefined) {
            street += (street !== '' ? ' ' : '') + addressJson.buildingIdentifier;
        }
        let postCode = '';
        if (addressJson.postCode !== undefined) {
            postCode += addressJson.postCode;
        }

        if (street !== '') {
            result = street + ', ';
        }
        if (postCode !== '') {
            result += postCode + ' ';
        }
        result += addressJson.city;
    } else {
        result = addressJson.addressFree;
    }

    return result;
}
