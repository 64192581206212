<div class="skts-wrap">
    <header>
        <div class="col-sm-3 hidden-xs">
            <div style="color: white; line-height: 40px; padding: 20px 20px 0 20px;"><a (click)="goToPortal()">{{'<'}} {{ 'GENERELT.HEADER.TILBAGE_TIL_MENU_KNAP' | translate | async }}</a></div>
        </div>
        <div class="container">
            <div class="skts-logo">
                <a href="https://www.skat.dk/front/appmanager/skat/ntse">
                    <span class="sr-only">{{ 'GENERELT.HEADER.SCREENREADER.TIL_FORSIDE' | translate | async }}</span>
                </a>
            </div>
            <ul class="skts-focus-links">
                <li><a id="skts-skip-til-indhold" href="#skts-skip-link-target-indhold">Gå til indhold</a></li>
            </ul>
            <a class="skts-login pull-right" href="https://www.skat.dk/SKAT.aspx?oId=69534">{{ 'GENERELT.HEADER.LOG_AF' | translate | async }}</a>
        </div>
    </header>
    <div class="skts-breadcrumb-container">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-8 hidden-xs">
                    <ol class="breadcrumb">
                    </ol>
                </div>
                <div *ngIf="pligtig" class="col-sm-4 text-center skts-user-info">
                    <span id="indberetterCompanyName">{{indberetterCompanyName}}</span> {{ 'GENERELT.HEADER.SENUMMER_LABEL' | translate | async }} <span id="indberetter">{{indberetter}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div *ngIf="shouldShowPligtig()" class="container-fluid">
                <div class="row">
                    <div class="col-sm-4 col-sm-offset-8 text-center skts-sub-user-info">{{ 'GENERELT.HEADER.PAA_VEGNE_AF_LABEL' | translate | async }} <span id="pligtig">{{pligtig}} </span> <span id="pligtigCompanyName">{{pligtigCompanyName}}</span></div>
                </div>
            </div>
        </div>
    </div>

    <div class="container skts-top-pad-1">
        <a class="sr-only" [skatOnLinkOnEnter]="focusOnLinkOnEnterMethod" [elementId]="'skts-skip-til-indhold'"
           id="skts-skip-link-target-indhold" tabindex="0">Start af indhold</a>
        <router-outlet></router-outlet> <!--loader CBC forsiden -->
    </div>
    <div class="skts-push"></div>
</div>

<footer>
    <div class="container">
        <div class="pull-left">
            <address>{{ 'GENERELT.FOOTER.KONTAKT_OS' | translate | async }}<a href="{{ 'GENERELT.FOOTER.TELEFONNUMMER.HREF' | translate | async }}">{{ 'GENERELT.FOOTER.TELEFONNUMMER.LABEL' | translate | async }}</a></address>
        </div>
        <div class="pull-right">
            <a target="_blank" href="https://www.skat.dk/skat.aspx?oid=3038">{{ 'GENERELT.FOOTER.OM_COOKIES.LABEL' | translate | async }}<span class="sr-only"> {{ 'GENERELT.FOOTER.OM_COOKIES.ÅBNER_I_NYT_VINDUE' | translate | async }}</span></a>
        </div>
    </div>
    <div class="skts-footer-info">
        <p class="text-center">{{ 'GENERELT.FOOTER.INFO' | translate | async }}</p>
    </div>
</footer>
