import { RouterModule, Routes } from "@angular/router";
import { ForsideComponent } from "./forside/forside.component";
import { FejlsideComponent } from "./shared/components/fejlside/fejlside.component";
import { UnavailableComponent } from "./shared/components/unavailable/unavailable.component";

export const routes: Routes = [
    {
        path: '',
        component: ForsideComponent,
        pathMatch: 'full'
    },
    {
        path: 'forside',
        component: ForsideComponent
    },
    {
        path: 'fejlside',
        component: FejlsideComponent
    },
    {
        path: 'unavailable',
        component: UnavailableComponent
    },
    {
        path: 'tilkendegiv',
        loadChildren: () => import('../app/tilkendegivelser/tilkendegivelser.module').then(m => m.TilkendegivelserModule)
    },
    {
        path: 'valider',
        loadChildren: () => import('../app/filoverfoersel/filoverfoersel.module').then(m => m.FiloverfoerselModule),
        pathMatch: 'full'
    },
    {
        path: 'tidligere-indberetninger',
        loadChildren: () => import('../app/indberetninger/indberetninger.module').then(m => m.IndberetningerModule),
        pathMatch: 'full'
    },
    {
        path: "tilkendegiv",
        loadChildren: () => import('../app/tilkendegivelser/tilkendegivelser.module').then(m => m.TilkendegivelserModule),
        pathMatch: 'full'
    },
    {
        path: "tidligere-tilkendegivelser",
        loadChildren: () => import('../app/tilkendegivelser/tilkendegivelser.module').then(m => m.TilkendegivelserModule),
        pathMatch: 'full'
    },
    {
        path: '**',
        component: ForsideComponent
    }
];

export const appRouting = RouterModule.forRoot(routes, {useHash: true});
