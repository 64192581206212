export const environment = {
    production: true,
    apiUrl: 'https://sso-api.tsecbcindberet.skat.dk',
    portalUrl: 'https://www.skat.dk/front/appmanager/skat/ntse?_nfpb=true&_nfpb=true&_pageLabel=Land_for_land_CbC&_nfls=false', // <-- Skal være tom string i lokal configuration
    logoutUrl: 'https://www.skat.dk/front/LogoutRedirectServlet', // <-- ... og det samme gælder for denne

    // External login page. Error page is used in development since there is no dev login page.
    loginUrl: 'https://pdcs.skat.dk/dcs-atn-gateway/login/tsklogin',
    noAccessUrl: 'https://pdcs.skat.dk/dcs-atn-gateway/error/noacess'
};
