<form role="form" (ngSubmit)="tilkendegiv()">
    <div class="clearfix">
        <h1 id="tilkendegiv-overskrift" class="pull-left">{{'TILKENDEGIVELSER.TILKENDEGIV.OVERSKRIFT' | translate |
            async }}</h1>
    </div>

    <ul class="skts-navbar-wizard">
        <li class="active"><span>{{ 'TILKENDEGIVELSER.TILKENDEGIV.PROGRESSBAR.TILKENDEGIV' | translate | async }}</span></li>
        <li><span>{{ 'TILKENDEGIVELSER.TILKENDEGIV.PROGRESSBAR.KVITTERING' | translate | async }}</span></li>
    </ul>

    <skat-fejl-liste *ngIf="errors.derErFejl()" [titel]="errorsOverskrift"></skat-fejl-liste>

    <div class="well">
        <div class="skts-row-seperator">
            <div class="well-row skts-required">
                <fieldset>
                    <div class="well-row">
                        <legend>
                            {{'TILKENDEGIVELSER.TILKENDEGIV.OMSÆTNING.LABEL' | translate | async }}
                        </legend>
                        <skat-hjaelpetekst id="helptext-tilkendegivelse" guid="8835"></skat-hjaelpetekst>
                    </div>
                    <div class="skts-process-form-section skts-required">
                        <div class="col-md-12 omsaetning-buttons-container">
                            <div class="btn-group-vertical skts-process-options skts-required" data-toggle="buttons">
                                <div class="btn skts-btn-radio" (click)="omfattetAfCbcClicked(true)">
                                    <input #inputTjentOver
                                           class="js-option-required"
                                           id="input-tjentOver"
                                           name="tjentRadio"
                                           type="radio"
                                           aria-required="true"
                                           aria-invalid="true"
                                    >
                                    <label for="input-tjentOver">{{'TILKENDEGIVELSER.TILKENDEGIV.OMSÆTNING.TJENTOVER' | translate | async }}</label>
                                </div>
                                <div class="btn skts-btn-radio" (click)="omfattetAfCbcClicked(false)">
                                    <input #inputTjentUnder
                                           class="js-option-required"
                                           id="input-tjentUnder"
                                           name="tjentRadio"
                                           type="radio"
                                           aria-required="true"
                                           aria-invalid="true"
                                    >
                                    <label for="input-tjentUnder">{{'TILKENDEGIVELSER.TILKENDEGIV.OMSÆTNING.TJENTUNDER' | translate | async }}</label>
                                </div>
                            </div>
                            <div class="skts-option-error-text" role="alert">{{ 'TILKENDEGIVELSER.TILKENDEGIV.OMSÆTNING.REQUIRED' | translate | async}}</div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="row skts-process-form-section skts-required">
                <div class="col-md-1 col-md-regnskabsperiodeSpace">
                    <legend>{{'TILKENDEGIVELSER.TILKENDEGIV.OMSAETNINGSPERIODE.LABEL' | translate | async }}</legend>
                    <skat-hjaelpetekst id="helptext-omsaetningsperiode" guid="8885"></skat-hjaelpetekst>
                </div>
                <div class="col-md-1 col-md-regnskabsperiodeDato">
                    <label [attr.for]="'input-omsaetningPeriodeStart'" class="screenreader-only">
                        {{'TILKENDEGIVELSER.TILKENDEGIV.SCREENREADER.OMSÆTNINGSPERIODESTART' | translate | async }}</label>
                    <skat-datovaelger-input-felt
                        [(modelDate)]="tilkendegivelseModel.omsaetningPeriodeStart"
                        [feltId]="'input-omsaetningPeriodeStart'"
                        [paakraevet]="true"
                        [datePickerStart]="'01-01-1900'"
                        [datePickerSlut]="'31-12-'+currentYearString()">
                    </skat-datovaelger-input-felt>
                </div>
                <div class="col-md-1 col-md-regnskabsperiodeDash">-</div>
                <div class="col-md-1 col-md-regnskabsperiodeDato">
                    <label [attr.for]="'input-omsaetningPeriodeSlut'" class="screenreader-only">
                        {{'TILKENDEGIVELSER.TILKENDEGIV.SCREENREADER.OMSÆTNINGSPERIODESLUT' | translate | async }}</label>
                    <skat-datovaelger-input-felt
                        #omsaetningPeriodeSlutDatepicker
                        [(modelDate)]="tilkendegivelseModel.omsaetningPeriodeSlut"
                        [feltId]="'input-omsaetningPeriodeSlut'"
                        [paakraevet]="true"
                        [datePickerStart]="calculateOmsaetningPeriodeSlut()"
                        [datePickerSlut]="'31-12-'+currentYearString()">
                    </skat-datovaelger-input-felt>
                </div>
            </div>
        </div>
    </div>

    <div class="inline" id="moderselskabInfo-wrapper" *ngIf="tilkendegivelseModel.omfattetAfCbc">
        <div class="clearfix">
            <h2 id="tilkendegiv-indsender-overskift">{{'TILKENDEGIVELSER.TILKENDEGIV.OPLYSNINGER' | translate | async }}</h2>
        </div>

        <div class="well">
            <div class="row skts-process-form-section skts-required">
                <div class="col-md-1 col-md-regnskabsperiodeSpace">
                    <legend id="rapporteringPeriodeSlutLabel">
                        {{'TILKENDEGIVELSER.TILKENDEGIV.RAPPORTERINGSPERIODESLUT.LABEL' | translate | async }}</legend>
                    <skat-hjaelpetekst id="helptext-rapporteringsperiode" guid="8833"></skat-hjaelpetekst>
                </div>
                <div class="col-md-1 col-md-regnskabsperiodeDato">
                    <label [attr.for]="'input-rapporteringPeriodeStart'" class="screenreader-only">
                        {{'TILKENDEGIVELSER.TILKENDEGIV.SCREENREADER.RAPPORTERINGSPERIODESTART' | translate | async }}</label>
                    <skat-datovaelger-input-felt
                        [(modelDate)]="tilkendegivelseModel.moderselskabInfo.rapporteringPeriodeStart"
                        [feltId]="'input-rapporteringPeriodeStart'"
                        [paakraevet]="true">
                    </skat-datovaelger-input-felt>
                </div>
                <div class="col-md-1 col-md-regnskabsperiodeDash">-</div>
                <div class="col-md-1 col-md-regnskabsperiodeDato">
                    <label [attr.for]="'input-rapporteringPeriodeSlut'" class="screenreader-only">
                        {{'TILKENDEGIVELSER.TILKENDEGIV.SCREENREADER.RAPPORTERINGSPERIODESLUT' | translate | async }}</label>
                    <skat-rapportering-periode-slut-input-felt
                        #rapporteringPeriodeSlutComponent
                        [(modelDate)]="tilkendegivelseModel.moderselskabInfo.rapporteringPeriodeSlut"
                        [feltId]="'input-rapporteringPeriodeSlut'"
                        [datePickerStart]="calculateRapporteringPeriodeSlut()"
                        [initialValue]="initialRapporteringPeriodeSlutValue">
                    </skat-rapportering-periode-slut-input-felt>
                </div>
            </div>
            <div class="skts-row-seperator">
                <div class="row col-md-12">
                    <h2>{{'TILKENDEGIVELSER.TILKENDEGIV.INDSENDER' | translate | async }}</h2>
                    <skat-hjaelpetekst id="helptext-indsender" guid="8837"></skat-hjaelpetekst>
                </div>
                <div class="row skts-process-form-section skts-required">
                    <div class="col-sm-6 col-md-6">
                        <label for="input-skattejurisdiktion" id="skattejurisdiktionLabel">{{'TILKENDEGIVELSER.TILKENDEGIV.SKATTEJURISDIKTION.LABEL' | translate | async }}</label>
                    </div>
                    <div class="col-sm-6 col-md-6">
                        <select class="form-control skts-required-val" id="input-skattejurisdiktion"
                                name="skattejurisdiktion"
                                aria-invalid="true" aria-required="true"
                                (change)="jurisdictionChanged($event)"
                                [(ngModel)]="tilkendegivelseModel.moderselskabInfo.skatteJurisdiktion">
                            <option [value]="undefined" disabled hidden selected >{{'TILKENDEGIVELSER.TILKENDEGIV.SKATTEJURISDIKTION.PLACEHOLDER' | translate | async }}</option>
                            <option *ngFor="let land of countries()" value="{{land.value}}">{{ land.key }}</option>
                        </select>
                        <div class="skts-error-text" role="alert">{{'TILKENDEGIVELSER.TILKENDEGIV.SKATTEJURISDIKTION.MANGLER' | translate | async }}</div>
                    </div>
                </div>
                <div class="skts-process-form-section skts-required">
                    <skat-se-number-input-felt
                        #tin
                        [(seNumber)]='tilkendegivelseModel.moderselskabInfo.tin'
                        [fieldId]="'input-tin'"
                        [fieldName]="'tin'"
                        [textLabel]="'TILKENDEGIVELSER.TILKENDEGIV.TIN.LABEL'"
                        [textPlaceHolder]="'TILKENDEGIVELSER.TILKENDEGIV.TIN.PLACEHOLDER'"
                        [textWrongFormat]="'TILKENDEGIVELSER.TILKENDEGIV.TIN.HELE_TAL'"
                        [textMinLength]="'TILKENDEGIVELSER.TILKENDEGIV.TIN.MINIMUM_TEGN'"
                        [textMaxLength]="'TILKENDEGIVELSER.TILKENDEGIV.TIN.MAKSIMUM_TEGN'"
                        [textRequired]="'TILKENDEGIVELSER.TILKENDEGIV.TIN.MANGLER'"
                        [textEsValidationFailed]="'TILKENDEGIVELSER.TILKENDEGIV.TIN.ES_VALIDERING'"
                        [validationEnabled]="hasDKJurisdiction()">
                    </skat-se-number-input-felt>
                </div>
                <div class="row skts-process-form-section skts-required">
                    <div class="col-sm-6 col-md-6">
                        <label for="input-navn">{{'TILKENDEGIVELSER.TILKENDEGIV.NAVN.LABEL' | translate | async }}</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control skts-required-val" id="input-navn"
                               name="navn"
                               aria-invalid="true"
                               aria-required="true"
                               data-show-type="string"
                               [(ngModel)]="tilkendegivelseModel.moderselskabInfo.navn"
                               maxlength='255'
                               placeholder="{{'TILKENDEGIVELSER.TILKENDEGIV.NAVN.PLACEHOLDER' | translate | async }}">
                        <div class="skts-error-text" role="alert"></div>
                        <div class="skts-error-text" role="alert">{{'TILKENDEGIVELSER.TILKENDEGIV.NAVN.MANGLER' | translate | async }}</div>
                    </div>
                </div>
                <div class="row  skts-process-form-section skts-required">
                    <div class="col-sm-6 col-md-6">
                        <label for="input-adresse">{{'TILKENDEGIVELSER.TILKENDEGIV.ADRESSE.LABEL' | translate | async }}</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control skts-required-val" id="input-adresse"
                               name="adresse"
                               aria-invalid="true"
                               aria-required="true"
                               data-show-type="string"
                               [(ngModel)]="tilkendegivelseModel.moderselskabInfo.adresse"
                               maxlength='255'
                               placeholder="{{'TILKENDEGIVELSER.TILKENDEGIV.ADRESSE.PLACEHOLDER' | translate | async }}">
                        <div class="skts-error-text" role="alert"></div>
                        <div class="skts-error-text" role="alert">{{'TILKENDEGIVELSER.TILKENDEGIV.ADRESSE.MANGLER' | translate | async }}</div>
                    </div>
                </div>
                <div class="row skts-process-form-section skts-required">
                    <div class="col-md-9">
                        <label for="input-postnummer">{{'TILKENDEGIVELSER.TILKENDEGIV.POSTNUMMER.LABEL' | translate | async }}</label>
                    </div>
                    <div class="col-md-3">
                        <input type="text" class="form-control skts-required-val" id="input-postnummer"
                               name="postnummer"
                               aria-invalid="true"
                               aria-required="true"
                               data-show-type="string"
                               [(ngModel)]="tilkendegivelseModel.moderselskabInfo.postNummer"
                               maxlength='255'
                               placeholder="{{'TILKENDEGIVELSER.TILKENDEGIV.POSTNUMMER.PLACEHOLDER' | translate | async }}">
                        <div class="skts-error-text" role="alert"></div>
                        <div class="skts-error-text" role="alert">{{'TILKENDEGIVELSER.TILKENDEGIV.POSTNUMMER.MANGLER' | translate | async }}</div>
                    </div>
                </div>
                <div class="row skts-process-form-section  skts-required">
                    <div class="col-md-8">
                        <label for="input-by">{{'TILKENDEGIVELSER.TILKENDEGIV.BY.LABEL' | translate | async }}</label>
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control skts-required-val" id="input-by"
                               name="by"
                               aria-invalid="true"
                               aria-required="true"
                               data-show-type="string"
                               [(ngModel)]="tilkendegivelseModel.moderselskabInfo.by"
                               maxlength='255'
                               placeholder="{{'TILKENDEGIVELSER.TILKENDEGIV.BY.PLACEHOLDER' | translate | async }}">
                        <div class="skts-error-text" role="alert"></div>
                        <div class="skts-error-text" role="alert">{{'TILKENDEGIVELSER.TILKENDEGIV.BY.MANGLER' | translate | async }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="clearfix skts-wizard-buttons">
        <a id="knap-annuller"  *ngIf="corrects" routerLink="/tidligere-tilkendegivelser">
            <input type="button"
                   class="pull-left btn btn-default skts-btn-secondary"
                   value="{{ 'TILKENDEGIVELSER.TILKENDEGIV.ANNULLER_KNAP' | translate | async }}"/>
        </a>
        <input type="submit" class="pull-right btn btn-default js-form-validate" [ngClass]="{'disabled': shouldDisableSubmitButton()}" id="knap-tilkendegiv"
               value="{{ submitButtonLabel() | translate | async }}"/>
    </div>
</form>
