import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FiluploadFactory {

    onError: Subject<[any, number]> = new Subject<[any, number]>();
    onSuccess: Subject<[any, number]> = new Subject<[any, number]>();

    constructor(){}

    getXMLHttpRequest(): XMLHttpRequest {
        return new XMLHttpRequest();
    }

    createXMLHttpRequest(progressEvent: EventEmitter<{}>): XMLHttpRequest {

        const xhr = this.getXMLHttpRequest();
        xhr.withCredentials = true;

        xhr.upload.onprogress = (event) => {
            const progress = Math.round(event.lengthComputable ? event.loaded * 100 / event.total : 0);
            progressEvent.emit(progress);
        };

        xhr.onload = () => {
            if (xhr.readyState === xhr.DONE) {
                const response = xhr.response;
                this.isSuccessCode(xhr.status) ?
                    this.onSuccess.next([response, xhr.status])
                     : this.onError.next([response, xhr.status]);
            }
        };

        xhr.onerror = () => {
            const response = xhr.response;
            this.onError.next([response, xhr.status]);
        };

        xhr.onabort = () => {
            const response = xhr.response;
            this.onError.next([response, xhr.status]);
        };

        return xhr;
    }

    isSuccessCode(status: any) {
        return (status >= 200 && status < 300) || status === 304;
    }

}
