import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { todayAsPrettyText } from "../../../utils/text-utils";

@Component({
    selector: "skat-datovaelger-input-felt",
    templateUrl: './datovaelger-input-felt.component.html',
    providers: []
})

export class DatovaelgerInputFeltComponent implements AfterViewInit, OnDestroy {

    feltNavn: string;
    datePickerIsInit: boolean = false;
    date: string;

    @Input() datePickerStart: string;
    @Input() datePickerSlut: string;

    @Output() modelDateChange = new EventEmitter();
    @Input() feltId: string;
    @Input() index: number;
    @Input() cursorStart: string;
    @Input() paakraevet: boolean;
    @Input() ingenStartdato: boolean;

    @Input() customErrorText: string = "";

    @Input() shouldShowCustomErrorText: boolean = false;

    @Input()
    get modelDate() {
        return this.date;
    }

    set modelDate(input: string) {
        this.date = input;
        this.modelDateChange.emit(this.date);
    }

    ngOnInit() {
        this.feltNavn = this.index != null ? this.feltId + "_" + this.index : this.feltId;
    }

    ngAfterContentChecked() {
        const elements = jQuery('#' + this.feltNavn);
        const element = elements ? elements[0] : null;

        if (element && element.value && element.value !== this.date) {
            // Hack: Vent et tick for at undgå devMode fejlen:
            // Expression has changed after it was checked.
            setTimeout((_: any) => this.modelDate = element.value);
        }
    }

    ngAfterViewInit() {
        const element = jQuery('#' + this.feltNavn);
        if (!this.datePickerIsInit && element) {
            if (!this.cursorStart) {
                this.cursorStart = todayAsPrettyText();
            }
            datePicker.create(element, this.datePickerStart, this.datePickerSlut, this.cursorStart);
            this.datePickerIsInit = true;
        }
    }

    ngOnDestroy() {
        datePicker.destroy(this.feltNavn);
        this.datePickerIsInit = false;
    }
}
