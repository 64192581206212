<div class="clearfix">
    <h1 class="pull-left">{{'TILKENDEGIVELSER.TIDLIGERE.OVERSKRIFT' | translate | async }}</h1>

    <p class="pull-right no-print">
        <a id="tilkendegivelser-print"
           href="javascript:void(0);"
           target=_blank
           (click)="printPage()">
            <span class="skts-icon skts-icon--print skts-icon--align-left"></span>{{ 'TILKENDEGIVELSER.TIDLIGERE.UDSKRIV_KNAP' | translate | async }}
        </a>
    </p>
</div>

<div id="tilkendegivelse-description-wrapper" class="clearfix">
    <div id="tilkendegivelse-description" class="pull-left">{{ 'TILKENDEGIVELSER.TIDLIGERE.FORKLARING' | translate | async }}</div>

    <div class="pull-right no-print">
        <a id="goToTilkendegiv" (click)="goToTilkendegiv()">
            {{ 'TILKENDEGIVELSER.TIDLIGERE.NY_TILKENDEGIVELSE' | translate | async }}
        </a>
    </div>
</div>

<skat-fejl-liste *ngIf="errorService.derErFejl()" [titel]="fejlOverskrift"></skat-fejl-liste>


<div class="tilkendegivelse-wrapper" id="tilkendegivelse-wrapper-{{ tilkendegivelse.id }}" *ngFor="let tilkendegivelse of activeTilkendegivelser">
    <h2 class=omsaetningPeriodeHeader>
        {{ 'TILKENDEGIVELSER.TIDLIGERE.OMSAETNINGPERIODE.HEADER' | translate | async }}
        {{ tilkendegivelse.omsaetningPeriodeStartDk() }} - <span id="omsaetningPeriodeSlut-{{ tilkendegivelse.id }}">{{ tilkendegivelse.omsaetningPeriodeSlutDk() }}</span>
    </h2>
    <hr class="regnskabsperiode-hr">
    <div class="well oversigtsgruppe">
        <div class="detaljer">
            <skat-tilkendegivelse-view [model]="tilkendegivelse"></skat-tilkendegivelse-view>
        </div>

        <hr class="only-print" *ngIf="hasCorrections(tilkendegivelse)">

        <div *ngIf="hasCorrections(tilkendegivelse)" class="corrected-tilkendegivelser-wrapper">

            <h2 class="skts-row-seperator skts-collapse skts-collapse-arrow dropdown">
                <a aria-expanded=false class="dropdown-link">{{ 'TILKENDEGIVELSER.TIDLIGERE.SE_RETTEDE.LABEL' | translate | async }}</a>
            </h2>
            <div class="skts-toggle-hidden hidden transparent">
                <div *ngFor="let correction of getCorrections(tilkendegivelse)"
                     id="corrected-tilkendegivelse-{{correction.id}}"
                     class="skts-row-seperator corrected-tilkendegivelse">
                    <div class="detaljer">
                        <skat-tilkendegivelse-view
                            [model]="correction"
                            [correctLinkEnabled]="false"
                            [pdfLinkEnabled]="false">
                        </skat-tilkendegivelse-view>
                    </div>

                    <hr class="only-print" *ngIf="correction != getLastCorrectedTilkendegivelse(tilkendegivelse)">

                </div>
            </div>
        </div>
    </div>

</div>

<div class="ingen-tilkendegivelser" *ngIf="noTilkendegivelser()">
    <p><em>{{'TILKENDEGIVELSER.TIDLIGERE.INGEN_TILKENDEGIVELSER' | translate | async }}</em></p>
</div>
