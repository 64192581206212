import { RouterModule } from "@angular/router";
import { OversigtComponent } from './components/oversigt/oversigt.component';

export const indberetningerRoutes = RouterModule.forChild([

    {
        path: 'tidligere-indberetninger',
        component: OversigtComponent
    }
]);
