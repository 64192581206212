import { AmountModel } from "./amount-model";
import { CompanyModel } from "./company.model";
import { RevenueModel } from "./revenue.model";

export class CountryModel {
    countryCode: string;
    countryName: string;
    noOfEmployees: string;
    profitOrLoss: AmountModel;
    taxPaid: AmountModel;
    taxAccrued: AmountModel;
    capital: AmountModel;
    earnings: AmountModel;
    assets: AmountModel;
    revenue: RevenueModel;
    companies: Array<CompanyModel>;
}
