<div>
    <div class="clearfix">
        <h1 id="indberetninger-overskrift" class="pull-left">{{'INDBERETNINGER.OVERSKRIFT' | translate | async }}</h1>
        <p class="pull-right no-print">
            <a id="indberetninger-print" href="#" class="js-print"><span
                class="skts-icon skts-icon--print skts-icon--align-left"></span>{{ 'INDBERETNINGER.UDSKRIV_KNAP' | translate | async }}</a>
        </p>
    </div>

    <div>{{ 'INDBERETNINGER.BROEDTEKST' | translate | async }}</div>

    <skat-fejl-liste *ngIf="errors.derErFejl()" [titel]="errorsOverskrift"></skat-fejl-liste>

    <div id="indberetningsliste" *ngIf="reportingPeriods.length > 0">
        <div class="reportingPeriodList" *ngFor="let reportingPeriod of reportingPeriods">
            <h2 class=reportingPeriodHeader>{{ 'INDBERETNINGER.PERIODE_SLUT.LABEL' | translate | async }} {{reportingPeriod}}</h2>
            <hr class="regnskabsperiode-hr">
            <skat-samlet-indberetning-view [model]="consolidatedIndberetninger.get(reportingPeriod)"></skat-samlet-indberetning-view>
            <div *ngFor="let indberetning of indberetninger.get(reportingPeriod)">
                <skat-indberetning-view [model]="indberetning"
                                        [enableCollapseOnStatus]="true"
                                        [showDetails]="showDetailsView"
                                        [showFejlKode]="true"
                                        [showPrintReportLink]="false"></skat-indberetning-view>
            </div>
        </div>
    </div>


    <div id="ingen-indberetninger" *ngIf="indberetninger.size === 0">
        <p><em>{{'OVERSIGT.INGEN_INDBERETNINGER' | translate | async }}</em></p>
    </div>
</div>
