import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { getLanguageCode, textKeysVisible } from "../../utils/session-storage-utils";
import { isDefined } from "../../utils/text-utils";
import { DK_LANGUAGE_CODE, LanguageFileService } from "../language-file/language-file.service";

@Injectable()
export class TranslationService {

    constructor(private languageFileService: LanguageFileService) {
    }

    environmentAwareTranslate(query: string, interpolationParams: any = null): Observable<string> {
        let languageCode = getLanguageCode();
        if (languageCode == undefined || languageCode === "undefined") {
            languageCode = DK_LANGUAGE_CODE;
        }

        const languageFile = this.languageFileService.getLanguageFile(languageCode);
        if (isDefined(languageFile)) {
            const text = languageFile[query];
            if (!isDefined(text)) {
                return of(query);
            }
            return of(this.prepareText(query, text, interpolationParams));

        }
        else {
            return of(undefined);
        }
    }

    prepareText(query: string, text: string, interpolationParams: any): string {
        const parsedText = this.interpolateText(text, interpolationParams);
        return this.prefixWithKey(query, parsedText, textKeysVisible());
    }

    interpolateText(text: string, interpolationParams: any): string {
        let result = text;
        if (isDefined(interpolationParams)) {
            for (const paramKey of Object.keys(interpolationParams)) {
                const paramValue = interpolationParams[paramKey];
                const textKey = "{{" + paramKey + "}}";
                while (result.indexOf(textKey) !== -1) {
                    result = result.replace("{{" + paramKey + "}}", paramValue);
                }
            }
        }
        return result;
    }

    prefixWithKey(key: string, text: string, shouldShowTextKeys: boolean) {
        return (shouldShowTextKeys ? "[" + key + "] " : "") + text;
    }
}
