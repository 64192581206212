import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { uploadType } from "../../../shared/enums/uploadtype.enum";
import { Fejl } from "../../../shared/models/fejl.model";
import { Valideringsfejl } from '../../../shared/models/valideringsfejl.model';
import { ErrorService } from "../../../shared/services/error/error.service";
import { TranslationService } from "../../../shared/services/translate/translation.service";
import { standardOverskrift } from "../../../shared/utils/fejl-utils";
import { goToLogout, goToPortal } from "../../../shared/utils/url-utils";
import { FiloverfoerselModel } from "../../models/filoverfoersel.model";
import { FiluploadService } from "../../services/filupload/filupload.service";

@Component({
    templateUrl: './filoverfoersel-indberet.component.html',
    providers: [FiluploadService],
    styleUrls: ['./filoverfoersel-indberet.component.css']
})

export class FiloverfoerselIndberetComponent implements OnInit, OnDestroy, AfterViewInit {

    model: FiloverfoerselModel;
    errorsOverskrift: string;
    spinnerTekst: string = "";
    focusOnButton: boolean = false;
    focusOnNewButton: boolean = false;

    isValidated: boolean = false;
    validationStatus: boolean = false;
    validationErrors: Valideringsfejl;
    removeFile: boolean = true;

    constructor(private router: Router,
                private filoverfoerselModel: FiloverfoerselModel,
                private service: FiluploadService,
                public errors: ErrorService,
                private translationService: TranslationService,
                private titleService: Title) {
        this.model = filoverfoerselModel;
        this.errorsOverskrift = standardOverskrift();
        if (this.filoverfoerselModel.valideringsFejl !== null) {
            this.validationErrors = this.filoverfoerselModel.valideringsFejl;
            this.isValidated = true;
            this.errorsOverskrift = this.filoverfoerselModel.errorsOverskrift;
        }
    }

    ngOnInit() {
        this.subscribePaaSuccess();
        this.subscribePaaFejl();
        this.subscribePaaValideringsFejl();
        this.translationService.environmentAwareTranslate('FILOVERFOERSEL.INDBERET.SPINNER').subscribe((res: string) => {
            this.spinnerTekst = res;
        });
    }

    ngAfterViewInit() {
        this.translationService.environmentAwareTranslate('FILOVERFOERSEL.INDBERET.OVERSKRIFT').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
    }

    valider() {
        this.errors.reset();
        page.showLoader(this.spinnerTekst);
        this.service.uploadFil(uploadType.valider, '/indberet'); // validation upload, but indberet flow
    }

    onFilValgt(event: any) {
        this.onFilFjernet();
        this.model.fil = event.target.files[0];
        this.model.filnavn = this.model.fil.name;
    }

    onFilFjernet() {
        this.model.fil = null;
        this.validationStatus = false;
        this.isValidated = false;
        this.validationErrors = null;
    }

    subscribePaaSuccess() {
        this.service.uploadCompleted.subscribe(
            (data: FiloverfoerselModel) => {
                page.hideLoader();
                this.validationStatus = true;
                this.isValidated = true;
                this.validationErrors = new Valideringsfejl([], []);
                if (data.status === 'GODKENDT') {
                    this.removeFile = false;
                    if (data.valideringsFejl === null || data.valideringsFejl.advisFejl.length === 0) {
                        this.router.navigate(['/indberet/kontroller']);
                    } else {
                        this.validationErrors = data.valideringsFejl;
                    }
                }
            }
        );
    }

    goToPortal() {
        goToPortal(this.router);
    }

    goToLogout() {
        goToLogout(this.router);
    }

    goToNext() {
        this.router.navigate(['/indberet/kontroller']);
    }

    subscribePaaFejl() {
        this.service.uploadCompletedWithError.subscribe(
            (fejl: Fejl[]) => {
                page.hideLoader();
                this.filoverfoerselModel.fil = null;
                if (fejl && fejl.length > 0) {
                    this.errors.tilfoejFejl(fejl);
                }
                this.errorsOverskrift = standardOverskrift();
            }
        );
    }

    subscribePaaValideringsFejl() {

        this.service.validationErrorEvent.subscribe(
            (data: Valideringsfejl) => {
                page.hideLoader();
                this.validationStatus = false;
                this.isValidated = true;
                this.validationErrors = data;
            }
        );
    }

    ngOnDestroy() {
        this.service.validationErrorEvent.unsubscribe();
        this.service.uploadCompleted.unsubscribe();
        this.service.uploadCompletedWithError.unsubscribe();
        this.errors.reset();
        if (this.removeFile) {
            this.onFilFjernet();
        }

    }
}
