<div id="valideringsfejl">

    <div class="row">
        <div class="col-md-7">
            <h2 id="valideringsfejl-overskrift" *ngIf="heading != undefined">{{ heading }}</h2>
        </div>
        <div class="col-md-5">
            <p *ngIf="hasContentErrors || hasXMLErrors" class="text-right no-print">
                <a href="javascript:void(0)" (click)="udskrivFejlliste()" id="udskriv-fejlliste"><span
                    class="skts-icon skts-icon--print skts-icon--align-left"></span>{{'VALIDERINGSFEJL.UDSKRIV_FEJLLISTE.LABEL' | translate | async}}</a>
            </p>
        </div>
    </div>

    <div id="validering-xmlfejl" class="well validering-xmlfejl" [ngClass]="{'printAll': printFejl && hasXMLErrors, 'no-print': printFejl && !hasXMLErrors}">
        <div class="skts-header-section"  [ngClass]="{'skts-collapse skts-collapse-arrow': hasXMLErrors, 'ok': !hasXMLErrors}">
            <a aria-expanded="false">
                <div class="row">
                    <div class="col-sm-6 col-md-5"><div class="bold"><span [ngClass]="{'skts-icon--fejl': hasXMLErrors, 'skts-icon--ok': !hasXMLErrors }" class="skts-icon no-print skts-icon--align-left"></span>{{'VALIDERINGSFEJL.XMLFEJL.OVERSKRIFT' | translate | async}}</div></div>
                    <div class="col-sm-6 col-md-7 number-of-errors" *ngIf="hasXMLErrors">{{'VALIDERINGSFEJL.ANTAL_FEJL.LABEL' | translate | async}} {{validationErrors.xmlFejl.length}}</div>
                </div>
            </a>
        </div>
        <div class="hidden skts-toggle-hidden errorlist">
            <div class="row skts-row-seperator" *ngFor="let error of validationErrors.xmlFejl">
                <div class="col-sm-7 col-md-8 error-description">{{error.fejlBeskrivelse}}</div>
                <div class="col-sm-3 col-md-2 error-position">
                    {{'VALIDERINGSFEJL.LINJE_KOLONNE.LABEL' | translate | async}}<br/>
                    <span>{{error.linje}} / {{error.kolonne}}</span>
                </div>
                <div class="col-sm-2 col-md-2 error-code">{{ 'FILOVERFOERSEL.VALIDER.TABELHEADER.FEJLKODE' | translate | async }}<br />{{error.fejlKode}}</div>
            </div>
        </div>
    </div>

    <div id="validering-indholdsfejl" class="well validering-indholdsfejl" [ngClass]="{'printAll': printFejl && enableContentErrors, 'no-print': printFejl && !enableContentErrors}">
        <div class="skts-header-section" [ngClass]="{'skts-collapse skts-collapse-arrow': enableContentErrors, 'disabled': hasXMLErrors, 'ok': !hasContentErrors && !hasXMLErrors}">
            <a aria-expanded="false">
                <div class="row">
                    <div class="col-sm-6 col-md-5"><div class="bold"><span [ngClass]="{'skts-icon--fejl': hasContentErrors, 'skts-icon--ok': !hasContentErrors, 'hideIcon': hasXMLErrors }" class="no-print skts-icon skts-icon--align-left"></span>{{'VALIDERINGSFEJL.INDHOLDSFEJL.OVERSKRIFT' | translate | async}}</div></div>
                    <div class="col-sm-6 col-md-7 number-of-errors" *ngIf="!hasXMLErrors && hasContentErrors && hasAdvisErrors && validationErrors.advisFejl.length == 1">{{'VALIDERINGSFEJL.ANTAL_ADVIS_FEJL_EN.LABEL' | translate:{antalFejl: validationErrors.indsholdsFejl.length, antalAdvis: validationErrors.advisFejl.length} | async}}</div>
                    <div class="col-sm-6 col-md-7 number-of-errors" *ngIf="!hasXMLErrors && hasContentErrors && hasAdvisErrors && validationErrors.advisFejl.length > 1">{{'VALIDERINGSFEJL.ANTAL_ADVIS_FEJL_MANGE.LABEL' | translate:{antalFejl: validationErrors.indsholdsFejl.length, antalAdvis: validationErrors.advisFejl.length} | async}}</div>
                    <div class="col-sm-6 col-md-7 number-of-errors" *ngIf="!hasXMLErrors && !hasContentErrors && hasAdvisErrors && validationErrors.advisFejl.length == 1">{{'VALIDERINGSFEJL.ANTAL_ADVIS_EN.LABEL' | translate:{antalAdvis: validationErrors.advisFejl.length}  | async}}</div>
                    <div class="col-sm-6 col-md-7 number-of-errors" *ngIf="!hasXMLErrors && !hasContentErrors && hasAdvisErrors && validationErrors.advisFejl.length > 1">{{'VALIDERINGSFEJL.ANTAL_ADVIS_MANGE.LABEL' | translate:{antalAdvis: validationErrors.advisFejl.length}  | async}}</div>
                    <div class="col-sm-6 col-md-7 number-of-errors" *ngIf="!hasXMLErrors && hasContentErrors && !hasAdvisErrors">{{'VALIDERINGSFEJL.ANTAL_FEJL.LABEL' | translate | async}} {{validationErrors.indsholdsFejl.length}}</div>
                    <div class="col-sm-6 col-md-7 number-of-errors" *ngIf="hasXMLErrors">---</div>
                </div>
            </a>
        </div>
        <div class="hidden skts-toggle-hidden errorlist">
            <div class="row skts-row-seperator" *ngFor="let error of validationErrors.advisFejl">
                <div class="col-sm-7 col-md-6 error-description">Bemærkning<br />{{error.fejlBeskrivelse}}</div>
                <div class="col-sm-3 col-md-4 error-position"><span *ngIf="error.docRefId != null">{{'VALIDERINGSFEJL.DOCREFID.LABEL' | translate | async}}<br/>{{error.docRefId}}</span></div>
                <div class="col-sm-2 col-md-2 error-code">{{ 'FILOVERFOERSEL.VALIDER.TABELHEADER.FEJLKODE' | translate | async }}<br />{{error.fejlKode}}</div>
            </div>
            <div class="row skts-row-seperator" *ngFor="let error of validationErrors.indsholdsFejl">
                <div class="col-sm-7 col-md-6 error-description">{{error.fejlBeskrivelse}}</div>
                <div class="col-sm-3 col-md-4 error-position"><span *ngIf="error.docRefId != null">{{'VALIDERINGSFEJL.DOCREFID.LABEL' | translate | async}}<br/>{{error.docRefId}}</span></div>
                <div class="col-sm-2 col-md-2 error-code">{{ 'FILOVERFOERSEL.VALIDER.TABELHEADER.FEJLKODE' | translate | async }}<br />{{error.fejlKode}}</div>
            </div>
        </div>
    </div>

    <p id="validering-flerefejl" *ngIf="hasXMLErrors || hasContentErrors">{{'VALIDERINGSFEJL.FLERE_FEJL.LABEL' | translate | async}}</p>
</div>
