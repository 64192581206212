<div>
    <input type="text"
           class="form-control js-date-picker-with-range {{paakraevet ? 'skts-required-val' : ''}}"
           id="{{ feltNavn }}"
           name="{{ feltNavn }}"
           data-show-type="dkDate"
           data-validation-event="blur"
           placeholder="{{ 'DATOVAELGER.PLACEHOLDER' | translate | async }}"
           autocomplete="off"
           maxlength="10"
           aria-required="true"
           aria-invalid="true"
           [attr.data-show-facets]="ingenStartdato ? 'maxDate' : 'minDate!!maxDate'"
           [(ngModel)]="modelDate"
           [attr.data-show-facet-values]="ingenStartdato ? datePickerSlut : datePickerStart + '!!' + datePickerSlut">

    <div class="skts-error-text datovaelger-format-fejlbesked" role="alert">{{ 'DATOVAELGER.FORMAT' | translate | async }}</div>
    <div *ngIf="!ingenStartdato" class="skts-error-text datovaelger-foerdato-fejlbesked" role="alert">{{ 'DATOVAELGER.FOER' | translate | async }} {{ datePickerStart }}</div>
    <div class="skts-error-text datovaelger-efterdato-fejlbesked" role="alert">{{ 'DATOVAELGER.EFTER' | translate | async }} {{ datePickerSlut }}</div>
    <div *ngIf="paakraevet" class="skts-error-text datovaelger-paakraevet-fejlbesked" role="alert">{{ 'DATOVAELGER.PAAKRAEVET' | translate | async }}</div>
    <div *ngIf="shouldShowCustomErrorText"
         [class.show]="shouldShowCustomErrorText"
         class="custom-skts-error-text datovaelger-custom-fejlbesked" >{{ customErrorText | translate | async }}
         <a routerLink="/tidligere-tilkendegivelser">{{'TILKENDEGIVELSER.TILKENDEGIV.RAPPORTERINGSPERIODESLUT.INVALID_LINK_TEKST' | translate | async }}</a>
    </div>
</div>
