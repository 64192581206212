import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ValidationResultModel } from "../../models/rapportering-periode-slut-validation-result.model";
import { dkDateToIso } from "../../utils/date-utils";
import { rapporteringPeriodeSlutValidationUrl } from "../../utils/url-utils";
import { HttpService } from "../http/http.service";

@Injectable()
export class RapporteringPeriodeSlutValidationService {

    validationResult: Subject<boolean> = new Subject<boolean>();
    validationError: Subject<number> = new Subject<number>();

    constructor(private http: HttpService) {
    }

    validate(rapporteringPeriodeSlut: string) {
        const isoDate = dkDateToIso(rapporteringPeriodeSlut);
        this.http.get<ValidationResultModel>(rapporteringPeriodeSlutValidationUrl(), {rapporteringPeriodeSlut: isoDate})
            .subscribe((response: ValidationResultModel) => {
                    this.validationResult.next(response.data.validationResult);
                }, (error: HttpErrorResponse) => {
                    this.validationError.next(error.status);
                }
            );
    }
}
