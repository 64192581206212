import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SeNumberValidationService } from "../shared/services/senumber/se-number-validation.service";
import { SharedModule } from '../shared/shared.module';
import { TilkendegivelserKvitteringComponent } from "./components/tilkendegivelser-kvittering/tilkendegivelser-kvittering.component";
import { TilkendegivelserOversigtComponent } from "./components/tilkendegivelser-oversigt/tilkendegivelser-oversigt.component";
import { TilkendegivelserTilkendegivComponent } from "./components/tilkendegivelser-tilkendegiv/tilkendegivelser-tilkendegiv.component";
import { TilkendegivelseKvitteringModel } from "./models/tilkendegivelse-kvittering.model";
import { TilkendegivelseModel } from "./models/tilkendegivelse.model";
import { TilkendegivelserService } from "./services/tilkendegivelser.service";
import { tilkendegivelserRoutes } from './tilkendegivelser.routing';

@NgModule({
    imports: [
        CommonModule,
        tilkendegivelserRoutes,
        SharedModule,
    ],
    declarations: [
        TilkendegivelserTilkendegivComponent,
        TilkendegivelserKvitteringComponent,
        TilkendegivelserOversigtComponent,
    ],
    exports: [
    ],
    providers: [
        TilkendegivelseModel,
        TilkendegivelseKvitteringModel,
        TilkendegivelserService,
        SeNumberValidationService
    ]
})

export class TilkendegivelserModule {
}
