import { Component, Input, OnInit } from '@angular/core';
import { Fejl } from "../../../models/fejl.model";
import { Valideringsfejl } from '../../../models/valideringsfejl.model';

@Component({
    selector: "skat-valideringsfejl-view",
    templateUrl: "./valideringsfejl.component.html",
    providers: [],
    styleUrls: ['./valideringsfejl.component.css']
})

export class ValideringsfejlComponent implements OnInit{

    @Input() validationErrors: Valideringsfejl;
    @Input() heading: string;

    hasXMLErrors: boolean = false;
    hasContentErrors: boolean = false;
    hasAdvisErrors: boolean = false;
    enableContentErrors: boolean = false;

    printFejl:boolean = false;
    contentErrors: Fejl[];

    ngOnInit(){
        if(this.validationErrors.xmlFejl.length !== 0){
            this.hasXMLErrors = true;
        }
        if(this.validationErrors.indsholdsFejl.length !== 0){
            this.hasContentErrors = true;
            this.enableContentErrors = true;
        }
        if(this.validationErrors.advisFejl.length !== 0){
            this.hasAdvisErrors = true;
            this.enableContentErrors = true;
        }

        if(this.hasXMLErrors){
            this.enableContentErrors = false;
        }

        this.contentErrors = this.validationErrors.indsholdsFejl.concat(this.validationErrors.advisFejl);
    }

    udskrivFejlliste(){
        this.printFejl = true;

        setTimeout(function (){
            window.print();
            this.printFejl = false;
            }, 300);
    }
}
