export const countries = [
    { "value": "AD", "key": "ANDORRA", "aftaleLand": true },
    { "value": "AE", "key": "UNITED ARAB EMIRATES", "aftaleLand": false },
    { "value": "AF", "key": "AFGHANISTAN", "aftaleLand": false },
    { "value": "AG", "key": "ANTIGUA AND BARBUDA", "aftaleLand": false },
    { "value": "AI", "key": "ANGUILLA", "aftaleLand": true },
    { "value": "AL", "key": "ALBANIA", "aftaleLand": false },
    { "value": "AM", "key": "ARMENIA", "aftaleLand": false },
    { "value": "AO", "key": "ANGOLA", "aftaleLand": false },
    { "value": "AQ", "key": "ANTARCTICA", "aftaleLand": false },
    { "value": "AR", "key": "ARGENTINA", "aftaleLand": true },
    { "value": "AS", "key": "AMERICAN SAMOA", "aftaleLand": false },
    { "value": "AT", "key": "AUSTRIA", "aftaleLand": true },
    { "value": "AU", "key": "AUSTRALIA", "aftaleLand": false },
    { "value": "AW", "key": "ARUBA", "aftaleLand": false },
    { "value": "AX", "key": "ALAND ISLANDS", "aftaleLand": false },
    { "value": "AZ", "key": "AZERBAIJAN", "aftaleLand": false },
    { "value": "BA", "key": "BOSNIA AND HERZEGOVINA", "aftaleLand": false },
    { "value": "BB", "key": "BARBADOS", "aftaleLand": true },
    { "value": "BD", "key": "BANGLADESH", "aftaleLand": false },
    { "value": "BE", "key": "BELGIUM", "aftaleLand": true },
    { "value": "BF", "key": "BURKINA FASO", "aftaleLand": false },
    { "value": "BG", "key": "BULGARIA", "aftaleLand": true },
    { "value": "BH", "key": "BAHRAIN", "aftaleLand": false },
    { "value": "BI", "key": "BURUNDI", "aftaleLand": false },
    { "value": "BJ", "key": "BENIN", "aftaleLand": false },
    { "value": "BL", "key": "SAINT BARTHELEMY", "aftaleLand": true },
    { "value": "BM", "key": "BERMUDA", "aftaleLand": true },
    { "value": "BN", "key": "BRUNEI DARUSSALAM", "aftaleLand": false },
    { "value": "BO", "key": "BOLIVIA, PLURINATIONAL STATE OF", "aftaleLand": false },
    { "value": "BQ", "key": "BONAIRE, SINT EUSTATIUS AND SABA", "aftaleLand": true },
    { "value": "BR", "key": "BRAZIL", "aftaleLand": false },
    { "value": "BS", "key": "BAHAMAS", "aftaleLand": false },
    { "value": "BT", "key": "BHUTAN", "aftaleLand": false },
    { "value": "BV", "key": "BOUVET ISLAND", "aftaleLand": false },
    { "value": "BW", "key": "BOTSWANA", "aftaleLand": false },
    { "value": "BY", "key": "BELARUS", "aftaleLand": false },
    { "value": "BZ", "key": "BELIZE", "aftaleLand": false },
    { "value": "CA", "key": "CANADA", "aftaleLand": false },
    { "value": "CC", "key": "COCOS (KEELING) ISLANDS", "aftaleLand": false },
    { "value": "CD", "key": "CONGO, THE DEMOCRATIC REPUBLIC OF THE", "aftaleLand": false },
    { "value": "CF", "key": "CENTRAL AFRICAN REPUBLIC", "aftaleLand": false },
    { "value": "CG", "key": "CONGO", "aftaleLand": false },
    { "value": "CH", "key": "SWITZERLAND", "aftaleLand": true },
    { "value": "CI", "key": "COTE D'IVOIRE", "aftaleLand": false },
    { "value": "CK", "key": "COOK ISLANDS", "aftaleLand": false },
    { "value": "CL", "key": "CHILE", "aftaleLand": false },
    { "value": "CM", "key": "CAMEROON", "aftaleLand": false },
    { "value": "CN", "key": "CHINA", "aftaleLand": false },
    { "value": "CO", "key": "COLOMBIA", "aftaleLand": true },
    { "value": "CR", "key": "COSTA RICA", "aftaleLand": false },
    { "value": "CU", "key": "CUBA", "aftaleLand": false },
    { "value": "CV", "key": "CAPE VERDE", "aftaleLand": false },
    { "value": "CW", "key": "CURACAO", "aftaleLand": true },
    { "value": "CX", "key": "CHRISTMAS ISLAND", "aftaleLand": false },
    { "value": "CY", "key": "CYPRUS", "aftaleLand": true },
    { "value": "CZ", "key": "CZECH REPUBLIC", "aftaleLand": true },
    { "value": "DE", "key": "GERMANY", "aftaleLand": true },
    { "value": "DJ", "key": "DJIBOUTI", "aftaleLand": false },
    { "value": "DK", "key": "DENMARK", "aftaleLand": false },
    { "value": "DM", "key": "DOMINICA", "aftaleLand": false },
    { "value": "DO", "key": "DOMINICAN REPUBLIC", "aftaleLand": false },
    { "value": "DZ", "key": "ALGERIA", "aftaleLand": false },
    { "value": "EC", "key": "ECUADOR", "aftaleLand": false },
    { "value": "EE", "key": "ESTONIA", "aftaleLand": true },
    { "value": "EG", "key": "EGYPT", "aftaleLand": false },
    { "value": "EH", "key": "WESTERN SAHARA", "aftaleLand": false },
    { "value": "ER", "key": "ERITREA", "aftaleLand": true },
    { "value": "ES", "key": "SPAIN", "aftaleLand": true },
    { "value": "ET", "key": "ETHIOPIA", "aftaleLand": false },
    { "value": "FI", "key": "FINLAND", "aftaleLand": true },
    { "value": "FJ", "key": "FIJI", "aftaleLand": false },
    { "value": "FK", "key": "FALKLAND ISLANDS (MALVINAS)", "aftaleLand": false },
    { "value": "FM", "key": "MICRONESIA, FEDERATED STATES OF", "aftaleLand": false },
    { "value": "FO", "key": "FAROE ISLANDS", "aftaleLand": true },
    { "value": "FR", "key": "FRANCE", "aftaleLand": true },
    { "value": "GA", "key": "GABON", "aftaleLand": false },
    { "value": "GB", "key": "UNITED KINGDOM", "aftaleLand": true },
    { "value": "GD", "key": "GRENADA", "aftaleLand": false },
    { "value": "GE", "key": "GEORGIA", "aftaleLand": false },
    { "value": "GF", "key": "FRENCH GUIANA", "aftaleLand": false },
    { "value": "GG", "key": "GUERNSEY", "aftaleLand": true },
    { "value": "GH", "key": "GHANA", "aftaleLand": false },
    { "value": "GI", "key": "GIBRALTAR", "aftaleLand": true },
    { "value": "GL", "key": "GREENLAND", "aftaleLand": true },
    { "value": "GM", "key": "GAMBIA", "aftaleLand": false },
    { "value": "GN", "key": "GUINEA", "aftaleLand": false },
    { "value": "GP", "key": "GUADELOUPE", "aftaleLand": false },
    { "value": "GQ", "key": "EQUATORIAL GUINEA", "aftaleLand": false },
    { "value": "GR", "key": "GREECE", "aftaleLand": true },
    { "value": "GS", "key": "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS", "aftaleLand": false },
    { "value": "GT", "key": "GUATEMALA", "aftaleLand": false },
    { "value": "GU", "key": "GUAM", "aftaleLand": false },
    { "value": "GW", "key": "GUINEA-BISSAU", "aftaleLand": false },
    { "value": "GY", "key": "GUYANA", "aftaleLand": false },
    { "value": "HK", "key": "HONG KONG", "aftaleLand": false },
    { "value": "HM", "key": "HEARD ISLAND AND MCDONALD ISLANDS", "aftaleLand": false },
    { "value": "HN", "key": "HONDURAS", "aftaleLand": false },
    { "value": "HR", "key": "CROATIA", "aftaleLand": true },
    { "value": "HT", "key": "HAITI", "aftaleLand": false },
    { "value": "HU", "key": "HUNGARY", "aftaleLand": true },
    { "value": "ID", "key": "INDONESIA", "aftaleLand": false },
    { "value": "IE", "key": "IRELAND", "aftaleLand": true },
    { "value": "IL", "key": "ISRAEL", "aftaleLand": false },
    { "value": "IM", "key": "ISLE OF MAN", "aftaleLand": true },
    { "value": "IN", "key": "INDIA", "aftaleLand": true },
    { "value": "IO", "key": "BRITISH INDIAN OCEAN TERRITORY", "aftaleLand": false },
    { "value": "IQ", "key": "IRAQ", "aftaleLand": false },
    { "value": "IR", "key": "IRAN, ISLAMIC REPUBLIC OF", "aftaleLand": false },
    { "value": "IS", "key": "ICELAND", "aftaleLand": true },
    { "value": "IT", "key": "ITALY", "aftaleLand": true },
    { "value": "JE", "key": "JERSEY", "aftaleLand": true },
    { "value": "JM", "key": "JAMAICA", "aftaleLand": false },
    { "value": "JO", "key": "JORDAN", "aftaleLand": false },
    { "value": "JP", "key": "JAPAN", "aftaleLand": false },
    { "value": "KE", "key": "KENYA", "aftaleLand": false },
    { "value": "KG", "key": "KYRGYZSTAN", "aftaleLand": false },
    { "value": "KH", "key": "CAMBODIA", "aftaleLand": false },
    { "value": "KI", "key": "KIRIBATI", "aftaleLand": false },
    { "value": "KM", "key": "COMOROS", "aftaleLand": false },
    { "value": "KN", "key": "SAINT KITTS AND NEVIS", "aftaleLand": false },
    { "value": "KP", "key": "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF", "aftaleLand": false },
    { "value": "KR", "key": "KOREA, REPUBLIC OF", "aftaleLand": true },
    { "value": "KW", "key": "KUWAIT", "aftaleLand": false },
    { "value": "KY", "key": "CAYMAN ISLANDS", "aftaleLand": true },
    { "value": "KZ", "key": "KAZAKHSTAN", "aftaleLand": false },
    { "value": "LA", "key": "LAO PEOPLE'S DEMOCRATIC REPUBLIC", "aftaleLand": false },
    { "value": "LB", "key": "LEBANON", "aftaleLand": false },
    { "value": "LC", "key": "SAINT LUCIA", "aftaleLand": false },
    { "value": "LI", "key": "LIECHTENSTEIN", "aftaleLand": true },
    { "value": "LK", "key": "SRI LANKA", "aftaleLand": false },
    { "value": "LR", "key": "LIBERIA", "aftaleLand": false },
    { "value": "LS", "key": "LESOTHO", "aftaleLand": false },
    { "value": "LT", "key": "LITHUANIA", "aftaleLand": true },
    { "value": "LU", "key": "LUXEMBOURG", "aftaleLand": true },
    { "value": "LV", "key": "LATVIA", "aftaleLand": true },
    { "value": "LY", "key": "LIBYA", "aftaleLand": false },
    { "value": "MA", "key": "MOROCCO", "aftaleLand": false },
    { "value": "MC", "key": "MONACO", "aftaleLand": true },
    { "value": "MD", "key": "MOLDOVA, REPUBLIC OF", "aftaleLand": false },
    { "value": "ME", "key": "MONTENEGRO", "aftaleLand": false },
    { "value": "MF", "key": "SAINT MARTIN (FRENCH PART)", "aftaleLand": false },
    { "value": "MG", "key": "MADAGASCAR", "aftaleLand": false },
    { "value": "MH", "key": "MARSHALL ISLANDS", "aftaleLand": false },
    { "value": "MK", "key": "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF", "aftaleLand": false },
    { "value": "ML", "key": "MALI", "aftaleLand": false },
    { "value": "MM", "key": "MYANMAR", "aftaleLand": false },
    { "value": "MN", "key": "MONGOLIA", "aftaleLand": false },
    { "value": "MO", "key": "MACAO", "aftaleLand": false },
    { "value": "MP", "key": "NORTHERN MARIANA ISLANDS", "aftaleLand": false },
    { "value": "MQ", "key": "MARTINIQUE", "aftaleLand": false },
    { "value": "MR", "key": "MAURITANIA", "aftaleLand": false },
    { "value": "MS", "key": "MONTSERRAT", "aftaleLand": true },
    { "value": "MT", "key": "MALTA", "aftaleLand": true },
    { "value": "MU", "key": "MAURITIUS", "aftaleLand": false },
    { "value": "MV", "key": "MALDIVES", "aftaleLand": false },
    { "value": "MW", "key": "MALAWI", "aftaleLand": false },
    { "value": "MX", "key": "MEXICO", "aftaleLand": true },
    { "value": "MY", "key": "MALAYSIA", "aftaleLand": false },
    { "value": "MZ", "key": "MOZAMBIQUE", "aftaleLand": false },
    { "value": "NA", "key": "NAMIBIA", "aftaleLand": false },
    { "value": "NC", "key": "NEW CALEDONIA", "aftaleLand": false },
    { "value": "NE", "key": "NIGER", "aftaleLand": false },
    { "value": "NF", "key": "NORFOLK ISLAND", "aftaleLand": false },
    { "value": "NG", "key": "NIGERIA", "aftaleLand": false },
    { "value": "NI", "key": "NICARAGUA", "aftaleLand": false },
    { "value": "NL", "key": "NETHERLANDS", "aftaleLand": true },
    { "value": "NO", "key": "NORWAY", "aftaleLand": true },
    { "value": "NP", "key": "NEPAL", "aftaleLand": false },
    { "value": "NR", "key": "NAURU", "aftaleLand": false },
    { "value": "NU", "key": "NIUE", "aftaleLand": true },
    { "value": "NZ", "key": "NEW ZEALAND", "aftaleLand": false },
    { "value": "OM", "key": "OMAN", "aftaleLand": false },
    { "value": "PA", "key": "PANAMA", "aftaleLand": false },
    { "value": "PE", "key": "PERU", "aftaleLand": false },
    { "value": "PF", "key": "FRENCH POLYNESIA", "aftaleLand": false },
    { "value": "PG", "key": "PAPUA NEW GUINEA", "aftaleLand": false },
    { "value": "PH", "key": "PHILIPPINES", "aftaleLand": false },
    { "value": "PK", "key": "PAKISTAN", "aftaleLand": false },
    { "value": "PL", "key": "POLAND", "aftaleLand": true },
    { "value": "PM", "key": "SAINT PIERRE AND MIQUELON", "aftaleLand": false },
    { "value": "PN", "key": "PITCAIRN", "aftaleLand": false },
    { "value": "PR", "key": "PUERTO RICO", "aftaleLand": false },
    { "value": "PS", "key": "PALESTINE, STATE OF", "aftaleLand": false },
    { "value": "PT", "key": "PORTUGAL", "aftaleLand": true },
    { "value": "PW", "key": "PALAU", "aftaleLand": false },
    { "value": "PY", "key": "PARAGUAY", "aftaleLand": false },
    { "value": "QA", "key": "QATAR", "aftaleLand": false },
    { "value": "RE", "key": "REUNION", "aftaleLand": false },
    { "value": "RO", "key": "ROMANIA", "aftaleLand": true },
    { "value": "RS", "key": "SERBIA", "aftaleLand": false },
    { "value": "RU", "key": "RUSSIAN FEDERATION", "aftaleLand": false },
    { "value": "RW", "key": "RWANDA", "aftaleLand": false },
    { "value": "SA", "key": "SAUDI ARABIA", "aftaleLand": false },
    { "value": "SB", "key": "SOLOMON ISLANDS", "aftaleLand": false },
    { "value": "SC", "key": "SEYCHELLES", "aftaleLand": true },
    { "value": "SD", "key": "SUDAN", "aftaleLand": false },
    { "value": "SE", "key": "SWEDEN", "aftaleLand": true },
    { "value": "SG", "key": "SINGAPORE", "aftaleLand": false },
    { "value": "SH", "key": "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA", "aftaleLand": false },
    { "value": "SI", "key": "SLOVENIA", "aftaleLand": true },
    { "value": "SJ", "key": "SVALBARD AND JAN MAYEN", "aftaleLand": false },
    { "value": "SK", "key": "SLOVAKIA", "aftaleLand": true },
    { "value": "SL", "key": "SIERRA LEONE", "aftaleLand": false },
    { "value": "SM", "key": "SAN MARINO", "aftaleLand": true },
    { "value": "SN", "key": "SENEGAL", "aftaleLand": false },
    { "value": "SO", "key": "SOMALIA", "aftaleLand": false },
    { "value": "SR", "key": "SURINAME", "aftaleLand": false },
    { "value": "SS", "key": "SOUTH SUDAN", "aftaleLand": false },
    { "value": "ST", "key": "SAO TOME AND PRINCIPE", "aftaleLand": false },
    { "value": "SV", "key": "EL SALVADOR", "aftaleLand": false },
    { "value": "SX", "key": "SINT MAARTEN (DUTCH PART)", "aftaleLand": false },
    { "value": "SY", "key": "SYRIAN ARAB REPUBLIC", "aftaleLand": false },
    { "value": "SZ", "key": "SWAZILAND", "aftaleLand": false },
    { "value": "TC", "key": "TURKS AND CAICOS ISLANDS", "aftaleLand": true },
    { "value": "TD", "key": "CHAD", "aftaleLand": false },
    { "value": "TF", "key": "FRENCH SOUTHERN TERRITORIES", "aftaleLand": false },
    { "value": "TG", "key": "TOGO", "aftaleLand": false },
    { "value": "TH", "key": "THAILAND", "aftaleLand": false },
    { "value": "TJ", "key": "TAJIKISTAN", "aftaleLand": false },
    { "value": "TK", "key": "TOKELAU", "aftaleLand": false },
    { "value": "TL", "key": "TIMOR-LESTE", "aftaleLand": false },
    { "value": "TM", "key": "TURKMENISTAN", "aftaleLand": false },
    { "value": "TN", "key": "TUNISIA", "aftaleLand": false },
    { "value": "TO", "key": "TONGA", "aftaleLand": false },
    { "value": "TR", "key": "TURKEY", "aftaleLand": false },
    { "value": "TT", "key": "TRINIDAD AND TOBAGO", "aftaleLand": false },
    { "value": "TV", "key": "TUVALU", "aftaleLand": false },
    { "value": "TW", "key": "TAIWAN, PROVINCE OF CHINA", "aftaleLand": false },
    { "value": "TZ", "key": "TANZANIA, UNITED REPUBLIC OF", "aftaleLand": false },
    { "value": "UA", "key": "UKRAINE", "aftaleLand": false },
    { "value": "UG", "key": "UGANDA", "aftaleLand": false },
    { "value": "UM", "key": "UNITED STATES MINOR OUTLYING ISLANDS", "aftaleLand": true },
    { "value": "US", "key": "UNITED STATES", "aftaleLand": false },
    { "value": "UY", "key": "URUGUAY", "aftaleLand": false },
    { "value": "UZ", "key": "UZBEKISTAN", "aftaleLand": false },
    { "value": "VA", "key": "HOLY SEE (VATICAN CITY STATE)", "aftaleLand": false },
    { "value": "VC", "key": "SAINT VINCENT AND THE GRENADINES", "aftaleLand": false },
    { "value": "VE", "key": "VENEZUELA, BOLIVARIAN REPUBLIC OF", "aftaleLand": false },
    { "value": "VG", "key": "VIRGIN ISLANDS, BRITISH", "aftaleLand": true },
    { "value": "VI", "key": "VIRGIN ISLANDS, U.S.", "aftaleLand": false },
    { "value": "VN", "key": "VIET NAM", "aftaleLand": false },
    { "value": "VU", "key": "VANUATU", "aftaleLand": false },
    { "value": "WF", "key": "WALLIS AND FUTUNA", "aftaleLand": false },
    { "value": "WS", "key": "SAMOA", "aftaleLand": false },
    { "value": "YE", "key": "YEMEN", "aftaleLand": false },
    { "value": "YT", "key": "MAYOTTE", "aftaleLand": false },
    { "value": "ZA", "key": "SOUTH AFRICA", "aftaleLand": true },
    { "value": "ZM", "key": "ZAMBIA", "aftaleLand": false },
    { "value": "ZW", "key": "ZIMBABWE", "aftaleLand": false },
    { "value": "XK", "key": "KOSOVO", "aftaleLand": false },
    { "value": "X5", "key": "STATSLØSE", "aftaleLand": false },
];

// Same as countries but sorted by country name (key)
export const sortedCountries = countries.map(row => {
    return {value: row.value, key: row.key, aftaleLand: row.aftaleLand };
}).sort((a, b) => {
    if (a.key > b.key) {
        return 1;
    }
    else if (a.key < b.key) {
        return -1;
    }
    else {
        return 0;
    }
});
