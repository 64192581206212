import { Injectable } from "@angular/core";
import { formatSimpleDate, formatZuluDateTime, getPrettyCountryNameByCode } from "../../shared/utils/text-utils";
import { ModerselskabInfoModel } from "./moderselskab-info.model";

@Injectable()
export class TilkendegivelseKvitteringModel {

    id: number;
    indberetter: string;
    pligtig: string;
    omfattetAfCbc: boolean;
    omsaetningPeriodeStart: string;
    omsaetningPeriodeSlut: string;
    oprettelsesTid: string;

    moderselskabInfo: ModerselskabInfoModel;

    correctedBy: number;

    constructor() {
    }

    fullAddress(): string {
        if (this.moderselskabInfo == undefined) {
            return "";
        }
        return this.moderselskabInfo.adresse + ", " + this.moderselskabInfo.postNummer + ", " + this.moderselskabInfo.by;
    }

    omsaetningPeriodeStartDk(): string {
        return formatSimpleDate(this.omsaetningPeriodeStart);
    }

    omsaetningPeriodeSlutDk(): string {
        return formatSimpleDate(this.omsaetningPeriodeSlut);
    }

    rapporteringPeriodeStartDk(): string {
        if (this.moderselskabInfo == undefined) {
            return "";
        }
        return formatSimpleDate(this.moderselskabInfo.rapporteringPeriodeStart);
    }

    rapporteringPeriodeSlutDk(): string {
        if (this.moderselskabInfo == undefined) {
            return "";
        }
        return formatSimpleDate(this.moderselskabInfo.rapporteringPeriodeSlut);
    }

    oprettelsesTidDk(): string {
        return formatZuluDateTime(this.oprettelsesTid);
    }

    jurisdictionName(): string {
        if (this.moderselskabInfo == undefined) {
            return "";
        }
        return getPrettyCountryNameByCode(this.moderselskabInfo.skatteJurisdiktion);
    }

    init(): void {
        Object.keys(this).forEach(key => {
            this[key] = null;
        });
    }

    assign(value: TilkendegivelseKvitteringModel): void {
        Object.keys(value).forEach(key => {
            this[key] = value[key];
        });
    }

    isEmpty(): boolean {
        return (this.id == undefined || this.id == null);
    }
}
