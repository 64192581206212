import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { ErrorStylingService } from "../../../services/error-styling/error-styling.service";
import { SeNumberValidationService } from "../../../services/senumber/se-number-validation.service";

@Component({
    selector: "skat-se-number-input-felt",
    templateUrl: './se-number-input-felt.component.html',
    providers: [SeNumberValidationService, ErrorStylingService]
})
export class SeNumberInputFeltComponent implements AfterViewInit, OnDestroy {

    @Input() textLabel: string;
    @Input() textPlaceHolder: string;
    @Input() textRequired: string;
    @Input() textWrongFormat: string;
    @Input() textMinLength: string;
    @Input() textMaxLength: string = "";
    @Input() textEsValidationFailed: string;

    @Input() validationEnabled: boolean = true;

    @Input() fieldName: string;
    @Input() fieldId: string;

    @Input() helpTextId: string;

    maxLength(): number {
        return this.validationEnabled ? 8 : 255;
    }

    minLength(): number {
        return this.validationEnabled ? 8 : 0;
    }

    facetValues(): string {
        return this.minLength() + "!!" + this.maxLength();
    }

    facets(): string {
        return "minLength!!maxLength";
    }

    bootskatType(): string {
        return this.validationEnabled ? 'integer' : 'string';
    }

    privateSeNumber: string;

    @Input()
    get seNumber() {
        return this.privateSeNumber;
    }

    set seNumber(seNumber: string) {
        this.seNumberChange.emit(seNumber);
        this.privateSeNumber = seNumber;
    }

    @Output() seNumberChange = new EventEmitter();

    privateSeNumberIsValid: boolean = false;

    @Input()
    get seNumberIsValid(): boolean {
        return !this.validationEnabled || this.privateSeNumberIsValid;
    }

    @Output() seNumberIsValidChange = new EventEmitter();

    shouldShowEsErrorText: boolean = false;

    set seNumberIsValid(seNumberIsValid: boolean) {
        this.seNumberIsValidChange.emit(seNumberIsValid);
        this.privateSeNumberIsValid = seNumberIsValid;
    }

    constructor(private seNumberValidationService: SeNumberValidationService,
                private errorStylingService: ErrorStylingService,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        this.seNumberValidationService.seNumberValidation.subscribe(
            (validationResult: boolean) => {
                this.seNumberIsValid = validationResult;
                this.shouldShowEsErrorText = !validationResult;

                if (this.shouldShowEsErrorText) {
                    this.addErrorClass();
                } else {
                    this.removeErrorClass();
                }
            }
        );
        this.seNumberValidationService.seNumberValidationError.subscribe(
            (status: number) => {
                this.seNumberIsValid = false;
                if (status === 400) {
                    this.shouldShowEsErrorText = true;
                } else {
                    this.shouldShowEsErrorText = false;
                }
            }
        );
        if (this.seNumber) {
            this.refreshValidation();
        }
    }

    ngOnDestroy(): void {
        this.seNumberValidationService.seNumberValidationError.unsubscribe();
        this.seNumberValidationService.seNumberValidation.unsubscribe();
    }

    onSeNumberChanged(newSeNumber: string) {
        if (newSeNumber !== this.seNumber) {
            this.validateSeNumber(newSeNumber);
        }
    }

    refreshValidation() {
        this.refreshBootskatValidation();
        this.validateSeNumber(this.seNumber);
    }

    private refreshBootskatValidation() {
        // If an seNumber is not set then don't bother validating
        // Prevents showing an error on seNumber when selecting a jurisdiction before typing in a seNumber
        if (!this.seNumber){
            return;
        }
        // Make wait for min/max length changes
        this.changeDetectorRef.detectChanges();
        // Dispatch a keyup event to trigger bootskat validation
        const keyupEvent = new KeyboardEvent('keyup',{'bubbles':true});
        document.getElementById(this.fieldId).dispatchEvent(keyupEvent);
    }

    private validateSeNumber(newSeNumber: string) {
        if (this.validationEnabled && newSeNumber != undefined && newSeNumber.length === this.minLength()) {
            this.seNumberValidationService.validateSeNumber(newSeNumber);
        } else {
            this.removeErrorClass();
            this.shouldShowEsErrorText = false;
        }
    }

    focus() {
        this.addErrorClass();
        document.getElementById(this.fieldId).focus();
    }

    private removeErrorClass() {
        this.errorStylingService.removeErrorStyling(document.getElementById(this.fieldId));
    }

    private addErrorClass() {
        this.errorStylingService.addErrorStyling(document.getElementById(this.fieldId));
    }
}
