import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ReportComponent } from '../../../shared/components/partials/report/report.component';
import { ErrorService } from "../../../shared/services/error/error.service";
import { TranslationService } from "../../../shared/services/translate/translation.service";
import { FiloverfoerselModel } from "../../models/filoverfoersel.model";
import { FiluploadService } from '../../services/filupload/filupload.service';

@Component({
    templateUrl: './filoverfoersel-modtaget.component.html',
    providers: [
        FiluploadService
    ],
    styleUrls: ['./filoverfoersel-modtaget.component.css']
})

export class FiloverfoerselModtagetComponent implements OnInit, AfterViewInit {

    @ViewChild(ReportComponent) reportComponent: ReportComponent;
    timestamp: string;
    messageRefId: string;
    currency: string;
    reportingPeriod: string;

    readonly showDetailsView: boolean = true;

    constructor(private router: Router,
                public model: FiloverfoerselModel,
                public errorService: ErrorService,
                private translationService: TranslationService,
                private titleService: Title) {
        this.errorService.reset();
    }

    async ngOnInit() {
        if (this.model.oprettelsesid === null) {
            this.router.navigate(['/indberet']);
        }
    }

    ngAfterViewInit(){
        this.translationService.environmentAwareTranslate('FILOVERFOERSEL.INDBERET.OVERSKRIFT').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
    }

    goToReports(){
        this.router.navigate(['/tidligere-indberetninger']);
    }

    ngOnDestroy() {
        this.model.init();
    }
}
