import { RouterModule } from "@angular/router";
import { FiloverfoerselIndberetComponent } from "./components/filoverfoersel-indberet/filoverfoersel-indberet.component";
import { FiloverfoerselKontrollerComponent } from "./components/filoverfoersel-kontroller/filoverfoersel-kontroller.component";
import { FiloverfoerselModtagetComponent } from "./components/filoverfoersel-modtaget/filoverfoersel-modtaget.component";
import { FiloverfoerselValiderComponent } from "./components/filoverfoersel-valider/filoverfoersel-valider.component";

export const filuploadRoutes = RouterModule.forChild([

    {
        path: 'indberet',
        component: FiloverfoerselIndberetComponent
    },
    {
        path: 'indberet/kontroller',
        component: FiloverfoerselKontrollerComponent
    },
    {
        path: 'indberet/modtaget',
        component: FiloverfoerselModtagetComponent
    },
    {
        path: 'valider',
        component: FiloverfoerselValiderComponent
    },
]);
