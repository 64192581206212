<form role="form" (ngSubmit)="valider()">
    <div class="clearfix">
        <h1 id="filoverfoersel_overskrift" class="pull-left">{{ 'FILOVERFOERSEL.VALIDER.OVERSKRIFT' | translate | async }}</h1>
    </div>
    <div class="clearfix">
        <p>{{'FILOVERFOERSEL.VALIDER.OVERSKRIFT.BESKRIVELSE' | translate | async}}</p>
    </div>
    <skat-fejl-liste *ngIf="errors.derErFejl()" [titel]="errorsOverskrift"></skat-fejl-liste>

    <div class="well">
        <div class="row skts-process-form-section skts-row-seperator skts-required">
            <div class="col-sm-4 col-md-4">
                <label for="fileUpload">{{ 'FILOVERFOERSEL.VALIDER.LABEL' | translate | async }}</label>
                <skat-hjaelpetekst guid="8561"></skat-hjaelpetekst>
            </div>
            <div class="col-sm-8 col-md-8 text-right" *ngIf="!model.fil">
                <span id="skts-btn-file" class="btn btn-default skts-btn-file" [ngClass]="{ 'skts-btn-file-focus': focusOnButton}">
                    {{ 'FILOVERFOERSEL.VALIDER.VAELG_FIL_KNAP' | translate | async }}
                  <input type="file" title="" (change)="onFilValgt($event)" name="fileUpload" id="fileUpload" (focus)="focusOnButton = true;" (blur)="focusOnButton = false;"/>
                </span>
            </div>
            <div class="col-sm-7 col-md-7 text-right" *ngIf="model.fil">
                <span id="filename">{{model.fil.name}}</span>
            </div>
            <div class="col-sm-1 col-md-1 text-right" *ngIf="model.fil">
                <a id="removeFileButton" (click)="onFilFjernet()" title="{{'FILOVERFOERSEL.FJERN_FIL_MOUSEOVER.LABEL' | translate | async}}" (keyup.enter)="onFilFjernet()"
                   aria-label="Fjern fil" tabindex="0">
                    <span aria-hidden="true" class="skts-icon skts-icon--small skts-rounded-icon skts-icon--slet-hvid"></span>
                </a>
            </div>
        </div>
    </div>
    <input type="hidden" name="reportType" value="file"/>


    <div *ngIf="isValidated">
        <skat-valideringsfejl-view [validationErrors]="validationErrors"></skat-valideringsfejl-view>
    </div>

    <p *ngIf="validationStatus && isValidated" id="validering-success">{{'FILOVERFOERSEL.VALIDER.SUCCESS.LABEL' | translate:{filnavn: model?.fil?.name} | async}}</p>

    <div class="clearfix skts-wizard-buttons no-print">
        <input *ngIf="!isValidated" type="submit" class="pull-right btn btn-default js-form-validate" id="knap-Send" value="{{ 'FILOVERFOERSEL.VALIDER.SEND_KNAP' | translate | async }}" [disabled]="model.fil == null && !isValidated"/>

        <input type="button" class="pull-left btn btn-default skts-btn-secondary js-form-validate" data-toggle="modal" data-target="#validerAfslutModal" data-backdrop="static" id="knap-afslut" value="{{ 'FILOVERFOERSEL.VALIDER.AFSLUT' | translate | async }}" *ngIf="isValidated" />

        <span id="skts-btn-new-file" class="btn btn-default skts-btn-file" [ngClass]="{ 'skts-btn-new-file-focus': focusOnNewButton, 'skts-btn-secondary pull-left': validationStatus && isValidated, 'pull-right': !validationStatus && isValidated }" *ngIf="isValidated" >
            {{ 'FILOVERFOERSEL.VALIDER.VAELG_NY_FIL_KNAP' | translate | async }}
          <input type="file" (change)="onFilValgt($event)" name="fileUpload" id="fileUploadNewFile" (focus)="focusOnNewButton = true;" (blur)="focusOnNewButton = false;" title="{{'FILOVERFOERSEL.VALIDER.VAELG_NY_FIL_MOUSEOVER.LABEL' | translate | async}}"/>
        </span>

        <button type="button" class="btn btn-default pull-right" (click)="onJaTilFilIndberet()" id="knap-gaaTilIndberetning" *ngIf="validationStatus && isValidated">{{'FILOVERFOERSEL.VALIDER.GAA_TIL_INDBERETNING.LABEL' | translate | async }}</button>
    </div>

    <!-- Lightbox for afslutning af validering -->
    <div class="modal fade" tabindex="-1" role="dialog" id="validerAfslutModal" style="display: none">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button id="luk-valider-afslut-modal" type="button" class="close" data-dismiss="modal" aria-label="Luk"><span aria-hidden="true"></span></button>
                </div>
                <div class="modal-body js-modal-body text-center">
                    {{ 'FILOVERFOERSEL.VALIDER.LOGAF_MODAL.LEDETEKST' | translate | async }}
                </div>
                <div class="modal-footer">
                    <div class="clearfix skts-wizard-buttons">
                        <input id="accepter-logaf" class="btn btn-primary pull-right" value="{{ 'FILOVERFOERSEL.VALIDER.LOGAF_MODAL.ACCEPTER' | translate | async }}" type="button" (click)="goToLogout()" data-dismiss="modal">
                        <input id="afvis-logaf" class="btn pull-left skts-btn-secondary" value="{{ 'FILOVERFOERSEL.VALIDER.LOGAF_MODAL.AFVIS' | translate | async }}" type="button" (click)="goToPortal()" data-dismiss="modal">
                    </div>
                </div>
            </div>
        </div>
    </div>

</form>
