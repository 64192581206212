import { Fejl } from "../models/fejl.model";
import { Valideringsfejl } from "../models/valideringsfejl.model";

export function mapValidationErrorList(response: any): Valideringsfejl {
    const xmlFejl: Fejl[] = [];
    const indholdsFejl: Fejl[] = [];
    const advisFejl: Fejl[] = [];

    if (response.xmlFejl !== undefined) {
        for (const responseXmlFejl of response.xmlFejl) {
            const fejl = new Fejl(responseXmlFejl.fejlBeskrivelse, responseXmlFejl.fejlKode);
            if (responseXmlFejl.kolonneNummer !== undefined) {
                fejl.kolonne = responseXmlFejl.kolonneNummer;
            }
            if (responseXmlFejl.linjeNummer !== undefined) {
                fejl.linje = responseXmlFejl.linjeNummer;
            }
            xmlFejl.push(fejl);
        }
    }

    if (response.indholdsFejl !== undefined) {
        for (const responseIndholdsFejl of response.indholdsFejl) {
            const fejl = new Fejl(responseIndholdsFejl.fejlBeskrivelse, responseIndholdsFejl.fejlKode);
            if (responseIndholdsFejl.docRefId !== undefined) {
                fejl.docRefId = responseIndholdsFejl.docRefId;
            }
            indholdsFejl.push(fejl);
        }
    }

    if (response.advisFejl !== undefined) {
        for (const responseAdvisFejl of response.advisFejl) {
            const fejl = new Fejl(responseAdvisFejl.fejlBeskrivelse, responseAdvisFejl.fejlKode);
            advisFejl.push(fejl);

        }
    }

    return new Valideringsfejl(xmlFejl, indholdsFejl, advisFejl);
}
