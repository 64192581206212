<div class="row skts-required">
    <div class="col-md-8">
        <label [for]="fieldId">{{ textLabel | translate | async }}</label>
        <skat-hjaelpetekst *ngIf="helpTextId != undefined" guid="{{helpTextId}}"></skat-hjaelpetekst>
    </div>
    <div class="col-md-4">
        <input type="text"
               placeholder="{{ textPlaceHolder | translate | async }}"
               class="form-control skts-required-val"
               id="{{fieldId}}"
               name="{{fieldName}}"
               aria-invalid="true"
               aria-required="true"
               [attr.data-show-type]="bootskatType()"
               [attr.data-show-facets]="facets()"
               [attr.data-show-facet-values]="facetValues()"
               [maxlength]="maxLength()"
               (keydown.space)="$event.preventDefault()"
               (ngModelChange)="onSeNumberChanged($event)"
               [(ngModel)]="seNumber">
        <div id="{{fieldId}}-error-text-format" class="skts-error-text" role="alert">{{ textWrongFormat | translate | async }}</div>
        <div id="{{fieldId}}-error-text-minlength" class="skts-error-text" role="alert">{{ textMinLength | translate | async }}</div>
        <div class="skts-error-text" role="alert">{{ textMaxLength | translate | async }}</div>
        <div id="{{fieldId}}-error-text-required" class="skts-error-text" role="alert">{{ textRequired | translate | async }}</div>
        <div id="{{fieldId}}-error-text-validation" [class.show]="shouldShowEsErrorText" class="custom-skts-error-text" role="alert">
             {{ textEsValidationFailed | translate | async }}
        </div>
    </div>
</div>
