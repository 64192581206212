import { Component, Input, OnInit } from '@angular/core';
import { AdditionalInfoModel } from '../../../models/additional-info.model';
import { AmountModel } from "../../../models/amount-model";
import { ReportModel } from '../../../models/report.model';
import { TranslationService } from '../../../services/translate/translation.service';

@Component({
    selector: "skat-report-view",
    templateUrl: "./report.component.html",
    providers: [],
    styleUrls: ['./report.component.css']
})

export class ReportComponent implements OnInit{

    @Input() report: ReportModel;
    @Input() showIndividualCurrencies: boolean = false;
    public hidden: boolean = true;
    height:string;
    public print : boolean = false;
    summaryRef:string[] = [];
    summaryRefCodes:string[] = ['CBC601', 'CBC602', 'CBC603', 'CBC604', 'CBC605', 'CBC606', 'CBC607', 'CBC608', 'CBC609', 'CBC610', 'CBC611'];

    constructor(private translationService: TranslationService) { }

    ngOnInit(){
        this.getAllSummaryRefCodeTexts();
    }

    hasData(): boolean {
        return this.report && this.report.hasData();
    }

    getAllSummaryRefCodeTexts(){
        for (const summaryRefCode of this.summaryRefCodes) {
            this.translationService.environmentAwareTranslate('REPORT.ADDITIONAL_INFO.SUMMARYREF.' + summaryRefCode).subscribe((text: string) => {
                this.summaryRef[summaryRefCode] = text;
            });
        }
    }

    doesAdditionalInfoContainCountryCode(code: string):boolean {
        for (const additionalInfo of this.report.additionalInfos) {
            if (additionalInfo.resCountryCodes != undefined && additionalInfo.resCountryCodes.includes(code)) {
                return true;
            }
        }
        return false;
    }

    additionalInfoWithCountryCode(code: string): AdditionalInfoModel[] {
        const additionalInfos:AdditionalInfoModel[] = [];
        for (const additionalInfo of this.report.additionalInfos) {

            if (additionalInfo.resCountryCodes != undefined && additionalInfo.resCountryCodes.includes(code)) {
                additionalInfos.push(additionalInfo);
            }
        }
        return additionalInfos;
    }

    listSummaryRefCodes(list:string[]): string{
        const text:string[] = [];
        for (const element of list) {
            text.push('"'+this.summaryRef[element]+'"');
        }
        return text.join(', ');
    }

    additionalInfoNoCountries(additionalInfos: AdditionalInfoModel[]):boolean{
        if(additionalInfos != undefined){
            for (const additionalInfo of additionalInfos) {
                if(additionalInfo.resCountryCodes == undefined){
                    return true;
                }
            }
        }

        return false;
    }

    getAdditionalInfoNoCountries(additionalInfos: AdditionalInfoModel[]):AdditionalInfoModel[]{
        const resultList: AdditionalInfoModel[] = [];
        if(additionalInfos != undefined){
            for (const additionalInfo of additionalInfos) {
                if(additionalInfo.resCountryCodes == undefined){
                    resultList.push(additionalInfo);
                }
            }
        }

        return resultList;
    }

    getCurrencyCode(amount: AmountModel): string {
        return this.showIndividualCurrencies ? amount.currencyCode : this.report.currency;
    }

    updateTables(){
        setTimeout(function (){
            jQuery('.country-companies-container').each(function (){

                const countryOverview = jQuery(this).parents('.skts-hidden-form-section').find('.country-overview');

                if(jQuery(this).height() > countryOverview.height()){
                    jQuery(this).height(countryOverview.height());
                } else{
                    jQuery(this).height("");
                }
            });
        }, 400);
    }
}
