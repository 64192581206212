import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { seNumberValidationUrl } from "../../utils/url-utils";
import { HttpService } from "../http/http.service";
import { SeNumberValidationResult } from "./se-number-validation-result.model";

@Injectable()
export class SeNumberValidationService {

    seNumberValidation: Subject<boolean> = new Subject<boolean>();
    seNumberValidationError: Subject<number> = new Subject<number>();

    constructor(private http: HttpService) {
    }

    validateSeNumber(seNumber: string) {
        this.http.get<SeNumberValidationResult>(seNumberValidationUrl(), {seNumber: seNumber})
            .subscribe((response: SeNumberValidationResult) => {
                    this.seNumberValidation.next(response.data.validationResult);
                }, (error: HttpErrorResponse) => {
                    this.seNumberValidationError.next(error.status);
                }
            );
    }
}
