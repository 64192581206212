export class Fejl {
    fejlKode:string;
    fejlBeskrivelse:string;
    kolonne: string;
    linje: string;
    docRefId: string;
    tekniskFejl: boolean;

    constructor(besked: string, kode: string = null, tekniskFejl: boolean = false){
        this.fejlKode = kode;
        this.fejlBeskrivelse = besked;
        this.kolonne = null;
        this.linje = null;
        this.docRefId = null;
        this.tekniskFejl = tekniskFejl;
    }

    text(): string {
        return this.fejlKode ? this.fejlKode + ": " + this.fejlBeskrivelse : this.fejlBeskrivelse;
    }
}
