<div id="server-fejl-box" class="alert alert-danger" role="alert">
    <a id="skts-skip-link-target-fejl" class="sr-only" tabindex="-1">Start af fejl</a>
    <h2 *ngIf="!titel" [attr.id]="'fejl_liste_overskrift'">{{ 'GENERELT.FEJL.STANDARD_OVERSKRIFT' | translate | async }}</h2>
    <h2 *ngIf="titel" [attr.id]="'fejl_liste_overskrift'">{{ titel | translate | async }}</h2>

    <div class="fejl_liste_element" *ngFor="let f of fejl; let i = index" >
        <p *ngIf="f.fejlKode !== null" [attr.id]="'fejl_liste_' + i">FEJL {{f.fejlKode}}: {{f.fejlBeskrivelse | translate | async}}</p>
        <p *ngIf="f.fejlKode === null" [attr.id]="'fejl_liste_' + i">{{f.fejlBeskrivelse | translate | async}}</p>
    </div>
</div>
