import { Injectable } from "@angular/core";
import { genererUrlTilTekster } from "../../utils/url-utils";
import { HttpService } from "../http/http.service";

export const DK_LANGUAGE_CODE = "DA";
export const US_LANGUAGE_CODE = "US";

const languageCodes = [DK_LANGUAGE_CODE, US_LANGUAGE_CODE];

@Injectable()
export class LanguageFileService {

    private languageFiles: Map<string, Object>;

    constructor(private http: HttpService) {
        this.languageFiles = new Map<string, Object>();
    }

    getLanguageFile(languageCode: string): Object {
        return this.languageFiles.get(languageCode.toUpperCase());
    }

    // Sets up a Promise which will be used as an indicator by APP_INITIALIZER
    // for when all files have been loaded (or failed to) from the backend.
    // TODO: all attempts at testing this method in Jasmine have been unsuccessful so far
    load(): Promise<any> {
        const promises: Promise<any>[] = [];
        languageCodes.forEach(languageCode => {
            const promise: Promise<any> = this.http.getWithoutEmit<any>(genererUrlTilTekster(languageCode))
                .toPromise()
                .then((data: Object) => this.languageFiles.set(languageCode, data))
                .catch(error => this.languageFiles.set(languageCode, undefined));
                promises.push(promise);
        });
        return Promise.all(promises);
    }
}

// This is a factory function for APP_INITIALIZER setup in the
// App module setup. It's purpose is to load all language files before initializing
// components, etc.
// See http://www.learn-angular.fr/how-to-call-an-asynchronous-service-before-bootstrap/
export function languageFileServiceFactory(config: LanguageFileService) {
    return () => config.load();
}
