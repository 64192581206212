/**
 * Represents (the metadata of) a consolidated indberetning, that is without its business data, in the same way that
 * `FiloverfoerselModel` represents an indberetning without its business data.
 */
export class ConsolidatedIndberetningModel {
    /** Reporting period formatted as `dd-MM-yyyy`. */
    reportingPeriod: string;
    /** Date of the newest indberetning in the consolidation formatted as `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`. */
    date: string;
}
