import { RouterModule } from "@angular/router";
import { TilkendegivelserKvitteringComponent } from "./components/tilkendegivelser-kvittering/tilkendegivelser-kvittering.component";
import { TilkendegivelserOversigtComponent } from "./components/tilkendegivelser-oversigt/tilkendegivelser-oversigt.component";
import { TilkendegivelserTilkendegivComponent } from "./components/tilkendegivelser-tilkendegiv/tilkendegivelser-tilkendegiv.component";

export const tilkendegivelserRoutes = RouterModule.forChild([

    {
        path: 'tilkendegiv',
        component: TilkendegivelserTilkendegivComponent
    },
    {
        path: 'tilkendegiv/kvittering',
        component: TilkendegivelserKvitteringComponent
    },
    {
        path: 'tidligere-tilkendegivelser',
        component: TilkendegivelserOversigtComponent
    }
]);
