import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { uploadType } from "../../../shared/enums/uploadtype.enum";
import { Fejl } from "../../../shared/models/fejl.model";
import { Valideringsfejl } from '../../../shared/models/valideringsfejl.model';
import { ErrorService } from "../../../shared/services/error/error.service";
import { TranslationService } from "../../../shared/services/translate/translation.service";
import { standardOverskrift } from '../../../shared/utils/fejl-utils';
import { goToLogout, goToPortal } from '../../../shared/utils/url-utils';
import { FiloverfoerselModel } from "../../models/filoverfoersel.model";
import { FiluploadService } from "../../services/filupload/filupload.service";

@Component({
    templateUrl: './filoverfoersel-valider.component.html',
    providers: [FiluploadService],
    styleUrls: ['./filoverfoersel-valider.component.css']
})

export class FiloverfoerselValiderComponent implements OnInit, OnDestroy, AfterViewInit {

    model: FiloverfoerselModel;
    isValidated: boolean = false;
    validationStatus: boolean = false;
    validationErrors: Valideringsfejl;
    validationErrorsNumber: number;
    errorsOverskrift: string;
    spinnerTekst: string = "";
    focusOnButton: boolean = false;
    removeFile: boolean = true;
    focusOnNewButton: boolean = false;

    constructor(private router: Router,
                private filoverfoerselModel: FiloverfoerselModel,
                private service: FiluploadService,
                public errors: ErrorService,
                private translationService: TranslationService,
                private titleService: Title ) {
        this.model = filoverfoerselModel;
        this.errorsOverskrift = standardOverskrift();
    }

    ngOnInit() {
        this.errors.reset();
        this.validationStatus = null;
        this.subscribePaaSuccess();
        this.subscribePaaValideringsFejl();
        this.subscribePaaFejl();

        this.translationService.environmentAwareTranslate('FILOVERFOERSEL.VALIDER.SPINNER').subscribe((res: string) => {
            this.spinnerTekst = res;
        });

    }

    ngAfterViewInit(){
        this.translationService.environmentAwareTranslate('FILOVERFOERSEL.VALIDER.OVERSKRIFT').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
    }

    valider() {
        this.errors.reset();
        page.showLoader(this.spinnerTekst);
        this.service.uploadFil(uploadType.valider, '/valider');
    }

    onFilValgt(event: any) {
        this.onFilFjernet();
        this.model.fil = event.target.files[0];
        this.model.filnavn = this.model.fil.name;
    }

    onFilFjernet() {
        this.model.fil = null;
        this.validationStatus = false;
        this.isValidated = false;
        this.validationErrors = null;
    }

    resetValideringsForm() {
        this.onFilFjernet();
    }

    onJaTilFilIndberet() {
        this.removeFile = false;
        this.router.navigate(['/indberet/kontroller']);
    }

    subscribePaaSuccess() {
        this.service.uploadCompleted.subscribe(
            (data: FiloverfoerselModel) => {
                page.hideLoader();
                this.validationStatus = true;
                this.isValidated = true;
                if(data.valideringsFejl != undefined){
                    this.validationErrors = data.valideringsFejl;
                } else{
                    this.validationErrors = new Valideringsfejl([], []);
                }

            }
        );
    }

    goToPortal() {
        goToPortal(this.router);
    }

    goToLogout() {
        goToLogout(this.router);
    }

    subscribePaaValideringsFejl(){

        this.service.validationErrorEvent.subscribe(
            (data: Valideringsfejl) => {
                page.hideLoader();
                this.validationStatus = false;
                this.isValidated = true;
                this.validationErrors = data;
                this.validationErrorsNumber = data.indsholdsFejl.length + data.xmlFejl.length;
            }
        );
    }

    subscribePaaFejl() {
        this.service.uploadCompletedWithError.subscribe(
            (fejl: Fejl[]) => {
                page.hideLoader();

                this.isValidated = false;
                this.validationStatus = false;
                this.filoverfoerselModel.fil = null;
                if (fejl && fejl.length > 0) {
                    this.errors.tilfoejFejl(fejl);
                }
                this.errorsOverskrift = standardOverskrift();
            }
        );
    }

   ngOnDestroy(){
       this.service.validationErrorEvent.unsubscribe();
       this.service.uploadCompleted.unsubscribe();
       this.service.uploadCompletedWithError.unsubscribe();

       if(this.removeFile){
           this.resetValideringsForm();
       }
   }

}
