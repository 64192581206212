import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";
import { FiloverfoerselModel } from '../../../../filoverfoersel/models/filoverfoersel.model';
import { ReportModel } from '../../../models/report.model';
import { IndberetningResultModel } from "../../../services/indberetning/indberetning-result.model";
import { IndberetningService } from '../../../services/indberetning/indberetning.service';
import { formatSimpleDate, formatZuluDateTime } from '../../../utils/text-utils';
import { ReportComponent } from '../report/report.component';

@Component({
    selector: "skat-indberetning-view",
    templateUrl: "./indberetning.component.html",
    styleUrls: ['./indberetning.component.css']
})

export class IndberetningComponent implements OnInit, OnDestroy {

    @ViewChild(ReportComponent) reportComponent: ReportComponent;
    @Input() model: FiloverfoerselModel;
    @Input() showDownloadLink: boolean = true;
    @Input() showPrintReportLink: boolean = true;
    @Input() showDetails: boolean = false;
    @Input() enableCollapseOnStatus: boolean = true;
    @Input() showFejlKode: boolean = true;

    reports: Map<number, ReportModel> = new Map<number, ReportModel>();
    reportingPeriod: string;
    private subscriptionToIndberetningHentet: Subscription;

    constructor(private service: IndberetningService) {
    }

    ngOnInit() {
        this.model.date = formatZuluDateTime(this.model.date);
        this.reportingPeriod = formatSimpleDate(this.model.reportingPeriod);

        this.subscriptionToIndberetningHentet = this.service.indberetningHentet.subscribe((result: IndberetningResultModel) => {
            this.reports.set(result.id, result.model);
        });
    }

    ngOnDestroy() {
        this.subscriptionToIndberetningHentet.unsubscribe();
        this.reports = null;
    }

    printReport(id: number) {
        this.getXML(id);

        jQuery('.well.indberetning').addClass('noprint');
        jQuery('#indberetning' + id).removeClass('noprint');
        jQuery('#indberetning' + id + ' .hidden').addClass('printAll');

        this.reportComponent.print = true;
        const reportComponent = this.reportComponent;

        setTimeout(function () {
            window.print();
            reportComponent.print = false;
            jQuery('.well.indberetning').removeClass('noprint');
            jQuery('#indberetning' + id + ' .hidden').removeClass('printAll');

        }, 300);

    }

    async downloadResource() : Promise<void> {
        try {
            const blob = await this.service.hentIndberetningsBlob(this.model.oprettelsesid);
            this.downloadBlob(blob);
        }
        catch( err) {
          // catching here to prevent console log
        }
    }

    async getXML(id: number) {
        if (this.reports.get(id) == null) {
            this.service.hentIndberetningsXML(id);
        }
    }
    /**
     * Downloads a Blob in a Browser-aware way.
     * @param blob the Blob to download.
     */
    downloadBlob(blob: Blob): void {
        // Allow download in IE10+: has Blob, but not a[download] or URL
        if (navigator.msSaveBlob) {
            this.downloadFileIe(blob);
        } else {
            this.downloadFileNonIe(blob);
        }
    }

    /**
     * Download file the IE way. IE doesn't support the download attribute on `a` elements, so we need another method.
     * @param blob a Blob containing the file to download.
     */
    downloadFileIe(blob: Blob) {
        navigator.msSaveBlob(blob, this.model.filnavn);
    }

    /**
     * Download file in browsers that are not IE.
     * @param blob a Blob containing the file to download.æ
     */
    downloadFileNonIe(blob: Blob) {
        const link = document.createElement('a');
        link.setAttribute("visibility", "hidden");
        link.href = URL.createObjectURL(blob);

        link.download = this.model.filnavn;
        document.body.appendChild(link);
        link.click();
    }
}
