import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Fejl } from "../../shared/models/fejl.model";
import { HttpService } from "../../shared/services/http/http.service";
import { nyStandardFejl } from "../../shared/utils/fejl-utils";
import { genererUrlTilTilkendegivelse, genererUrlTilTilkendegivelser } from "../../shared/utils/url-utils";
import { ModerselskabInfoModel } from "../models/moderselskab-info.model";
import { TilkendegivelseKvitteringModel } from "../models/tilkendegivelse-kvittering.model";
import { TilkendegivelseModel } from "../models/tilkendegivelse.model";

@Injectable()
export class TilkendegivelserService {

    tilkendegivPostSuccess: Subject<TilkendegivelseKvitteringModel> = new Subject<TilkendegivelseKvitteringModel>();
    tilkendegivPostFejl: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();

    tilkendgivelserHentet: Subject<Array<TilkendegivelseKvitteringModel>> = new Subject<Array<TilkendegivelseKvitteringModel>>();
    tilkendgivelseHentet: Subject<TilkendegivelseModel> = new Subject<TilkendegivelseModel>();
    tilkendgivelserHentetFejl: Subject<Fejl> = new Subject<Fejl>();

    constructor(private http: HttpService) {
    }

    tilkendegivPost(tilkendegivelseModel: TilkendegivelseModel) {
        this.http.post<any>(genererUrlTilTilkendegivelser(), tilkendegivelseModel.toJsonString()).subscribe(response => {
            this.tilkendegivPostSuccess.next(this.buildTilkendegivelseKvitteringModel(response.data));
        }, (error: HttpErrorResponse) => {
            this.tilkendegivPostFejl.next(error);
        });
    }

    hentTilkendegivelser() {
        this.http.get<any>(genererUrlTilTilkendegivelser()).subscribe(response => {

            const models: TilkendegivelseKvitteringModel[] = [];

            if (response.data && response.data.length > 0) {
                for (const tilkendegivelse of response.data) {
                    models.push(this.buildTilkendegivelseKvitteringModel(tilkendegivelse));
                }
            }

            this.tilkendgivelserHentet.next(models);

        }, err => {
            this.tilkendgivelserHentetFejl.next(nyStandardFejl());
        });
    }

    hentTilkendegivelse(id: number) {
        this.http.get<any>(genererUrlTilTilkendegivelse(id)).subscribe(response => {
            if (response.data) {
                this.tilkendgivelseHentet.next(TilkendegivelseModel.fromJson(response.data.attributes));
            } else {
                this.tilkendgivelseHentet.error(nyStandardFejl());
            }
        }, err => {
            this.tilkendgivelseHentet.error(nyStandardFejl());
        });
    }

    private buildTilkendegivelseKvitteringModel(responseData: any): TilkendegivelseKvitteringModel {

        const model = new TilkendegivelseKvitteringModel();

        model.id = responseData.id;

        const attributes = responseData.attributes;
        model.indberetter = attributes.indberetter;
        model.pligtig = attributes.pligtig;
        model.omfattetAfCbc = attributes.omfattetAfCbc;
        model.omsaetningPeriodeStart = attributes.omsaetningPeriodeStart;
        model.omsaetningPeriodeSlut = attributes.omsaetningPeriodeSlut;
        model.oprettelsesTid = attributes.oprettelsesTid;
        model.correctedBy = attributes.correctedBy;

        const moderselskabInfo = attributes.moderselskabInfo;
        if (moderselskabInfo) {
            model.moderselskabInfo = new ModerselskabInfoModel();
            model.moderselskabInfo.rapporteringPeriodeStart = moderselskabInfo.rapporteringPeriodeStart;
            model.moderselskabInfo.rapporteringPeriodeSlut = moderselskabInfo.rapporteringPeriodeSlut;
            model.moderselskabInfo.skatteJurisdiktion = moderselskabInfo.skatteJurisdiktion;
            model.moderselskabInfo.tin = moderselskabInfo.tin;
            model.moderselskabInfo.navn = moderselskabInfo.navn;
            model.moderselskabInfo.adresse = moderselskabInfo.adresse;
            model.moderselskabInfo.postNummer = moderselskabInfo.postNummer;
            model.moderselskabInfo.by = moderselskabInfo.by;
        }

        return model;
    }
}
