import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ErrorService } from "../../../shared/services/error/error.service";
import { TranslationService } from "../../../shared/services/translate/translation.service";
import { nyStandardFejl, standardOverskrift } from "../../../shared/utils/fejl-utils";
import { TilkendegivelseKvitteringModel } from "../../models/tilkendegivelse-kvittering.model";
import { TilkendegivelserService } from "../../services/tilkendegivelser.service";

@Component({
    templateUrl: './tilkendegivelser-oversigt.component.html',
    styleUrls: ['./tilkendegivelser-oversigt.component.scss']
})

export class TilkendegivelserOversigtComponent implements OnInit, OnDestroy, AfterViewInit {
    fejlOverskrift: string;
    subscriptionToTilkendegivelser: Subscription;
    subscriptionToErrors: Subscription;

    // Tilkendegivelser that have not been corrected.
    activeTilkendegivelser: TilkendegivelseKvitteringModel[] = [];

    correctedTilkendegivelser: Map<number, TilkendegivelseKvitteringModel[]>;

    constructor(private translationService: TranslationService,
                private service: TilkendegivelserService,
                public errorService: ErrorService,
                private titleService: Title,
                private router: Router) {
    }

    ngOnInit(): void {
        this.errorService.reset();
        this.subscriptionToTilkendegivelser = this.service.tilkendgivelserHentet.subscribe((models: TilkendegivelseKvitteringModel[]) => {
            this.onTilkendgivelserHentet(models);
        });
        this.subscribeToIndberetningerHentetFejl();
        this.service.hentTilkendegivelser();

        this.translationService.environmentAwareTranslate('TILKENDEGIVELSER.TIDLIGERE.SPINNER').subscribe((res: string) => {
            page.showLoader(res);
        });
    }

    ngAfterViewInit() {
        this.translationService.environmentAwareTranslate('TILKENDEGIVELSER.TIDLIGERE.OVERSKRIFT').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
    }

    onTilkendgivelserHentet(allTilkendegivelser: TilkendegivelseKvitteringModel[]) {
        const tilkendegivelser: TilkendegivelseKvitteringModel[] = [];
        const correctedTilkendegivelser: Map<number, TilkendegivelseKvitteringModel[]> = new Map();

        allTilkendegivelser.forEach((tilkendegivelse: TilkendegivelseKvitteringModel) => {
            const correctedById = tilkendegivelse.correctedBy;
            if (correctedById) {
                const activeTilkendegivelseId = this.findActiveTilkendegivelseId(allTilkendegivelser, correctedById);

                let correctedList = correctedTilkendegivelser.get(activeTilkendegivelseId);
                if (!correctedList) {
                    correctedList = [];
                }
                correctedList.push(tilkendegivelse);
                correctedTilkendegivelser.set(correctedById, correctedList);
            } else {
                tilkendegivelser.push(tilkendegivelse);
            }
        });
        this.activeTilkendegivelser = tilkendegivelser;
        this.correctedTilkendegivelser = correctedTilkendegivelser;
        page.hideLoader();
    }

    private findActiveTilkendegivelseId(allTilkendegivelser: TilkendegivelseKvitteringModel[], correctedById: number): number {
        let currentSearchId: number = correctedById;
        let currentTilkendegivelse: TilkendegivelseKvitteringModel;
        do {
            currentTilkendegivelse = allTilkendegivelser.find(it => it.id === currentSearchId);
            currentSearchId = currentTilkendegivelse.correctedBy;
        } while (currentSearchId);
        return currentTilkendegivelse.id;
    }

    subscribeToIndberetningerHentetFejl() {
        this.subscriptionToErrors = this.service.tilkendgivelserHentetFejl.subscribe(fejl => {
            this.errorService.tilfoejFejl([nyStandardFejl()]);
            this.fejlOverskrift = standardOverskrift();
            page.hideLoader();
        });
    }

    ngOnDestroy() {
        if (this.subscriptionToTilkendegivelser) {
            this.subscriptionToTilkendegivelser.unsubscribe();
        }
        if (this.subscriptionToErrors) {
            this.subscriptionToErrors.unsubscribe();
        }
    }

    goToTilkendegiv() {
        this.router.navigate(['/tilkendegiv']);
    }

    printPage() {
        const collapsedElements: Array<any> = jQuery('a[aria-expanded="false"]');
        TilkendegivelserOversigtComponent.toggleCollapsed(collapsedElements);

        window.print();

        // Wait for the print dialog to render before collapsing the elements again
        setTimeout(function () {
            TilkendegivelserOversigtComponent.toggleCollapsed(collapsedElements);
        }, 100);
    }

    static toggleCollapsed(elements: Array<any>) {
        for (const element of elements) {
            element.click();
        }
    }

    getLastCorrectedTilkendegivelse (tilkendegivelse: TilkendegivelseKvitteringModel): TilkendegivelseKvitteringModel {
        const corrections = this.getCorrections(tilkendegivelse);
        return corrections[corrections.length - 1];
    }

    noTilkendegivelser(): boolean {
        return this.activeTilkendegivelser.length === 0;
    }

    getCorrections(tilkendegivelse: TilkendegivelseKvitteringModel): TilkendegivelseKvitteringModel[] {
        return this.correctedTilkendegivelser.get(tilkendegivelse.id);
    }

    hasCorrections(tilkendegivelse: TilkendegivelseKvitteringModel): boolean {
        return this.correctedTilkendegivelser.get(tilkendegivelse.id) != undefined;
    }
}
