import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ModalModule } from "ngx-bootstrap/modal";
import { ConsolidatedIndberetningComponent } from "./components/partials/consolidated-indberetning/consolidated-indberetning.component";
import { DatovaelgerInputFeltComponent } from "./components/partials/datovaelger-input-felt/datovaelger-input-felt.component";
import { FejlListeComponent } from "./components/partials/fejl-liste/fejl-liste.component";
import { HjaelpetekstComponent } from "./components/partials/hjaelpetekst/hjaelpetekst.component";
import { IndberetningComponent } from './components/partials/indberetning/indberetning.component';
import { RapporteringPeriodeSlutInputFeltComponent } from "./components/partials/rapportering-periode-input-felt/rapportering-periode-slut-input-felt.component";
import { ReportComponent } from './components/partials/report/report.component';
import { SeNumberInputFeltComponent } from "./components/partials/se-number-input-felt/se-number-input-felt.component";
import { TilkendegivelseViewComponent } from "./components/partials/tilkendegivelse/tilkendegivelse-view.component";
import { OnLinkOnEnterDirective } from "./directives/focus-on-link-on-enter.directive";
import { TranslationPipe } from "./pipes/translation.pipe";
import { HttpService } from "./services/http/http.service";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
        ModalModule.forRoot()
    ],
    declarations: [
        FejlListeComponent,
        TranslationPipe,
        OnLinkOnEnterDirective,
        HjaelpetekstComponent,
        ReportComponent,
        IndberetningComponent,
        ConsolidatedIndberetningComponent,
        DatovaelgerInputFeltComponent,
        TilkendegivelseViewComponent,
        SeNumberInputFeltComponent,
        RapporteringPeriodeSlutInputFeltComponent
    ],
    exports: [
        RouterModule,
        FormsModule,
        HttpClientModule,
        ModalModule,
        FejlListeComponent,
        TranslationPipe,
        OnLinkOnEnterDirective,
        HjaelpetekstComponent,
        ReportComponent,
        IndberetningComponent,
        ConsolidatedIndberetningComponent,
        DatovaelgerInputFeltComponent,
        TilkendegivelseViewComponent,
        SeNumberInputFeltComponent,
        RapporteringPeriodeSlutInputFeltComponent
    ],
    providers: [
        HttpService
    ]
 })
export class SharedModule {}
