import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { TilkendegivelseKvitteringModel } from "../../models/tilkendegivelse-kvittering.model";

@Component({
    templateUrl: './tilkendegivelser-kvittering.component.html',
    styleUrls: ['./tilkendegivelser-kvittering.component.css']
})

export class TilkendegivelserKvitteringComponent implements OnInit, OnDestroy {

    constructor(private router: Router,
                public model: TilkendegivelseKvitteringModel) {
    }

    ngOnInit() {
        if (this.model.isEmpty()) {
            this.router.navigate(['/tilkendegiv']);
        }
    }

    ngOnDestroy() {
        this.model.init();
    }

    goToTidligereTilkendegivelser() {
        this.router.navigate(['/tidligere-tilkendegivelser']);
    }
}
