import { AfterViewInit, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslationService } from "../shared/services/translate/translation.service";

@Component({
    selector: 'skat-forside',
    templateUrl: './forside.component.html',
})
export class ForsideComponent implements AfterViewInit{

    constructor(private translationService:TranslationService, private titleService: Title) {
       page.showLoader("Initialiserer Applikationen...");
        this.translationService.environmentAwareTranslate('FORSIDE.INDBERET.SPINNER').subscribe((res: string) => {
            page.hideLoader();
        });
    }

    ngAfterViewInit(){
        this.translationService.environmentAwareTranslate('FORSIDE.OVERSKRIFT').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
    }
}
