import { Injectable } from "@angular/core";
import { dkDateToIso, isoDateToDk } from "../../shared/utils/date-utils";
import { ModerselskabInfoModel } from "./moderselskab-info.model";

@Injectable()
export class TilkendegivelseModel {
    omsaetningPeriodeStart: string;
    omsaetningPeriodeSlut: string;
    omfattetAfCbc: boolean;
    /** Undefined &lt;=> no data. */
    moderselskabInfo: ModerselskabInfoModel;
    corrects: number;

    static fromJson(json: any): TilkendegivelseModel {
        const model = new TilkendegivelseModel();
        model.assignFrom(json);
        return model.cloneWithDkDates();
    }

    toJsonString(): string {
        return JSON.stringify(this.cloneWithIsoDates());
    }

    cloneWithIsoDates() {
        return this.cloneWithDateFormat(dkDateToIso);
    }

    cloneWithDkDates() {
        return this.cloneWithDateFormat(isoDateToDk);
    }

    private cloneWithDateFormat(conversionFunction: (s: string) => string) {
        const clone = this.clone();
        clone.omsaetningPeriodeStart = conversionFunction(clone.omsaetningPeriodeStart);
        clone.omsaetningPeriodeSlut = conversionFunction(clone.omsaetningPeriodeSlut);
        const moderselskabInfo = clone.moderselskabInfo;
        if (moderselskabInfo) {
            moderselskabInfo.rapporteringPeriodeStart = conversionFunction(moderselskabInfo.rapporteringPeriodeStart);
            moderselskabInfo.rapporteringPeriodeSlut = conversionFunction(moderselskabInfo.rapporteringPeriodeSlut);
        }
        return clone;
    }

    clone() {
        const clone = new TilkendegivelseModel();
        clone.assignFrom(this);
        return clone;
    }

    private assignFrom(model: TilkendegivelseModel): void {
        Object.keys(model).filter(key => key !== 'moderselskabInfo').forEach(key => {
            this[key] = model[key];
        });
        if (model.moderselskabInfo) {
            this.moderselskabInfo = new ModerselskabInfoModel();
            this.moderselskabInfo.assignFrom(model.moderselskabInfo);
        }
    }

    isIdenticalTo(model: TilkendegivelseModel): boolean {
        const compareModel = model ? model : new TilkendegivelseModel();
        return this.omsaetningPeriodeStart === compareModel.omsaetningPeriodeStart &&
            this.omsaetningPeriodeSlut === compareModel.omsaetningPeriodeSlut &&
            this.omfattetAfCbc === compareModel.omfattetAfCbc &&
            this.corrects === compareModel.corrects &&
            this.moderselskabInfoIsIdenticalTo(compareModel.moderselskabInfo);
    }

    private moderselskabInfoIsIdenticalTo(model: ModerselskabInfoModel): boolean {
        if (!this.moderselskabInfo) {
            return model ? model.isIdenticalTo(new ModerselskabInfoModel()) : true;
        }
        return this.moderselskabInfo.isIdenticalTo(model);
    }
}
