<div class="well indberetning" id="indberetning{{model.oprettelsesid}}">
    <div class="indberetning-wrap">
        <div class="information">

            <div class="row" *ngIf="model.indberetningsType === 'CORRECTION'">
                <div class="col-sm-4 col-md-4 col-xl-2">{{ 'INDBERETNING.TYPE.LABEL' | translate | async }}</div>
                <div id=indberetningsType class="col-sm-8 col-md-8 col-xl-10 indberetningsType">{{ 'INDBERETNING.TYPE.RETTELSE' | translate | async }}</div>
            </div>
            <div class="row" *ngIf="model.indberetningsType === 'SUPPLEMENT'">
                <div class="col-sm-4 col-md-4 col-xl-2">{{ 'INDBERETNING.TYPE.LABEL' | translate | async }}</div>
                <div id=indberetningsType class="col-sm-8 col-md-8 col-xl-10 indberetningsType">{{ 'INDBERETNING.TYPE.SUPPLERING' | translate | async }}</div>
            </div>

            <div class="row">
                <div class="col-sm-4 col-md-4 col-xl-2">{{ 'INDBERETNING.DATO.LABEL' | translate | async }}</div>
                <div class="col-sm-8 col-md-8 col-xl-10 date">{{ model.date }}</div>
            </div>

            <div class="row">
                <div class="col-sm-4 col-md-4 col-xl-2">{{ 'INDBERETNING.REGNSKABSPERIODE.LABEL' | translate | async }}</div>
                <div class="col-sm-8 col-md-8 col-xl-10 reportingperiod">{{reportingPeriod }}</div>
            </div>

            <div class="row">
                <div class="col-sm-4 col-md-4 col-xl-2">{{ 'INDBERETNING.MESSAGE_REF_ID.LABEL' | translate | async }}</div>
                <div *ngIf="model.messageRefId" class="col-sm-8 col-md-8 col-xl-10 messagerefid">{{model.messageRefId}}</div>
                <div *ngIf="!model.messageRefId" class="col-sm-8 col-md-8 col-xl-10 messagerefid">{{ 'INDBERETNING.MESSAGE_REF_ID.MANGLER' | translate | async }}</div>
            </div>

            <div class="row">
                <div class="col-sm-4 col-md-4 col-xl-2">{{ 'INDBERETNING.FILNAVN.LABEL' | translate | async }}</div>
                <div class="col-sm-8 col-md-8 col-xl-10 filename" title='{{model.filnavn}}'>
                    {{model.filnavn}}
                </div>
            </div>

            <div class="row">
                <div class="col-sm-4 col-md-4 col-xl-2">{{ 'INDBERETNING.STATUS.LABEL' | translate | async }}</div>
                <div class="col-sm-8 col-md-8 col-xl-10 status" *ngIf="model.valideringsFejl == undefined">
                    {{ 'INDBERETNING.STATUS.MODTAGET.LABEL' | translate | async }}
                </div>
                <div class="col-sm-8 col-md-8 col-xl-10 status" *ngIf="model.valideringsFejl != undefined">
                    <p [ngClass]="{'skts-collapse': enableCollapseOnStatus}">
                        <a aria-expanded="false" href="#" class="modtaget-bemaerkning-link" *ngIf="enableCollapseOnStatus">{{ 'INDBERETNING.STATUS.MODTAGET_MED_BEMAERKNING.LABEL' | translate | async }}</a>
                        <span class="modtaget-bemaerkning-link" *ngIf="!enableCollapseOnStatus">{{ 'INDBERETNING.STATUS.MODTAGET_MED_BEMAERKNING.LABEL' | translate | async }}</span>
                    </p>
                    <div class="hidden fejlliste" [ngClass]="{'hidden': enableCollapseOnStatus}">
                        <p *ngFor="let advis of model.valideringsFejl.advisFejl">
                            <span class="fejlbeskrivelse">{{advis.fejlBeskrivelse}}</span><br/>
                            <span *ngIf="showFejlKode" class="fejlkode">{{'INDBERETING.FEJLKODE.LABEL' | translate:{fejlKode: advis.fejlKode} | async}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="links text-right noprint" *ngIf="showDownloadLink || showPrintReportLink">
            <div *ngIf="showPrintReportLink">
                <span class="skts-icon skts-icon--print skts-icon--align-left"></span>
                <a href="javascript:void(0)"
                   class="printReport-link"
                   (click)="printReport(model.oprettelsesid)">{{ 'INDBERETNING.UDSKRIV' | translate | async }}</a>
            </div>

           <a *ngIf="showDownloadLink" (click)= "downloadResource()" class="download-indberetning-link">{{ 'INDBERETNING.HENT_XML.LINK' | translate | async }}</a>

        </div>
    </div>

    <div *ngIf="showDetails" id="details-report" class="details-report">
        <h2 class="skts-row-seperator skts-collapse skts-collapse-arrow"
            (click)="getXML(model.oprettelsesid)">
            <a aria-expanded="false" href="#">{{ 'INDBERETNING.SE_DETALJER.LABEL' | translate | async }}</a>
        </h2>
        <div class="hidden skts-toggle-hidden">
            <div>
                <div class="skts-row-seperator"></div>

                <skat-report-view [report]="reports.get(model.oprettelsesid)" ></skat-report-view>
            </div>
        </div>
    </div>
</div>
