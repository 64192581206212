import { Router } from "@angular/router";
import { environment } from '../../../environments/environment';
import { isDefinedAndNotEmpty } from "./text-utils";

export function backendUrl(): string {
    return environment.apiUrl;// ? environment.apiUrl : "http://cbc.demobox.lokal:8083/";
}

// not working
export function configUrl(): string {
    return backendUrl() + "/bruger";
}

export function genererUrlTilFiluploadIndberetning(): string {
    return backendUrl() + "/indberetninger";
}

export function genererUrlTilFiluploadValider(): string {
    return backendUrl() + "/validering";
}

export function genererUrlTilOversigt(): string {
    return backendUrl() + "/indberetninger";
}

export function genererUrlTilIndberetningsXML(id: number): string {
    return backendUrl() + "/indberetninger/" + id;
}

export function genererUrlTilKonsolidering(reportingPeriod: string): string {
    return backendUrl() + "/views/consolidation/indberetninger/" + reportingPeriod;
}

export function genererUrlTilTilkendegivelser(): string {
    return backendUrl() + "/tilkendegivelser";
}

export function genererUrlTilTilkendegivelse(id: number): string {
    return backendUrl() + "/tilkendegivelser/" + id;
}

export function seNumberValidationUrl(): string {
    return backendUrl() + "/frontendvalidation/senumber";
}

export function rapporteringPeriodeSlutValidationUrl(): string {
    return backendUrl() + "/frontendvalidation/rapporteringperiodeslut";
}

export function genererUrlTilTekster(languageCode: string): string {
    return backendUrl() + "/tekster/frontend-" + languageCode.toLowerCase();
}

//Kan ikke testes idét den trigger et hard reload og tests kører i en test context.
export function getUrlParam(name: string) {
    const regex = new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)');
    const urlName = regex.exec(location.href);
    if (urlName) {
        return decodeURIComponent(urlName[1]);
    } else {
        return null;
    }
}

export function goToPortal(router:Router) {
    const url = environment.portalUrl;
    if (!isDefinedAndNotEmpty(url)) {
        router.navigate(["/forside"]);
    } else {
        window.location.href = url;
    }
}

export function goToLogout(router:Router) {
    const url = environment.logoutUrl;
    if (!isDefinedAndNotEmpty(url)) {
        router.navigate(["/forside"]);
    } else {
        window.location.href = url;
    }
}

export const pathsWhereDataCanBeLost:Array<string> = [];
