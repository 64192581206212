<div class="kvittering">
    <div class="clearfix">
        <h1 id="tilkendegiv-kvittering-overskrift" class="pull-left">{{'TILKENDEGIVELSER.KVITTERING.OVERSKRIFT' | translate | async }}</h1>

        <p class="pull-right no-print">
            <a id="tilkendegivelser-print" href="#" class="js-print"><span
                class="skts-icon skts-icon--print skts-icon--align-left"></span>{{ 'TILKENDEGIVELSER.KVITTERING.UDSKRIV_KNAP' | translate | async }}</a>
        </p>
    </div>

    <ul class="skts-navbar-wizard">
        <li><span>{{ 'TILKENDEGIVELSER.TILKENDEGIV.PROGRESSBAR.TILKENDEGIV' | translate | async }}</span></li>
        <li class="active"><span>{{ 'TILKENDEGIVELSER.TILKENDEGIV.PROGRESSBAR.KVITTERING' | translate | async }}</span></li>
    </ul>

    <div class="clearfix">
        <p id="kvittering-overskrift" class="pull-left">{{'TILKENDEGIVELSER.KVITTERING.INFO.LABEL' | translate | async }}</p>

        <p class="pull-right no-print">
            <a id="goToTidligereTilkendegivelser" (click)="goToTidligereTilkendegivelser()">
                {{'TILKENDEGIVELSER.KVITTERING.SE_TIDLIGERE_TILKENDEGIVELSER' | translate | async}}
            </a>
        </p>
    </div>

    <div id="kvittering-detaljer" class="well">
        <skat-tilkendegivelse-view
            [model]="model"
            [correctLinkEnabled]="false"
            [pdfLinkEnabled]="true">
        </skat-tilkendegivelse-view>
    </div>

    <div id="kvittering-info">
        <div class="du-kan-rette">
            <div>{{ 'TILKENDEGIVELSER.KVITTERING.DU_KAN_RETTE' | translate | async }}</div>
            <a routerLink="/tidligere-tilkendegivelser">{{ 'TILKENDEGIVELSER.KVITTERING.TIDLIGER_TILKENDEGIVELSER_LINK_LABEL' | translate | async }}</a>
        </div>

        <div class="du-kan-laese">
            <div>{{ 'TILKENDEGIVELSER.KVITTERING.DU_KAN_LAESE' | translate | async }}</div>
            <a href="https://www.skat.dk">{{ 'TILKENDEGIVELSER.KVITTERING.SKAT_LINK_LABEL' | translate | async }}</a>
        </div>

        <div class="venlig-hilsen">
            <div>{{ 'TILKENDEGIVELSER.KVITTERING.VENLIG_HILSEN' | translate | async }}</div>
        </div>
    </div>
</div>
