import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";

@Injectable()
export class SkatHttp extends HttpClient {
    constructor(backend: HttpXhrBackend) {
        super(backend);
    }

    post(url: string, body: any, options?: any): Observable<any> {
        const newUrl: string = encodeURI(url);
        return super.post(newUrl, body, options);
    }

    get(url: string, options?: any): Observable<any> {
        const newUrl: string = encodeURI(url);
        return super.get(newUrl, options).pipe(map(res => res));
    }
}
