<div>
    <h1 id="forside-overskrift" class="text-center" style="padding-top: 30px;">{{ 'FORSIDE.OVERSKRIFT' | translate | async }}</h1>

    <div class="row">
        <div class="col-sm-6 col-md-4 col-sm-offset-3 col-md-offset-4">
            <a id="menu-filoverfoersler-valider" routerLink="/valider" class="skts-transport-box">
                <h2>{{ 'FORSIDE.MENU.VALIDER' | translate | async }}</h2>
            </a>
        </div>
        <div class="col-sm-6 col-md-4 col-sm-offset-3 col-md-offset-4">
            <a id="menu-filoverfoersler-indberet" routerLink="/indberet" class="skts-transport-box">
                <h2>{{ 'FORSIDE.MENU.INDBERET' | translate | async }}</h2>
            </a>
        </div> <div class="col-sm-6 col-md-4 col-sm-offset-3 col-md-offset-4">
            <a id="menu-indberetinger" routerLink="/tidligere-indberetninger" class="skts-transport-box">
                <h2>{{ 'FORSIDE.MENU.INDBERETNINGER' | translate | async }}</h2>
            </a>
        </div>
        <div class="col-sm-6 col-md-4 col-sm-offset-3 col-md-offset-4">
            <a id="menu-tilkendegiv" routerLink="/tilkendegiv" class="skts-transport-box">
                <h2>{{ 'FORSIDE.MENU.TILKENDEGIV' | translate | async }}</h2>
            </a>
        </div>
        <div class="col-sm-6 col-md-4 col-sm-offset-3 col-md-offset-4">
            <a id="menu-tidligere-tilkendegivelser" routerLink="/tidligere-tilkendegivelser" class="skts-transport-box">
                <h2>{{ 'FORSIDE.MENU.TIDLIGERE.TILKENDEGIVELSER' | translate | async }}</h2>
            </a>
        </div>
    </div>
</div>
