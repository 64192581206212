import { HttpErrorResponse } from "@angular/common/http";
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from "rxjs";
import { RapporteringPeriodeSlutInputFeltComponent } from "../../../shared/components/partials/rapportering-periode-input-felt/rapportering-periode-slut-input-felt.component";
import { SeNumberInputFeltComponent } from "../../../shared/components/partials/se-number-input-felt/se-number-input-felt.component";
import { sortedCountries } from "../../../shared/datalists/country";
import { ErrorService } from "../../../shared/services/error/error.service";
import { TranslationService } from "../../../shared/services/translate/translation.service";
import { DK_DATE_FORMAT, isParseableDkDate } from "../../../shared/utils/date-utils";
import { nyStandardFejl, standardOverskrift } from "../../../shared/utils/fejl-utils";
import { ModerselskabInfoModel } from "../../models/moderselskab-info.model";
import { TilkendegivelseKvitteringModel } from "../../models/tilkendegivelse-kvittering.model";
import { TilkendegivelseModel } from "../../models/tilkendegivelse.model";
import { TilkendegivelserService } from "../../services/tilkendegivelser.service";

@Component({
    templateUrl: './tilkendegivelser-tilkendegiv.component.html',
    providers: [],
    styleUrls: ['./tilkendegivelser-tilkendegiv.component.css']
})
export class TilkendegivelserTilkendegivComponent implements OnInit, AfterViewInit {

    @ViewChild('tin') tinSeNumberField: SeNumberInputFeltComponent;
    @ViewChild('inputTjentOver', {static: true}) inputTjentOver: ElementRef;
    @ViewChild('inputTjentUnder', {static: true}) inputTjentUnder: ElementRef;
    @ViewChild('rapporteringPeriodeSlutComponent') rapporteringPeriodeSlutField: RapporteringPeriodeSlutInputFeltComponent;

    errorsOverskrift: string;
    spinnerTekst: string = "";
    tilkendegivPostSuccess: Subscription;
    tilkendegivPostFejl: Subscription;
    hentTilkendegivelse: Subscription;
    private viewIsInitialized = false;

    corrects: number;
    private initialRapporteringPeriodeSlutValue: string;
    originalModel: TilkendegivelseModel;

    constructor(private router: Router,
                private route: ActivatedRoute,
                public errors: ErrorService,
                private translationService: TranslationService,
                private tilkendegivService: TilkendegivelserService,
                public tilkendegivelseModel: TilkendegivelseModel,
                public tilkendegivelseKvitteringModel: TilkendegivelseKvitteringModel,
                private titleService: Title) {
        this.errorsOverskrift = standardOverskrift();
    }

    ngOnInit() {
        this.tilkendegivelseModel = new TilkendegivelseModel();
        this.translationService.environmentAwareTranslate('TILKENDEGIVELSER.TILKENDEGIV.SPINNER').subscribe((res: string) => {
            this.spinnerTekst = res;
            this.subscribePaaTilkendegivelseHentet();
            this.subscribePaaPostSuccess();
            this.subscribePaaPostFejl();
        });
        this.corrects = this.route.snapshot.queryParams.corrects;
        if (this.corrects) {
            this.tilkendegivelseModel.corrects = this.corrects;
            this.tilkendegivService.hentTilkendegivelse(this.corrects);
        }
    }

    ngAfterViewInit() {
        this.translationService.environmentAwareTranslate('TILKENDEGIVELSER.TILKENDEGIV.OVERSKRIFT').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
        this.viewIsInitialized = true;
    }

    ngOnDestroy() {
        this.tilkendegivelseModel = null;
        this.viewIsInitialized = false;
        this.corrects = null;
        this.initialRapporteringPeriodeSlutValue = null;
        this.errors.reset();
        if (this.tilkendegivPostSuccess) {
            this.tilkendegivPostSuccess.unsubscribe();
        }
        if (this.tilkendegivPostFejl) {
            this.tilkendegivPostFejl.unsubscribe();
        }
        if (this.hentTilkendegivelse) {
            this.hentTilkendegivelse.unsubscribe();
        }
    }

    tilkendegiv() {
        if (!this.viewIsInitialized) {
            return;
        }
        if (this.tilkendegivelseModel.omfattetAfCbc &&
            this.initialRapporteringPeriodeSlutValue !== this.tilkendegivelseModel.moderselskabInfo.rapporteringPeriodeSlut
            && !this.rapporteringPeriodeSlutField.valid) {
            this.rapporteringPeriodeSlutField.focus();
            return;
        }
        if (this.hasDKJurisdiction() && !this.tinSeNumberField.seNumberIsValid) {
            this.tinSeNumberField.focus();
            return;
        }
        this.submitTilkendegivelse();
    }

    private submitTilkendegivelse() {
        page.showLoader(this.spinnerTekst);
        this.errors.reset();
        this.errorsOverskrift = standardOverskrift();
        this.tilkendegivService.tilkendegivPost(this.tilkendegivelseModel);
    }

    submitButtonLabel(): string {
        if (this.corrects) {
            return 'TILKENDEGIVELSER.TILKENDEGIV.RET_KNAP';
        } else {
            return 'TILKENDEGIVELSER.TILKENDEGIV.TILKENDEGIV_KNAP';
        }
    }

    shouldDisableSubmitButton(): boolean {
        return this.corrects && this.tilkendegivelseModel.isIdenticalTo(this.originalModel);
    }

    subscribePaaPostSuccess() {
        this.tilkendegivPostSuccess = this.tilkendegivService.tilkendegivPostSuccess.subscribe(
            model => {
                this.tilkendegivelseKvitteringModel.assign(model);
                page.hideLoader();
                this.router.navigate(['/tilkendegiv/kvittering']);
            }
        );
    }

    subscribePaaPostFejl() {
        this.tilkendegivPostFejl = this.tilkendegivService.tilkendegivPostFejl.subscribe((error: HttpErrorResponse) => {
            if (error.status !== 401) {
                this.errors.tilfoejFejl([nyStandardFejl()]);
                this.errorsOverskrift = standardOverskrift();
                page.hideLoader();
            }
        });
    }

    private subscribePaaTilkendegivelseHentet() {
        this.hentTilkendegivelse = this.tilkendegivService.tilkendgivelseHentet.subscribe(
            (model: TilkendegivelseModel) => {
                this.tilkendegivelseModel = model;
                this.tilkendegivelseModel.corrects = this.corrects;
                if (this.corrects) {
                    this.originalModel = model.clone();
                }

                if (this.tilkendegivelseModel.omfattetAfCbc) {
                    this.inputTjentOver.nativeElement.click();
                    this.initialRapporteringPeriodeSlutValue = this.tilkendegivelseModel.moderselskabInfo.rapporteringPeriodeSlut;
                } else {
                    this.inputTjentUnder.nativeElement.click();
                    this.tilkendegivelseModel.moderselskabInfo = undefined;
                }

                page.hideLoader();
            }, error => {
                this.errors.tilfoejFejl([nyStandardFejl()]);
                this.errorsOverskrift = standardOverskrift();
                page.hideLoader();
            });
    }

    currentYearString() {
        return new Date().getFullYear().toString();
    }

    countries() {
        return sortedCountries;
    }

    hasDKJurisdiction() {
        const moderselskabInfo = this.tilkendegivelseModel.moderselskabInfo;
        if (!moderselskabInfo) {
            return false;
        }
        return moderselskabInfo.skatteJurisdiktion === "DK";
    }

    jurisdictionChanged(event: any) {
        const newJurisdiction: string = event.target.value;
        this.tinSeNumberField.validationEnabled = newJurisdiction === 'DK';
        this.tinSeNumberField.refreshValidation();
    }

    calculateRapporteringPeriodeSlut(): string {
        return this.addOneDay(this.tilkendegivelseModel.moderselskabInfo.rapporteringPeriodeStart);
    }

    calculateOmsaetningPeriodeSlut() {
        return this.addOneDay(this.tilkendegivelseModel.omsaetningPeriodeStart);
    }

    omfattetAfCbcClicked(omfattetAfCbc: boolean) {
        const model = this.tilkendegivelseModel;
        if (omfattetAfCbc === model.omfattetAfCbc) {
            return;
        }

        if (omfattetAfCbc) {
            model.moderselskabInfo = new ModerselskabInfoModel();
        } else {
            model.moderselskabInfo = undefined;
        }
        model.omfattetAfCbc = omfattetAfCbc;
    }

    private addOneDay(dateString: string): string {
        if (!isParseableDkDate(dateString)) {
            return "";
        } else {
            const output = moment(dateString, DK_DATE_FORMAT)
                .add(1, 'days')
                .format(DK_DATE_FORMAT);
            if (output === "Invalid date") {
                return "";
            } else {
                return output;
            }
        }
    }

}
