import { countries } from "../datalists/country";

export function todayAsPrettyText(): string {
    const today = new Date();
    const aar:string = today.getFullYear().toString();
    const maaned:string = (today.getMonth() + 1).toString();
    const date:string = today.getDate().toString();

    return (date.length === 1 ? "0" + date : date) +
        "-" + (maaned.length === 1 ? "0" + maaned : maaned) +
        "-" + aar;
}

/**
 * Transforms a datetime string in the format `yyyy-MM-dd'T'HH:mm:ss` to the format `dd-MM-yyyy' kl. 'HH:mm`.
 */
export function formatZuluDateTime(dateTime: string): string{
    const date = new Date(dateTime);
    const day = date.getDate();
    const month = date.getMonth()+1; // getMonth() returns 0 as january

    const hour = date.getHours();
    const minutes = date.getMinutes();
    let formatedDate = "";
    formatedDate += day < 10 ? ("0" + day) : day;
    formatedDate += '-';
    formatedDate += month < 10 ? ("0" + month) : month;
    formatedDate += '-'+date.getFullYear().toString();
    formatedDate += ' kl. ';
    formatedDate += hour < 10 ? ("0" + hour) : hour;
    formatedDate += ':';
    formatedDate += minutes < 10 ? ("0" + minutes) : minutes;
    return formatedDate;
}

/**
 * Converts a Date to a string in the format `dd-MM-yyyy' kl. 'HH:mm`.
 */
export function formatDatetime(dateTime: Date): string {
    if (!isDefined(dateTime)) {
        return "";
    }
    const day = dateTime.getDate();
    const month = dateTime.getMonth()+1; // getMonth() returns 0 as january

    const hour = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    let formatedDate = "";
    formatedDate += day < 10 ? ("0" + day) : day;
    formatedDate += '-';
    formatedDate += month < 10 ? ("0" + month) : month;
    formatedDate += '-'+dateTime.getFullYear().toString();
    formatedDate += ' kl. ';
    formatedDate += hour < 10 ? ("0" + hour) : hour;
    formatedDate += ':';
    formatedDate += minutes < 10 ? ("0" + minutes) : minutes;
    return formatedDate;
}

/**
 * Transforms a date string in the format `yyyy-MM-dd` to the format `dd-MM-yyyy`.
 */
export function formatSimpleDate(dateString: string){
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const dayString = day < 10 ? ("0" + day) : day;
    const monthString = month < 10 ? ("0" + month) : month;

    return dayString + "-" + monthString + "-" + date.getFullYear().toString();
}

/**
 * Transforms a date string in the format `dd-MM-yyyy` to the format `yyyy-MM-dd`.
 */
export function reverseFormatSimpleDate(dateString: string){
    const dayString = dateString.substr(0, 2);
    const monthString = dateString.substr(3, 2);
    const yearString = dateString.substr(6, 4);

    return yearString + "-" + monthString + "-" + dayString;
}

/**
 * Compares two timestamp strings in the ZULU format by converting them to `Date`s and comparing them.
 * @returns 1 if timestamp1 is newer than timestamp2, -1 if the opposite is the case, and 0 if they are equal.
 */
export function compareTimestamps(timestamp1: string, timestamp2: string): number {
    if (timestamp1 === timestamp2) {
        return 0;
    }

    return new Date(timestamp1) > new Date(timestamp2) ? 1 : -1;
}

export function getCountryNameByCode(code: string): string {
    return countries.find(country => country.value === code).key;
}

// Returns the country name with captitalized first letter and the rest in lowercase
export function getPrettyCountryNameByCode(code: string): string {
    const countryNameAllCaps = getCountryNameByCode(code);
    if (!countryNameAllCaps) {
        return countryNameAllCaps;
    }
    const lc = countryNameAllCaps.toLowerCase();
    return lc.charAt(0).toUpperCase() + lc.slice(1);
}

export function isDefined(value: any): boolean {
    return value != undefined && value !== null && value !== 'null' && value !== 'undefined';
}

export function isDefinedAndNotEmpty(value: any): boolean {
    return isDefined(value) && value !== "" && value !== [];
}

export function noOfValues(map: Map<Object, Object>): number {
    return [].concat(...Array.from(map.values())).length;
}

export function removeCbcdocRefPrefix(id: string): string {
    const regex = /^[A-z]{2}[0-9]{4}[0-9]{8}[FI]{2}/g;
    if (id.length > 16 && regex.test(id)) {
        return id.substr(16);
    }
    return id;
}

export function splitCbCDocRefId(id: string): any {
    let prefix = id;
    let value = "";
    if (isDefinedAndNotEmpty(id) && id.length > 16) {
        value = id.substr(16);
        prefix = id.substr(0,16);
    }
    return {
        'prefix': prefix,
        'value': value
    };
}
