import { Injectable, Renderer2 } from "@angular/core";

const formSectionClass = "skts-process-form-section";
const hasErrorClass = 'custom-has-error';

@Injectable()
export class ErrorStylingService {

    constructor(private renderer: Renderer2) {
    }

    findSurroundingFormSection(element: HTMLElement): HTMLElement {
        if (!element || element.classList.contains(formSectionClass)) {
            return element;
        }
        return this.findSurroundingFormSection(element.parentElement);
    }

    addErrorStyling(element: HTMLElement) {
        const formSection = this.findSurroundingFormSection(element);
        if (formSection) {
            this.renderer.addClass(formSection, hasErrorClass);
        }
    }

    removeErrorStyling(datoVaelgerElement: HTMLElement) {
        const formSection = this.findSurroundingFormSection(datoVaelgerElement);
        if (formSection) {
            this.renderer.removeClass(formSection, hasErrorClass);
        }
    }
}
