<form role="form" (ngSubmit)="indberet()" *ngIf="model.fil">
    <div class="clearfix">
        <h1 id="filoverfoersel-overskrift" class="pull-left">{{ 'FILOVERFOERSEL.INDBERET.OVERSKRIFT' | translate | async }}</h1>
    </div>
    <ul class="skts-navbar-wizard">
        <li><span>{{ 'FILOVERFOERSEL.INDBERET.VAEGLFIL.LABEL' | translate | async }}</span></li>
        <li class="active"><span>{{ 'FILOVERFOERSEL.INDBERET.KONTROLLER.LABEL' | translate | async }}</span></li>
        <li><span>{{ 'FILOVERFOERSEL.INDBERET.KVITTERING.LABEL' | translate | async }}</span></li>
    </ul>

    <skat-fejl-liste *ngIf="errors.derErFejl()" [titel]="errorsOverskrift"></skat-fejl-liste>

    <p class="pull-right no-print">
        <a id="filoverfoersel-print" href="javascript:void(0)" (click)="printReport()"><span
            class="skts-icon skts-icon--print skts-icon--align-left"></span>{{ 'FILOVERFOERSEL.INDBERET.KONTROLLER.UDSKRIV_RAPPORT' | translate | async }}</a>
    </p>
    <p [innerHTML]="'FILOVERFOERSEL.INBERET_KONTROLLER.DESCRIPTION.LABEL' | translate | async"></p>

    <div class="panel">
        <div class="panel-body">
            <div class="panel-title company-name-header" *ngIf="report?.companyNames !== undefined">
                <div class="panel-title-content">
                    <span>{{ 'FILOVERFOERSEL.INBERET_KONTROLLER.KONCERN.LABEL' | translate | async }}</span>
                    <h2 [attr.id]="'company-name'" *ngIf="report?.companyNames.length === 1">{{report?.companyNames[0]}}</h2>
                    <h2 [attr.id]="'company-name'" *ngIf="report?.companyNames.length > 1">{{report?.companyNames[0]}} {{'FILOVERFOERSEL.KONTROLLER.KONCERNNAVN_MED_FLERE.LABEL' | translate | async}}</h2>
                </div>
            </div>
            <div class="panel-title reportingperiod-header" [ngClass]="{'panel-title-right': report?.companyNames !== undefined}">
                <div class="panel-title-content">
                    <span>{{ 'FILOVERFOERSEL.INBERET_KONTROLLER.REGNSKABSPERIODE.LABEL' | translate | async }}</span>
                    <span id="reporting-period">{{report?.reportingPeriod}}</span>
                </div>
            </div>
            <div class="panel-title panel-title-right currency-header" *ngIf="report?.currency !== undefined">
                <div class="panel-title-content">
                    <span>{{ 'FILOVERFOERSEL.INBERET_KONTROLLER.VALUTA.LABEL' | translate | async }}</span>
                    <span id="currency">{{report?.currency}}</span>
                </div>
            </div>
            <div class="panel-title panel-title-right filename-header" [ngClass]="{'no-companyname-header': report?.companyNames === undefined}">
                <div class="panel-title-content">
                    <span>{{ 'FILOVERFOERSEL.INBERET_KONTROLLER.FILNAVN.LABEL' | translate | async }}</span>
                    <span class='filename' id="filename" title='{{model.filnavn}}'>{{model.filnavn}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="well">
        <skat-report-view *ngIf="model.fil" [report]="report"></skat-report-view>
    </div>

    <div class="clearfix skts-wizard-buttons">
        <input type="submit" class="pull-right btn btn-primary js-form-validate" id="knap-Send" value="{{ 'FILOVERFOERSEL.INDBERET.SEND_KNAP' | translate | async }}"/>

        <button type="button" class="btn btn-default skts-btn-secondary pull-left" id="knap-tilbage" (click)="gaaTilbage()">{{'FILOVERFOERSEL.INDBERET.TILBAGE_KNAP' |  translate | async}}</button>
    </div>

</form>
