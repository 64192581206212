import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ReportComponent } from '../../../shared/components/partials/report/report.component';
import { uploadType } from "../../../shared/enums/uploadtype.enum";
import { Fejl } from "../../../shared/models/fejl.model";
import { ReportModel } from '../../../shared/models/report.model';
import { Valideringsfejl } from '../../../shared/models/valideringsfejl.model';
import { ErrorService } from "../../../shared/services/error/error.service";
import { TranslationService } from "../../../shared/services/translate/translation.service";
import { nyStandardFejl, standardOverskrift } from '../../../shared/utils/fejl-utils';
import { parseXMLFileToReportModel } from '../../../shared/utils/report-mapper';
import { FiloverfoerselModel } from "../../models/filoverfoersel.model";
import { FiluploadService } from '../../services/filupload/filupload.service';

@Component({
    templateUrl: './filoverfoersel-kontroller.component.html',
    providers: [
        FiluploadService
    ],
    styleUrls: ['./filoverfoersel-kontroller.component.css']
})
export class FiloverfoerselKontrollerComponent implements OnInit, AfterViewInit {

    @ViewChild(ReportComponent) reportComponent: ReportComponent;
    spinnerTekst:string = "";
    errorsOverskrift: string;

    reportingPeriod: string;
    currency: string;
    report: ReportModel;
    reportPromise: Promise<ReportModel>;
    removeFile: boolean = true;

    constructor(private router: Router,
                public model: FiloverfoerselModel,
                public errors: ErrorService,
                private service: FiluploadService,
                private translationService: TranslationService,
                private titleService: Title) {
        this.errors.reset();
    }

    async ngOnInit() {
        this.errors.reset();
        this.subscribePaaSuccess();
        this.subscribePaaFejl();
        this.subscribePaaValideringsFejl();

        if (this.model.oprettelsesid !== null) {
            this.reportPromise = parseXMLFileToReportModel(this.model.fil, true);
            this.report = await this.reportPromise;

            this.translationService.environmentAwareTranslate('FILOVERFOERSEL.KONTROLLER.SPINNER').subscribe((spinnerTekst: string) => {
                this.spinnerTekst = spinnerTekst;
            });
        } else {
            this.router.navigate(['/indberet']);
        }
    }

    ngAfterViewInit(){
        this.translationService.environmentAwareTranslate('FILOVERFOERSEL.INDBERET.OVERSKRIFT').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
    }

    indberet() {
        this.errors.reset();
        page.showLoader(this.spinnerTekst);
        this.service.uploadFil(uploadType.indberet, '/indberet');
    }

    printReport(){

        this.reportComponent.print = true;
        const reportComponent = this.reportComponent;

        setTimeout(function (){
            window.print();
            reportComponent.print = false;

        }, 300);

    }

    subscribePaaSuccess() {
        this.service.uploadCompleted.subscribe(
            () => {
                page.hideLoader();
                this.removeFile = false;
                this.router.navigate(['/indberet/modtaget']);
            }
        );
    }

    subscribePaaValideringsFejl(){

        this.service.validationErrorEvent.subscribe(
            (data: Valideringsfejl) => {
                page.hideLoader();

                this.removeFile = false;
                this.model.valideringsFejl = data;
                this.errors.tilfoejFejl([nyStandardFejl("FILOVERFOERSEL.INDBERET.UVENTET_VALIDERINGS_FEJL")]);
                this.model.errorsOverskrift = "FILOVERFOERSEL.INDBERET.UVENTET_VALIDERINGS_FEJL.OVERSKRIFT";
                this.router.navigate(['/indberet']);
            }
        );
    }

    gaaTilbage(){
        this.removeFile = false;
        this.router.navigate(['/indberet']);
    }

    subscribePaaFejl() {
        this.service.uploadCompletedWithError.subscribe(
            (fejl: Fejl[]) => {
                page.hideLoader();
                this.errors.tilfoejFejl(fejl);
                if (fejl[0].tekniskFejl) {
                    fejl[0].fejlBeskrivelse = "FILOVERFOERSEL.INDBERET_KONTROLLER.UVENTET_TEKNISK_FEJL";
                    this.errorsOverskrift = "FILOVERFOERSEL.INDBERET_KONTROLLER.UVENTET_TEKNISK_FEJL.OVERSKRIFT";
                }
                else {
                    this.errorsOverskrift = standardOverskrift();
                }
            }
        );
    }

    ngOnDestroy() {
        if(this.removeFile){
            this.model.init();
        }
    }
}
