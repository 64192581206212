<form role="form" (ngSubmit)="valider()">
    <div class="clearfix">
        <h1 id="filoverfoersel-overskrift" class="pull-left">{{ 'FILOVERFOERSEL.INDBERET.OVERSKRIFT' | translate | async }}</h1>
    </div>

    <ul class="skts-navbar-wizard">
        <li class="active"><span>{{ 'FILOVERFOERSEL.INDBERET.VAEGLFIL.LABEL' | translate | async }}</span></li>
        <li><span>{{ 'FILOVERFOERSEL.INDBERET.KONTROLLER.LABEL' | translate | async }}</span></li>
        <li><span>{{ 'FILOVERFOERSEL.INDBERET.KVITTERING.LABEL' | translate | async }}</span></li>
    </ul>

    <skat-fejl-liste *ngIf="errors.derErFejl()" [titel]="errorsOverskrift"></skat-fejl-liste>

    <div class="well">
        <div class="row skts-process-form-section skts-row-seperator skts-required">
            <div class="col-sm-4 col-md-4">
                <label for="{{ model.fil ? 'fileUploadNewFile' : 'fileUpload' }}">{{ 'FILOVERFOERSEL.INDBERET.LABEL' | translate | async }}</label>
                <skat-hjaelpetekst guid="8561"></skat-hjaelpetekst>
            </div>
            <div class="col-sm-8 col-md-8 text-right" *ngIf="!model.fil">
                <span id="skts-btn-file" class="btn btn-default skts-btn-file" [ngClass]="{ 'skts-btn-file-focus': focusOnButton}">
                    {{ 'FILOVERFOERSEL.INDBERET.VAELG_FIL_KNAP' | translate | async }}
                  <input type="file" title="" (change)="onFilValgt($event)" name="fileUpload" id="fileUpload" (focus)="focusOnButton = true;" (blur)="focusOnButton = false;"/>
                </span>
            </div>
            <div class="col-sm-7 col-md-7 text-right filename" *ngIf="model.fil">
                <span id="filename">{{model.fil.name}}</span>

            </div>
            <div class="col-sm-1 col-md-1 text-right" *ngIf="model.fil">
                <a id="removeFileButton" (click)="onFilFjernet()" title="{{'FILOVERFOERSEL.FJERN_FIL_MOUSEOVER.LABEL' | translate | async}}" (keyup.enter)="onFilFjernet()"
                   aria-label="Fjern fil" tabindex="0">
                    <span aria-hidden="true" class="skts-icon skts-icon--small skts-rounded-icon skts-icon--slet-hvid"></span>
                </a>
            </div>
        </div>
    </div>
    <div class="clearfix skts-wizard-buttons" *ngIf="!isValidated">
        <input type="submit" class="pull-right btn btn-default js-form-validate" id="knap-Send" value="{{ 'FILOVERFOERSEL.INDBERET.NAESTE_KNAP' | translate | async }}" [disabled]="model.fil === null || isValidated && validationErrors !== null" />
    </div>
    <input type="hidden" name="reportType" value="file"/>

    <div *ngIf="validationErrors !== null && isValidated">

        <skat-valideringsfejl-view *ngIf="validationErrors.xmlFejl.length > 0 || validationErrors.indsholdsFejl.length > 0" [validationErrors]="validationErrors" [heading]="'FILOVERFOERSEL.INBERET.FEJL.OVERSKRIFT' | translate:{filnavn: model?.fil?.name} | async"></skat-valideringsfejl-view>
        <skat-valideringsfejl-view *ngIf="validationErrors.advisFejl.length > 0 && validationErrors.xmlFejl.length === 0 && validationErrors.indsholdsFejl.length === 0" [validationErrors]="validationErrors" [heading]="'FILOVERFOERSEL.INBERET.FEJL.ADVIS.OVERSKRIFT' | translate:{filnavn: model?.fil?.name} | async"></skat-valideringsfejl-view>

        <div class="clearfix skts-wizard-buttons">
            <span *ngIf="validationErrors.xmlFejl.length > 0 || validationErrors.indsholdsFejl.length > 0" id="skts-btn-new-file" class="pull-right btn btn-default skts-btn-file" [ngClass]="{ 'skts-btn-new-file-focus': focusOnNewButton}">
                {{ 'FILOVERFOERSEL.INDBERET.VAELG_NY_FIL_KNAP' | translate | async }}
                <input type="file" (change)="onFilValgt($event)" name="fileUpload" id="fileUploadNewFile" (focus)="focusOnNewButton = true;" (blur)="focusOnNewButton = false;" title="{{'FILOVERFOERSEL.INDBERET.VAELG_NY_FIL_MOUSEOVER.LABEL' | translate | async}}"/>
            </span>
            <input *ngIf="validationErrors.xmlFejl.length === 0 && validationErrors.indsholdsFejl.length === 0" type="button" (click)="goToNext()" class="pull-right btn btn-default js-form-validate" id="knap-next"  value="{{ 'FILOVERFOERSEL.INDBERET.NAESTE_KNAP' | translate | async }}" />
            <input type="button" class="pull-left btn btn-default skts-btn-secondary js-form-validate" data-toggle="modal" data-target="#indberetAfslutModal" data-backdrop="static" id="knap-afslut" value="{{ 'FILOVERFOERSEL.INDBERET.AFSLUT_RET_FIL_KNAP' | translate | async }}" />
        </div>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" id="indberetAfslutModal" style="display: none">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button id="luk-indberet-afslut-modal" type="button" class="close" data-dismiss="modal" aria-label="Luk"><span aria-hidden="true"></span></button>
                </div>
                <div class="modal-body js-modal-body text-center">
                    {{ 'FILOVERFOERSEL.INDBERET.LOGAF_MODAL.LEDETEKST' | translate | async }}
                </div>
                <div class="modal-footer">
                    <div class="clearfix skts-wizard-buttons">
                        <input id="accepter-logaf" class="btn btn-primary pull-right" value="{{ 'FILOVERFOERSEL.INDBERET.LOGAF_MODAL.ACCEPTER' | translate | async }}" type="button" (click)="goToLogout()" data-dismiss="modal">
                        <input id="afvis-logaf" class="btn pull-left skts-btn-secondary" value="{{ 'FILOVERFOERSEL.INDBERET.LOGAF_MODAL.AFVIS' | translate | async }}" type="button" (click)="goToPortal()" data-dismiss="modal">
                    </div>
                </div>
            </div>
        </div>
    </div>

</form>
