import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IndberetningService } from '../shared/services/indberetning/indberetning.service';
import { SharedModule } from '../shared/shared.module';
import { OversigtComponent } from './components/oversigt/oversigt.component';
import { indberetningerRoutes } from './indberetninger.routing';

@NgModule({
    imports: [
        CommonModule,
        indberetningerRoutes,
        SharedModule
    ],
    declarations: [
        OversigtComponent
    ],
    exports: [
        OversigtComponent,
        RouterModule
    ],
    providers: [
        IndberetningService
    ]
})

export class IndberetningerModule {
}
