import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const httpHeadersJson = new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'});

/**
 * Service handling all REST requests and responses to/from the backend.
 */
@Injectable()
export class HttpService {

    unauthorized: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();

    emitErrorHandler: OperatorFunction<any, any> = catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
            this.unauthorized.next(error);
        }
        return throwError(error);
    });

    noEmitErrorHandler: OperatorFunction<any, any> = catchError((error: HttpErrorResponse) => {
        return throwError(error);
    });

    constructor(private http: HttpClient) {
    }

    /**
     * Construct a GET request which interprets the body as JSON and returns it.
     * An event is emitted to the authorized Subject in case of Unauthorized (401).
     *
     * @return an `Observable` of the body as type `T`.
     */
    get<T>(url: string, params: any = null): Observable<T> {
        return this.http.get<T>(url, {
            headers: httpHeadersJson,
            params: params,
            withCredentials: true
        }).pipe(this.emitErrorHandler);
    }

    /**
     * Construct a GET request which interprets the body as JSON and returns it.
     * Same as get, but without the 401 emission.
     *
     * @return an `Observable` of the body as type `T`.
     */
    getWithoutEmit<T>(url: string, params: any = null): Observable<T> {
        return this.http.get<T>(url, {
            headers: httpHeadersJson,
            params: params,
            withCredentials: true
        }).pipe(this.noEmitErrorHandler);
    }

    /**
     * Construct a GET request which interprets the body as text and returns it.
     *
     * @return an `Observable` of the body as a `string`.
     */
    getText(url: string): Observable<string> {
        return this.http.get(url, {
            headers: httpHeadersJson,
            responseType: 'text',
            withCredentials: true
        }).pipe(this.emitErrorHandler);
    }

    /**
     * Construct a POST request which interprets the body as JSON and returns it.
     *
     * @return an `Observable` of the body as type `T`.
     */
    post<T>(url: string, body: any): Observable<T> {
        return this.http.post<T>(url, body, {
            headers: httpHeadersJson,
            withCredentials: true
        }).pipe(this.emitErrorHandler);
    }
}
