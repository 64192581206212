import { ReportModel } from "../../models/report.model";

/**
 * Represents the result of a call to the indberetning endpoint, which holds the business data.
 */
export class IndberetningResultModel {

    constructor(public id: number, public model: ReportModel) {
    }

}
