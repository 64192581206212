import { Component, Input } from '@angular/core';
import { Fejl } from "../../../models/fejl.model";
import { ErrorService } from "../../../services/error/error.service";

@Component({
    selector: "skat-fejl-liste",
    templateUrl: "./fejl-liste.component.html"
})

export class FejlListeComponent {

    fejl: Fejl[];

    @Input()
    titel:string;

    constructor(private errorService: ErrorService) {
        this.update();
        errorService.newErrors.subscribe((nyeFejl: Array<Fejl>) => {
            this.update();
        });
    }

    update(){
        this.fejl = this.errorService.alleFejl();
    }
}
