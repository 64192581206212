import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ValideringsfejlComponent } from "../shared/components/partials/valideringsfejl/valideringsfejl.component";
import { SharedModule } from '../shared/shared.module';
import { FiloverfoerselIndberetComponent } from './components/filoverfoersel-indberet/filoverfoersel-indberet.component';
import { FiloverfoerselKontrollerComponent } from './components/filoverfoersel-kontroller/filoverfoersel-kontroller.component';
import { FiloverfoerselModtagetComponent } from './components/filoverfoersel-modtaget/filoverfoersel-modtaget.component';
import { FiloverfoerselValiderComponent } from './components/filoverfoersel-valider/filoverfoersel-valider.component';
import { filuploadRoutes } from './filoverfoersel.routing';
import { FiloverfoerselModel } from "./models/filoverfoersel.model";
import { FiluploadFactory } from "./services/filupload/filupload.factory";

@NgModule({
    imports: [
        CommonModule,
        filuploadRoutes,
        SharedModule
    ],
    declarations: [
        FiloverfoerselIndberetComponent,
        FiloverfoerselModtagetComponent,
        FiloverfoerselKontrollerComponent,
        FiloverfoerselValiderComponent,
        ValideringsfejlComponent,
    ],
    exports: [
        FiloverfoerselIndberetComponent,
        FiloverfoerselModtagetComponent,
        FiloverfoerselKontrollerComponent,
        FiloverfoerselValiderComponent,
        RouterModule
    ],
    providers: [
        FiloverfoerselModel,
        FiluploadFactory
    ]
})

export class FiloverfoerselModule {
}
