export const PLIGTIG_KEY:string = "pligtig";
export const INDBERETTER_KEY:string = "indberetter";
export const PLIGTIG_COMPANY_NAME_KEY:string = "pligtig_company_name";
export const INDBERETTER_COMPANY_NAME_KEY:string = "indberetter_company_name";
export const SKATSSO_COOKIE_KEY:string = "skatsso_cookie";
export const USERNAME_KEY:string = "username";
export const PLIGTIG_VALIDATING_KEY:string = "pligtig_validating";
export const LANGUAGE_CODE_KEY: string = "language_code";

export const TEXT_KEYS_VISIBLE:string = "false";

export function getPligtig():string {
    return window.sessionStorage.getItem(PLIGTIG_KEY);
}
export function setPligtig(pligtig:string) {
    window.sessionStorage.setItem(PLIGTIG_KEY, pligtig);
}

export function getIndberetter():string {
    return window.sessionStorage.getItem(INDBERETTER_KEY);
}
export function setIndberetter(pligtig:string) {
    window.sessionStorage.setItem(INDBERETTER_KEY, pligtig);
}

export function clearUserInfo() {
    window.sessionStorage.removeItem(PLIGTIG_KEY);
    window.sessionStorage.removeItem(INDBERETTER_KEY);
    window.sessionStorage.removeItem(PLIGTIG_COMPANY_NAME_KEY);
    window.sessionStorage.removeItem(INDBERETTER_COMPANY_NAME_KEY);
    window.sessionStorage.removeItem(USERNAME_KEY);
    window.sessionStorage.removeItem(SKATSSO_COOKIE_KEY);
}

export function setPligtigCompanyName(companyName: string) {
    window.sessionStorage.setItem(PLIGTIG_COMPANY_NAME_KEY, companyName);
}

export function setIndberetterCompanyName(companyName: string) {
    window.sessionStorage.setItem(INDBERETTER_COMPANY_NAME_KEY, companyName);
}

export function setUsername(username: string) {
    window.sessionStorage.setItem(USERNAME_KEY, username);
}

export function setLanguageCode(languageCode: string) {
    window.sessionStorage.setItem(LANGUAGE_CODE_KEY, languageCode);
}

export function getPligtigCompanyName(): string {
    return window.sessionStorage.getItem(PLIGTIG_COMPANY_NAME_KEY);
}

export function getIndberetterCompanyName(): string {
    return window.sessionStorage.getItem(INDBERETTER_COMPANY_NAME_KEY);
}

export function getUsername(): string {
    return window.sessionStorage.getItem(USERNAME_KEY);
}

export function getLanguageCode(): string {
    return window.sessionStorage.getItem(LANGUAGE_CODE_KEY);
}

export function userIsBeingValidated(): boolean {
    return window.sessionStorage.getItem(PLIGTIG_VALIDATING_KEY) === "true";
}

export function setUserIsBeingValidated(isValidating:boolean) {
    window.sessionStorage.setItem(PLIGTIG_VALIDATING_KEY, isValidating.toString());
}

export function indberetterAndPligtigAreTheSame():boolean {
    const pligtig = getPligtig();
    return pligtig !== null && pligtig === getIndberetter();
}

export function waitForPligtigAndPerformAction(action: any, failedAction: any, maxTries: number = 10, tryCount: number = 0) {
    if (getPligtig() !== null && !userIsBeingValidated()) {
        action();
    } else {
        if (tryCount < maxTries) {
            setTimeout((_: any) => {
                waitForPligtigAndPerformAction(action, maxTries, tryCount + 1);
            }, 500);
        } else {
            failedAction();
        }
    }
}

export function setTextKeysVisible(active:string) {
    window.sessionStorage.setItem(TEXT_KEYS_VISIBLE, active);
}

export function textKeysVisible():boolean {
    return window.sessionStorage.getItem(TEXT_KEYS_VISIBLE) !== "false";
}
