import * as X2JS from 'x2js';
import { AdditionalInfoModel } from '../models/additional-info.model';
import { AmountModel } from "../models/amount-model";
import { CompanyModel } from '../models/company.model';
import { CountryModel } from "../models/country.model";
import { ReportModel } from '../models/report.model';
import { RevenueModel } from "../models/revenue.model";
import { formatDatetime, formatSimpleDate, getCountryNameByCode } from "./text-utils";

export function parseXMLFileToReportModel(file: File | Blob, formatDates: boolean = false): Promise<ReportModel> {

    return new Promise<ReportModel>((resolve, reject) => {
        const aReader: FileReader = new FileReader();

        aReader.onerror = () => {
            aReader.abort();
            reject(new DOMException("Problem parsing input file."));
        };

        aReader.onload = (event: any) => {
            const x2js = new X2JS();
            const json: any = <JSON>x2js.xml2js(event.target.result);
            const report = extractReportFromJson(json, formatDates);

            resolve(report);
        };

        aReader.readAsText(file, "UTF-8");
    });
}

function extractReportFromJson(json: any, formatDates: boolean): ReportModel {
    const report = new ReportModel();
    if (json === null) {
        return report;
    }

    report.timestamp = formatDatetime(new Date(getNamespaceValue(json.CBC_OECD.MessageSpec.Timestamp)));
    report.reportingPeriod = getNamespaceValue(json.CBC_OECD.MessageSpec.ReportingPeriod);
    if (formatDates) {
        report.reportingPeriod = formatSimpleDate(getNamespaceValue(json.CBC_OECD.MessageSpec.ReportingPeriod));
    }
    report.messageRefId = getNamespaceValue(json.CBC_OECD.MessageSpec.MessageRefId);

    // if more than one company name
    const names: string[] = [];
    if (json.CBC_OECD.CbcBody.ReportingEntity !== undefined) {
        if (json.CBC_OECD.CbcBody.ReportingEntity.Entity.Name instanceof Array) {
            for (const name of json.CBC_OECD.CbcBody.ReportingEntity.Entity.Name) {
                names.push(getNamespaceValue(name));
            }
        } else {
            names.push(getNamespaceValue(json.CBC_OECD.CbcBody.ReportingEntity.Entity.Name));
        }
        report.companyNames = names;
    }

    // loop CbcReports - Add countries
    const countries: CountryModel[] = [];
    const cbcReportsCountryCodes: string[] = [];

    if (json.CBC_OECD.CbcBody.CbcReports !== undefined) {
        let cbcReports = json.CBC_OECD.CbcBody.CbcReports;
        if (!Array.isArray(cbcReports)) {
            cbcReports = [json.CBC_OECD.CbcBody.CbcReports];
        }
        report.currency = cbcReports[0].Summary.Revenues.Related._currCode;
        for (const cbcReport of cbcReports) {
            const country = getCountry(cbcReport, cbcReportsCountryCodes);
            countries.push(country);
        }
    }

    // loop AdditionalInfos
    const additionalInfos: AdditionalInfoModel[] = [];
    const additionalInfoCountryCodes: string[] = [];

    if (json.CBC_OECD.CbcBody.AdditionalInfo !== undefined) {
        let additionalInfo: Array<any> = [];
        if (json.CBC_OECD.CbcBody.AdditionalInfo.OtherInfo !== undefined) {
            additionalInfo = [json.CBC_OECD.CbcBody.AdditionalInfo];
        } else {
            additionalInfo = json.CBC_OECD.CbcBody.AdditionalInfo;
        }
        for (const info of additionalInfo) {
            additionalInfos.push(getAdditionalInfo(info, additionalInfoCountryCodes));
        }

        report.additionalInfos = additionalInfos;
    }

    // if xml contains AdditionalInfo with countrycode NOT in CbcReports, then add country
    for (const countryCode of additionalInfoCountryCodes) {
        if (!cbcReportsCountryCodes.includes(countryCode)) {

            const country = new CountryModel();

            country.countryName = getCountryNameByCode(countryCode);
            country.countryCode = countryCode;
            countries.push(country);
        }
    }

    report.countries = countries;

    return report;
}

/**
 * If XML contains a namespace the xml2json parser will set __text as the final element when selecting a value.
 * If no namespace, __text will not exist.
 * @param value
 * @returns {string}
 */
function getNamespaceValue(value: any): string {
    if (value.__text !== undefined) {
        return value.__text;
    }
    return value;
}

/**
 * Extracts AdditionalInfo data from the provided JSON and returns a AdditionalInfoModel.
 * @param cbcReportsJson the AdditionalInfo JSON input.
 * @param countryCodes
 */
function getCountry(cbcReportsJson: any, countryCodes: string[]): CountryModel {
    countryCodes.push(getNamespaceValue(cbcReportsJson.ResCountryCode));

    const country = new CountryModel();
    country.countryCode = getNamespaceValue(cbcReportsJson.ResCountryCode);
    country.countryName = getCountryNameByCode(country.countryCode);
    country.noOfEmployees = getNamespaceValue(cbcReportsJson.Summary.NbEmployees);
    country.profitOrLoss = getAmount(cbcReportsJson.Summary.ProfitOrLoss);
    country.taxPaid = getAmount(cbcReportsJson.Summary.TaxPaid);
    country.taxAccrued = getAmount(cbcReportsJson.Summary.TaxAccrued);
    country.capital = getAmount(cbcReportsJson.Summary.Capital);
    country.earnings = getAmount(cbcReportsJson.Summary.Earnings);
    country.assets = getAmount(cbcReportsJson.Summary.Assets);
    country.revenue = new RevenueModel();
    country.revenue.unrelated = getAmount(cbcReportsJson.Summary.Revenues.Unrelated);
    country.revenue.related = getAmount(cbcReportsJson.Summary.Revenues.Related);
    country.revenue.total = getAmount(cbcReportsJson.Summary.Revenues.Total);

    // add companies
    const companies: CompanyModel[] = [];
    if (cbcReportsJson.ConstEntities instanceof Array) {
        for (const entity of cbcReportsJson.ConstEntities) {
            companies.push(getCompany(entity));
        }
    } else {
        companies.push(getCompany(cbcReportsJson.ConstEntities));
    }

    country.companies = companies;

    return country;
}

function getAmount(amountJson: any): AmountModel {
    return new AmountModel(getNamespaceValue(amountJson), amountJson._currCode);
}

/**
 * Extracts AdditionalInfo data from the provided JSON and returns a AdditionalInfoModel.
 * @param additionalInfoJson the AdditionalInfo JSON input.
 * @param additionalInfoCountryCodes an array to push all found (distinct) ResCountryCode values to.
 */
function getAdditionalInfo(additionalInfoJson: any, additionalInfoCountryCodes: string[]): AdditionalInfoModel {
    const additionalInfoModel = new AdditionalInfoModel();

    additionalInfoModel.otherInfo = getNamespaceValue(additionalInfoJson.OtherInfo);

    // is countrycode set?
    if (additionalInfoJson.ResCountryCode !== undefined) {
        // is there more than one?
        if (additionalInfoJson.ResCountryCode instanceof Array) {
            const resCountryCodes = [];
            for (const countryCode of additionalInfoJson.ResCountryCode) {
                resCountryCodes.push(getNamespaceValue(countryCode));
                if (!additionalInfoCountryCodes.includes(getNamespaceValue(countryCode))) {
                    additionalInfoCountryCodes.push(getNamespaceValue(countryCode));
                }
            }
            additionalInfoModel.resCountryCodes = resCountryCodes;
        } else {
            additionalInfoModel.resCountryCodes = [getNamespaceValue(additionalInfoJson.ResCountryCode)];
            if (!additionalInfoCountryCodes.includes(getNamespaceValue(additionalInfoJson.ResCountryCode))) {
                additionalInfoCountryCodes.push(getNamespaceValue(additionalInfoJson.ResCountryCode));
            }
        }
    }

    // is summaryref set?
    if (additionalInfoJson.SummaryRef !== undefined) {
        // is there more than one?
        if (additionalInfoJson.SummaryRef instanceof Array) {
            const summaryRefs = [];
            for (const ref of additionalInfoJson.SummaryRef) {
                summaryRefs.push(getNamespaceValue(ref));
            }
            additionalInfoModel.summaryRefs = summaryRefs;
        } else {
            additionalInfoModel.summaryRefs = [getNamespaceValue(additionalInfoJson.SummaryRef)];
        }
    }

    return additionalInfoModel;
}

export function getCompany(element: any): CompanyModel {
    const company = new CompanyModel();

    company.tinNumber = getNamespaceValue(element.ConstEntity.TIN);

    // if name is set
    if (element.ConstEntity.Name !== undefined) {
        // check if more than one
        if (element.ConstEntity.Name instanceof Array) {
            const names: string[] = [];
            for (const entityName of element.ConstEntity.Name) {
                names.push(getNamespaceValue(entityName));
            }
            company.names = names;
        } else {
            company.names = [getNamespaceValue(element.ConstEntity.Name)];
        }
    }
    // if BizActivities is set
    if (element.BizActivities !== undefined) {
        // check if more than one
        if (element.BizActivities instanceof Array) {
            const bizActivities: string[] = [];
            for (const activity of element.BizActivities) {
                bizActivities.push(getNamespaceValue(activity));
            }
            company.bizActivities = bizActivities;
        } else {
            company.bizActivities = [getNamespaceValue(element.BizActivities)];
        }
    }
    // if address is set
    if (element.ConstEntity.Address !== undefined) {
        // check if more than one
        if (element.ConstEntity.Address instanceof Array) {
            const addresses: string[] = [];
            for (const entityAddress of element.ConstEntity.Address) {
                addresses.push(getAddress(entityAddress));
            }
            company.addresses = addresses;
        } else {
            company.addresses = [getAddress(element.ConstEntity.Address)];
        }
    }
    return company;
}

export function getAddress(address: any): string {
    let result = "";
    if (address.AddressFix !== undefined) {
        let street = "";
        if (address.AddressFix.Street !== undefined) {
            street += getNamespaceValue(address.AddressFix.Street);
        }
        if (address.AddressFix.BuildingIdentifier !== undefined) {
            street += ' ' + getNamespaceValue(address.AddressFix.BuildingIdentifier);
        }
        let postCode = "";
        if (address.AddressFix.PostCode !== undefined) {
            postCode += getNamespaceValue(address.AddressFix.PostCode);
        }

        const city = getNamespaceValue(address.AddressFix.City);

        if (street !== "") {
            result = street + ', ';
        }
        if (postCode !== "") {
            result += postCode + ' ';
        }
        result += city;
    } else {
        result = getNamespaceValue(address.AddressFree);
    }
    return result;
}
